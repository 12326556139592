import { Component, Fragment } from 'react';
import { postFilter, getData } from "../../servicos/api";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Button, Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';
import Combobox from '../../Components/Combobox';
import { Link } from "react-router-dom";
import DatePicker from 'react-date-picker';

//REDUX
import { setLoaderShow, setResponseModal, setDadosRelatorioPedido } from '../../reducers/index';
import { connect } from 'react-redux';

const salvaFiltro = ( key, value ) => {
    sessionStorage.setItem(key, JSON.stringify(value));
}

const buscaFiltro = ( key ) => {
    return JSON.parse(sessionStorage.getItem(key));
}

const removeFiltro = ( key ) => {
    sessionStorage.removeItem(key);
}

const FormularioListagem = (props) => {

    let filtros = buscaFiltro('filtro_pedidos_listagem');

    let validacao = Yup.object({});

    let dateStart = new Date();
    let dateEnd = new Date();

    dateStart.setDate(dateEnd.getDate() - 7);

    let comboValues = [
        { label: "Não", value: 0 },
        { label: "Sim", value: 1 }
    ]

    const formik = useFormik({
        initialValues: {
            data_inicio: filtros !== null && filtros.data_inicio !== null ? new Date(filtros.data_inicio) : dateStart,
            data_termino: filtros !== null && filtros.data_termino !== null ? new Date(filtros.data_termino) : dateEnd,
            cliente_id: filtros !== null && filtros.cliente_id !== null ? filtros.cliente_id : {},
            cidade_id: filtros !== null && filtros.cidade_id !== null ? filtros.cidade_id : {},
            bairro_id: filtros !== null && filtros.bairro_id !== null ? filtros.bairro_id : {},
            rua_id: filtros !== null && filtros.rua_id !== null ? filtros.rua_id : {},
            entregue: filtros !== null && filtros.entregue !== null ? filtros.entregue : {},
            pago: filtros !== null && filtros.pago !== null ? filtros.pago : {},
        },
        enableReinitialize: false,
        validationSchema: validacao,
        onSubmit: values => {

            let data_inicio = values.data_inicio !== null ? (values.data_inicio.toLocaleDateString()) + ' 00:00:00' : null;
            let data_termino = values.data_termino !== null ? (values.data_termino.toLocaleDateString()) + ' 23:59:59' : null;

            let dataFiltro = {
                cliente_id: values.cliente_id.value === undefined || values.cliente_id.value === null ? null : values.cliente_id,
                cidade_id: values.cidade_id.value === undefined || values.cidade_id.value === null ? null : values.cidade_id,
                bairro_id: values.bairro_id.value === undefined || values.bairro_id.value === null ? null : values.bairro_id,
                rua_id: values.rua_id.value === undefined || values.rua_id.value === null ? null : values.rua_id,
                data_inicio: values.data_inicio !== null ? values.data_inicio : null,
                data_termino: values.data_termino !== null ? values.data_termino : null,
                entregue: values.entregue.value === undefined || values.entregue.value === null ? null : values.entregue,
                pago: values.pago.value === undefined || values.pago.value === null ? null : values.pago,
            }
            salvaFiltro('filtro_pedidos_listagem', dataFiltro);

            let data = {
                "cliente_id": values.cliente_id.value === undefined || values.cliente_id.value === null ? null : values.cliente_id.value,
                "cidade_id": values.cidade_id.value === undefined || values.cidade_id.value === null ? null : values.cidade_id.value,
                "bairro_id": values.bairro_id.value === undefined || values.bairro_id.value === null ? null : values.bairro_id.value,
                "rua_id": values.rua_id.value === undefined || values.rua_id.value === null ? null : values.rua_id.value,
                "data_inicio": data_inicio,
                "data_termino": data_termino,
                "entregue": values.entregue.value === undefined || values.entregue.value === null ? null : values.entregue.value,
                "pago": values.pago.value === undefined || values.pago.value === null ? null : values.pago.value,
            }

            props.filtrar(data);
        },
    });
    return ( 
        <Form className="forms-sample col-12" onSubmit={formik.handleSubmit}>
            <Card>
                <CardHeader className="bg-white">
                    <Row>
                        <Col className='col_lbl_filtro' sm='12' md='6'>
                            <h4 className="headerTitle">Filtro de pedidos</h4>
                        </Col>
                        <Col className='col_btn_filtro' sm='12' md='6'>
                            <Button 
                                className="btn btn-sm btn-success btn-fw bd-rd-8" 
                                type="button" 
                                onClick={
                                    () => { 
                                        removeFiltro('filtro_pedidos_listagem');

                                        formik.setFieldValue('data_inicio', dateStart);
                                        formik.setFieldValue('data_termino', dateEnd);
                                        formik.setFieldValue('cliente_id', {});
                                        formik.setFieldValue('cidade_id', {});
                                        formik.setFieldValue('bairro_id', {});
                                        formik.setFieldValue('rua_id', {});
                                        formik.setFieldValue('entregue', {});
                                        formik.setFieldValue('pago', {});
                                    }
                                }
                            >
                                <i className="fa fa-check btn-icon-append btn-fs-def"></i>
                                <span className="label-button btn-fs-def">Limpar filtro</span>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div>

                        <FormGroup>
                            <Label for="data_inicio">Data de início</Label>
                            <br/>
                            <DatePicker 
                                id='data_inicio'
                                name='data_inicio'
                                className={'form-control form-control-sm'}
                                onChange={(newDate) => formik.setFieldValue('data_inicio', newDate)} 
                                value={formik.values.data_inicio}
                                closeCalendar={true}
                                locale={'pt-BR'}
                            />
                            {formik.touched.data_inicio && formik.errors.data_inicio ? (
                                <div className="divError">{formik.errors.data_inicio}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="data_fim">Data de término</Label>
                            <br/>
                            <DatePicker 
                                id='data_termino'
                                name='data_termino'
                                className={'form-control form-control-sm'}
                                onChange={(newDate) => formik.setFieldValue('data_termino', newDate)} 
                                value={formik.values.data_termino}
                                closeCalendar={true}
                                locale={'pt-BR'}
                            />
                            {formik.touched.data_termino && formik.errors.data_termino ? (
                                <div className="divError">{formik.errors.data_termino}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="cliente_id">Cliente</Label>
                            <Combobox
                                invalid={formik.touched.cliente_id && formik.errors.cliente_id}
                                valid={formik.touched.cliente_id && !formik.errors.cliente_id}
                                id="cliente_id"
                                name="cliente_id"
                                placeholder={'Selecione um item'}
                                defaultValue={formik.values.cliente_id}
                                value={formik.values.cliente_id}
                                options={props.listaClientes}
                                isMulti={false}
                                loadingMessage={"Carregando..."}
                                noOptionsMessage={"Não existem dados cadastrados."}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                maxMenuHeight={190}
                            />
                            {formik.touched.cliente_id && formik.errors.cliente_id ? (
                                <div className="divError">{formik.errors.cliente_id}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="cidade_id">Cidade</Label>
                            <Combobox
                                invalid={formik.touched.cidade_id && formik.errors.cidade_id}
                                valid={formik.touched.cidade_id && !formik.errors.cidade_id}
                                id="cidade_id"
                                name="cidade_id"
                                placeholder={'Selecione um item'}
                                defaultValue={formik.values.cidade_id}
                                value={formik.values.cidade_id}
                                options={props.listaCidades}
                                isMulti={false}
                                loadingMessage={"Carregando..."}
                                noOptionsMessage={"Não existem dados cadastrados."}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                maxMenuHeight={190}
                            />
                            {formik.touched.cidade_id && formik.errors.cidade_id ? (
                                <div className="divError">{formik.errors.cidade_id}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="bairro_id">Bairro</Label>
                            <Combobox
                                invalid={formik.touched.bairro_id && formik.errors.bairro_id}
                                valid={formik.touched.bairro_id && !formik.errors.bairro_id}
                                id="bairro_id"
                                name="bairro_id"
                                placeholder={'Selecione um item'}
                                defaultValue={formik.values.bairro_id}
                                value={formik.values.bairro_id}
                                options={props.listaBairros}
                                isMulti={false}
                                loadingMessage={"Carregando..."}
                                noOptionsMessage={"Não existem dados cadastrados."}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                maxMenuHeight={190}
                            />
                            {formik.touched.bairro_id && formik.errors.bairro_id ? (
                                <div className="divError">{formik.errors.bairro_id}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="rua_id">Rua</Label>
                            <Combobox
                                invalid={formik.touched.rua_id && formik.errors.rua_id}
                                valid={formik.touched.rua_id && !formik.errors.rua_id}
                                id="rua_id"
                                name="rua_id"
                                placeholder={'Selecione um item'}
                                defaultValue={formik.values.rua_id}
                                value={formik.values.rua_id}
                                options={props.listaRuas}
                                isMulti={false}
                                loadingMessage={"Carregando..."}
                                noOptionsMessage={"Não existem dados cadastrados."}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                maxMenuHeight={190}
                            />
                            {formik.touched.rua_id && formik.errors.rua_id ? (
                                <div className="divError">{formik.errors.rua_id}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="entregue">Entregue</Label>
                            <Combobox
                                invalid={formik.touched.entregue && formik.errors.entregue}
                                valid={formik.touched.entregue && !formik.errors.entregue}
                                id="entregue"
                                name="entregue"
                                placeholder={'Selecione um item'}
                                defaultValue={formik.values.entregue}
                                value={formik.values.entregue}
                                options={comboValues}
                                isMulti={false}
                                loadingMessage={"Carregando..."}
                                noOptionsMessage={"Não existem dados cadastrados."}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                maxMenuHeight={190}
                            />
                            {formik.touched.entregue && formik.errors.entregue ? (
                                <div className="divError">{formik.errors.entregue}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="pago">Pago</Label>
                            <Combobox
                                invalid={formik.touched.pago && formik.errors.pago}
                                valid={formik.touched.pago && !formik.errors.pago}
                                id="pago"
                                name="pago"
                                placeholder={'Selecione um item'}
                                defaultValue={formik.values.pago}
                                value={formik.values.pago}
                                options={comboValues}
                                isMulti={false}
                                loadingMessage={"Carregando..."}
                                noOptionsMessage={"Não existem dados cadastrados."}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                maxMenuHeight={190}
                            />
                            {formik.touched.pago && formik.errors.pago ? (
                                <div className="divError">{formik.errors.pago}</div>
                            ) : null}
                        </FormGroup>

                    </div>
                </CardBody>
                <CardFooter className="text-align-end bg-white">
                    <Link replace to="/inicio">
                        <Button className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                            <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                            <span className="label-button btn-fs-def">Voltar</span>
                        </Button>
                    </Link>

                    <Button className="btn btn-sm btn-success btn-fw bd-rd-8" type="submit">
                        <i className="fa fa-check btn-icon-append btn-fs-def"></i>
                        <span className="label-button btn-fs-def">Filtrar</span>
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

class PedidoFiltro extends Component {
    constructor(props){
        super(props);

        this.state = {
            activeTab: '1',
            listaClientes: [],
            listaCidades: [],
            listaBairros: [],
            listaRuas: [],
        };
        
        this.loadingShow  = this.loadingShow.bind(this);
        this.filtrar      = this.filtrar.bind(this);
        this.toggleTabs   = this.toggleTabs.bind(this);
        this.setDados     = this.setDados.bind(this);
    }

    async componentDidMount(){
        this.loadingShow(true);

        let [listaClientes, listaCidades, listaBairros, listaRuas] = await Promise.all([
            getData(`/cliente`, this.props),
            getData(`/cidade`, this.props),
            getData(`/bairro`, this.props),
            getData(`/endereco`, this.props)
        ]);

        if(listaClientes !== null){
            listaClientes = listaClientes.map(function (value) {
                return { value: value.id, label: value.nome, ativo: value.ativo };
            });
        }

        if(listaCidades !== null){
            listaCidades = listaCidades.map(function (value) {
                return { value: value.id, label: value.nome, ativo: value.ativo };
            });
        }

        if(listaBairros !== null){
            listaBairros = listaBairros.map(function (value) {
                return { value: value.id, label: value.nome, ativo: value.ativo };
            });
        }

        if(listaRuas !== null){
            listaRuas = listaRuas.map(function (value) {
                return { value: value.id, label: value.logradouro, ativo: value.ativo };
            });
        }

        this.setState({listaClientes, listaCidades, listaBairros, listaRuas});

        this.loadingShow(false);
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    setDados(value) {
        let { setDadosRelatorioPedido } = this.props;
        setDadosRelatorioPedido(value);
    }

    async filtrar(data){
        this.loadingShow(true);
        const dados = await postFilter(`/relatorio/pedidos`, this.props, data, '../');

        this.setDados({ carregado: true, dados });
        this.props.history.push('./visualizar');
        this.loadingShow(false);
    }

    toggleTabs(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render(){
        const { setResponseModal, enableResponseModal } = this.props;
        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <Card>
                                            <CardBody>
                                                <div>
                                                    <FormularioListagem
                                                        filtrar={this.filtrar}
                                                        listaClientes={this.state.listaClientes}
                                                        listaCidades={this.state.listaCidades}
                                                        listaBairros={this.state.listaBairros}
                                                        listaRuas={this.state.listaRuas}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal,
        dadosRelatorioPedido: state.dadosRelatorioPedido,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
    setDadosRelatorioPedido: dados => dispatch(setDadosRelatorioPedido(dados)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PedidoFiltro);