import { Component, Fragment } from 'react';
import { postFilter, getData } from "../../servicos/api";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Button, Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';
import Combobox from '../../Components/Combobox';
import { Link } from "react-router-dom";

//REDUX
import { setLoaderShow, setResponseModal, setDadosRelatorioCliente } from '../../reducers/index';
import { connect } from 'react-redux';

const salvaFiltro = ( key, value ) => {
	sessionStorage.setItem(key, JSON.stringify(value));
}

const buscaFiltro = ( key ) => {
	return JSON.parse(sessionStorage.getItem(key));
}

const removeFiltro = ( key ) => {
	sessionStorage.removeItem(key);
}

const FormularioListagem = (props) => {

	let filtros = buscaFiltro('filtro_clientes_listagem');

	let validacao = Yup.object({});

	let comboValues = [
		{ label: "Não", value: 0 },
	]

	const formik = useFormik({
		initialValues: {
			pedidos_pendentes: filtros !== null && filtros.pedidos_pendentes !== null ? filtros.pedidos_pendentes : {},
      cidade_id: filtros !== null && filtros.cidade_id !== null ? filtros.cidade_id : {},
      bairro_id: filtros !== null && filtros.bairro_id !== null ? filtros.bairro_id : {},
      rua_id: filtros !== null && filtros.rua_id !== null ? filtros.rua_id : {}
		},
		enableReinitialize: false,
		validationSchema: validacao,
		onSubmit: values => {

			let dataFiltro = {
				pedidos_pendentes: values.pedidos_pendentes.value === undefined || values.pedidos_pendentes.value === null ? null : values.pedidos_pendentes,
        cidade_id: values.cidade_id === undefined || values.cidade_id.value === undefined || values.cidade_id.value === null ? null : values.cidade_id,
        bairro_id: values.bairro_id === undefined || values.bairro_id.value === undefined || values.bairro_id.value === null ? null : values.bairro_id,
        rua_id: values.rua_id === undefined || values.rua_id.value === undefined || values.rua_id.value === null ? null : values.rua_id,
			}
			salvaFiltro('filtro_clientes_listagem', dataFiltro);

			let data = {
				"pedidos_pendentes": values.pedidos_pendentes.value === undefined || values.pedidos_pendentes.value === null ? null : values.pedidos_pendentes.value,
        "cidade_id": values.cidade_id === undefined || values.cidade_id.value === undefined || values.cidade_id.value === null ? null : values.cidade_id.value,
        "bairro_id": values.bairro_id === undefined || values.bairro_id.value === undefined || values.bairro_id.value === null ? null : values.bairro_id.value,
        "rua_id": values.rua_id === undefined || values.rua_id.value === undefined || values.rua_id.value === null ? null : values.rua_id.value,
			}

			props.filtrar(data);
		},
	});
	return ( 
		<Form className="forms-sample col-12" onSubmit={formik.handleSubmit}>
			<Card>
				<CardHeader className="bg-white">
					<Row>
						<Col className='col_lbl_filtro' sm='12' md='6'>
							<h4 className="headerTitle">Filtro de clientes</h4>
						</Col>
						<Col className='col_btn_filtro' sm='12' md='6'>
							<Button 
								className="btn btn-sm btn-success btn-fw bd-rd-8" 
								type="button" 
								onClick={
									() => { 
										removeFiltro('filtro_clientes_listagem');

										formik.setFieldValue('pedidos_pendentes', {});
                    formik.setFieldValue('cidade_id', {});
                    formik.setFieldValue('bairro_id', {});
                    formik.setFieldValue('rua_id', {});
									}
								}
							>
								<i className="fa fa-check btn-icon-append btn-fs-def"></i>
								<span className="label-button btn-fs-def">Limpar filtro</span>
							</Button>
						</Col>
					</Row>
				</CardHeader>
				<CardBody>
					<div>

						<FormGroup>
							<Label for="pedidos_pendentes">Pedidos pendentes</Label>
							<Combobox
								invalid={formik.touched.pedidos_pendentes && formik.errors.pedidos_pendentes}
								valid={formik.touched.pedidos_pendentes && !formik.errors.pedidos_pendentes}
								id="pedidos_pendentes"
								name="pedidos_pendentes"
								placeholder={'Selecione um item'}
								defaultValue={formik.values.pedidos_pendentes}
								value={formik.values.pedidos_pendentes}
								options={comboValues}
								isMulti={false}
								loadingMessage={"Carregando..."}
								noOptionsMessage={"Não existem dados cadastrados."}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
								maxMenuHeight={190}
							/>
							{formik.touched.pedidos_pendentes && formik.errors.pedidos_pendentes ? (
								<div className="divError">{formik.errors.pedidos_pendentes}</div>
							) : null}
						</FormGroup>

            <FormGroup>
                <Label for="cidade_id">Cidade</Label>
                <Combobox
                    invalid={formik.touched.cidade_id && formik.errors.cidade_id}
                    valid={formik.touched.cidade_id && !formik.errors.cidade_id}
                    id="cidade_id"
                    name="cidade_id"
                    placeholder={'Selecione um item'}
                    defaultValue={formik.values.cidade_id}
                    value={formik.values.cidade_id}
                    options={props.listaCidades}
                    isMulti={false}
                    loadingMessage={"Carregando..."}
                    noOptionsMessage={"Não existem dados cadastrados."}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    maxMenuHeight={190}
                />
                {formik.touched.cidade_id && formik.errors.cidade_id ? (
                    <div className="divError">{formik.errors.cidade_id}</div>
                ) : null}
            </FormGroup>

            <FormGroup>
                <Label for="bairro_id">Bairro</Label>
                <Combobox
                    invalid={formik.touched.bairro_id && formik.errors.bairro_id}
                    valid={formik.touched.bairro_id && !formik.errors.bairro_id}
                    id="bairro_id"
                    name="bairro_id"
                    placeholder={'Selecione um item'}
                    defaultValue={formik.values.bairro_id}
                    value={formik.values.bairro_id}
                    options={props.listaBairros}
                    isMulti={false}
                    loadingMessage={"Carregando..."}
                    noOptionsMessage={"Não existem dados cadastrados."}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    maxMenuHeight={190}
                />
                {formik.touched.bairro_id && formik.errors.bairro_id ? (
                    <div className="divError">{formik.errors.bairro_id}</div>
                ) : null}
            </FormGroup>

            <FormGroup>
                <Label for="rua_id">Rua</Label>
                <Combobox
                    invalid={formik.touched.rua_id && formik.errors.rua_id}
                    valid={formik.touched.rua_id && !formik.errors.rua_id}
                    id="rua_id"
                    name="rua_id"
                    placeholder={'Selecione um item'}
                    defaultValue={formik.values.rua_id}
                    value={formik.values.rua_id}
                    options={props.listaRuas}
                    isMulti={false}
                    loadingMessage={"Carregando..."}
                    noOptionsMessage={"Não existem dados cadastrados."}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    maxMenuHeight={190}
                />
                {formik.touched.rua_id && formik.errors.rua_id ? (
                    <div className="divError">{formik.errors.rua_id}</div>
                ) : null}
            </FormGroup>

					</div>
				</CardBody>
				<CardFooter className="text-align-end bg-white">
					<Link replace to="/inicio">
						<Button className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
							<i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
							<span className="label-button btn-fs-def">Voltar</span>
						</Button>
					</Link>

					<Button className="btn btn-sm btn-success btn-fw bd-rd-8" type="submit">
						<i className="fa fa-check btn-icon-append btn-fs-def"></i>
						<span className="label-button btn-fs-def">Filtrar</span>
					</Button>
				</CardFooter>
			</Card>
		</Form>
	);
}

class ClienteFiltro extends Component {
	constructor(props){
		super(props);

		this.state = {
			activeTab: '1',
      listaCidades: [],
      listaBairros: [],
      listaRuas: []
		};
		
		this.loadingShow = this.loadingShow.bind(this);
		this.filtrar     = this.filtrar.bind(this);
		this.toggleTabs  = this.toggleTabs.bind(this);
		this.setDados    = this.setDados.bind(this);
	}

  async componentDidMount(){
        this.loadingShow(true);

        let [listaCidades, listaBairros, listaRuas] = await Promise.all([
            getData(`/cidade`, this.props),
            getData(`/bairro`, this.props),
            getData(`/endereco`, this.props)
        ]);

        if(listaCidades !== null){
            listaCidades = listaCidades.map(function (value) {
                return { value: value.id, label: value.nome, ativo: value.ativo };
            });
        }

        if(listaBairros !== null){
            listaBairros = listaBairros.map(function (value) {
                return { value: value.id, label: value.nome, ativo: value.ativo };
            });
        }

        if(listaRuas !== null){
            listaRuas = listaRuas.map(function (value) {
                return { value: value.id, label: value.logradouro, ativo: value.ativo };
            });
        }

        this.setState({listaCidades, listaBairros, listaRuas});

        this.loadingShow(false);
    }

	loadingShow(value) {
		let { setLoaderShow } = this.props;
		setLoaderShow(value);
	}

	setDados(value) {
		let { setDadosRelatorioCliente } = this.props;
		setDadosRelatorioCliente(value);
	}

	async filtrar(data){
		this.loadingShow(true);
		const dados = await postFilter(`/relatorio/clientes`, this.props, data, '../');

		this.setDados({ carregado: true, dados });
		this.props.history.push('./visualizar');
		this.loadingShow(false);
	}

	toggleTabs(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	render(){
		const { setResponseModal, enableResponseModal } = this.props;
		return (
			<Fragment>
				<div className="container-scroller">
					<Navbar/>
					<div className="container-fluid page-body-wrapper">
						<Sidebar/>
						<div className="main-panel">
							<div className="content-wrapper">
								<div className="row">
									<div className="col-md-12 grid-margin stretch-card">
										<Card>
											<CardBody>
												<div>
													<FormularioListagem
														filtrar={this.filtrar}
                            listaCidades={this.state.listaCidades}
                            listaBairros={this.state.listaBairros}
                            listaRuas={this.state.listaRuas}
													/>
												</div>
											</CardBody>
										</Card>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ModalResponse 
					history={this.props.history} 
					setResponseModal={setResponseModal} 
					enableResponseModal={enableResponseModal}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		enableLoaderShow: state.enableLoaderShow,
		enableResponseModal: state.enableResponseModal,
		dadosRelatorioCliente: state.dadosRelatorioCliente,
	}
};

const mapDispatchToProps = dispatch => ({
	setLoaderShow: enable => dispatch(setLoaderShow(enable)),
	setResponseModal: enable => dispatch(setResponseModal(enable)),
	setDadosRelatorioCliente: dados => dispatch(setDadosRelatorioCliente(dados)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClienteFiltro);