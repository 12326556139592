import { Component, Fragment } from 'react';
import { postData, putData } from "../../servicos/api";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, Button, Card, CardHeader, CardBody, CardFooter, Row } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import InputFile from '../../Components/InputFile';
import ModalResponse from '../../Components/ModalResponse';
import { Link } from "react-router-dom";
import Switch from 'react-switch';
import MaskedInput from 'react-text-mask';

//REDUX
import { setLoaderShow, setResponseModal } from '../../reducers/index';
import { connect } from 'react-redux';

const Formulario = (props) => {
    
    let arquivo_label = "";
    if (props.data && props.data.imagem !== undefined && props.data.imagem !== null && props.data.imagem !== "") {
        arquivo_label = "Arquivo selecionado";
    }

    const formik = useFormik({
        initialValues: {
            nome:          props.data !== undefined && props.data !== null ? props.data.nome : '',
            endereco:      props.data !== undefined && props.data !== null ? props.data.endereco : '',
            razao_social:  props.data !== undefined && props.data !== null ? props.data.razao_social : '',
            cnpj:          props.data !== undefined && props.data !== null ? props.data.cnpj : '',
            email:         props.data !== undefined && props.data !== null ? props.data.email : '',
            telefone:      props.data !== undefined && props.data !== null ? props.data.telefone : '',
            celular:       props.data !== undefined && props.data !== null ? props.data.celular : '',
            whatsapp:      props.data !== undefined && props.data !== null ? props.data.whatsapp : '',
            facebook:      props.data !== undefined && props.data !== null ? props.data.facebook : '',
            instagram:     props.data !== undefined && props.data !== null ? props.data.instagram : '',
            ativo:         props.data !== undefined && props.data !== null ? !!parseInt(props.data.ativo) : true,
            arquivo:       props.data !== undefined && props.data !== null && props.data !== '' ? props.data.imagem :  '',
            arquivo_label: arquivo_label,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            nome: Yup.string()
                .min(2, 'O campo nome deverá ter no mínimo 2 caracteres.')
                .max(90, 'O campo nome deverá ter no máximo 90 caracteres.')
                .required('O campo nome é obrigatório.')
                .trim(),
            endereco: Yup.string()
                .min(2, 'O campo endereço deverá ter no mínimo 2 caracteres.')
                .max(90, 'O campo endereço deverá ter no máximo 90 caracteres.')
                .required('O campo endereço é obrigatório.')
                .trim(),
            razao_social: Yup.string()
                .min(2, 'O campo razão social deverá ter no mínimo 2 caracteres.')
                .max(90, 'O campo razão social deverá ter no máximo 90 caracteres.')
                .nullable()
                .trim(),
            cnpj: Yup.string()
                .min(18, 'O campo cnpj deverá ter no mínimo 18 caracteres.')
                .nullable()
                .trim(),
            email: Yup.string()
                .min(5, 'O campo email deverá ter no mínimo 5 caracteres.')
                .max(120, 'O campo email deverá ter no máximo 120 caracteres.')
                .email('O campo email deverá receber um email válido.')
                .nullable()
                .trim(),
            telefone: Yup.string()
                .min(14, 'O campo telefone deverá ter no mínimo 14 caracteres.')
                .max(14, 'O campo telefone deverá ter no máximo 14 caracteres.')
                .nullable()
                .trim(),
            celular: Yup.string()
                .min(15, 'O campo celular deverá ter no mínimo 15 caracteres.')
                .max(16, 'O campo celular deverá ter no máximo 16 caracteres.')
                .required('O campo celular é obrigatório.')
                .trim(),
            whatsapp: Yup.string()
                .min(15, 'O campo whatsapp deverá ter no mínimo 15 caracteres.')
                .max(16, 'O campo whatsapp deverá ter no máximo 16 caracteres.')
                .nullable()
                .trim(),
            facebook: Yup.string()
                .min(5, 'O campo facebook deverá ter no mínimo 5 caracteres.')
                .max(90, 'O campo facebook deverá ter no máximo 90 caracteres.')
                .nullable()
                .trim(),
            instagram: Yup.string()
                .min(5, 'O campo instagram deverá ter no mínimo 5 caracteres.')
                .max(90, 'O campo instagram deverá ter no máximo 90 caracteres.')
                .nullable()
                .trim(),
            arquivo: Yup.mixed()
                .nullable()
        }),
        onSubmit: values => {
            let data = {
                "nome" : values.nome,
                "endereco" : values.endereco,
                "razao_social" : values.razao_social,
                "cnpj" : values.cnpj,
                "email" : values.email,
                "telefone" : values.telefone,
                "celular" : values.celular,
                "whatsapp" : values.whatsapp,
                "facebook" : values.facebook,
                "instagram" : values.instagram,
                "ativo": values.ativo,
                "imagem" : values.arquivo,
            }

            if (props.operation !== undefined && props.operation !== null && props.operation === 'alt') {
                props.updateEstabelecimento(data, props.data.uuid);
            } else {
                props.insertEstabelecimento(data);
            }
        },
    });

    return ( 
        <Form className="forms-sample col-12" onSubmit={formik.handleSubmit}>
            <Card>
                <CardHeader className="bg-maskats text-center">
                    <h4 className="headerTitle">Cadastro de estabelecimentos</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        <FormGroup>
                            <Label for="nome">* Nome</Label>
                            <Input 
                                className="form-control form-control-sm" 
                                id="nome" 
                                name="nome" 
                                type="text" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.nome}
                                minLength={5}
                                maxLength={90}
                            />
                            {formik.touched.nome && formik.errors.nome ? (
                                <div className="divError">{formik.errors.nome}</div>
                            ) : null}
                        </FormGroup>
                        
                        <FormGroup>
                            <Label for="endereco">* Endereço</Label>
                            <Input 
                                className="form-control form-control-sm" 
                                id="endereco" 
                                name="endereco" 
                                type="text" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.endereco}
                                minLength={5}
                                maxLength={90}
                            />
                            {formik.touched.endereco && formik.errors.endereco ? (
                                <div className="divError">{formik.errors.endereco}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="razao_social">Razão social</Label>
                            <Input 
                                className="form-control form-control-sm" 
                                id="razao_social" 
                                name="razao_social" 
                                type="text" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.razao_social}
                                minLength={5}
                                maxLength={90}
                            />
                            {formik.touched.razao_social && formik.errors.razao_social ? (
                                <div className="divError">{formik.errors.razao_social}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="cnpj">CNPJ</Label>
                            <MaskedInput
                                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                guide={false}
                                className="form-control form-control-sm" 
                                id="cnpj" 
                                name="cnpj" 
                                type="text" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.cnpj}
                                minLength={18}
                                maxLength={18}
                            />
                            {formik.touched.cnpj && formik.errors.cnpj ? (
                                <div className="divError">{formik.errors.cnpj}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input 
                                className="form-control form-control-sm" 
                                id="email" 
                                name="email" 
                                type="email" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                minLength={5}
                                maxLength={120}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="divError">{formik.errors.email}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="telefone">Telefone</Label>
                            <MaskedInput 
                                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                guide={false}
                                className="form-control form-control-sm" 
                                id="telefone" 
                                name="telefone" 
                                type="text" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.telefone}
                                minLength={14}
                                maxLength={14}
                            />
                            {formik.touched.telefone && formik.errors.telefone ? (
                                <div className="divError">{formik.errors.telefone}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="celular">* Celular</Label>
                            <MaskedInput
                                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                guide={false}
                                className="form-control form-control-sm"
                                id="celular"
                                name="celular"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.celular}
                                minLength={16}
                                maxLength={16}
                            />
                            {formik.touched.celular && formik.errors.celular ? (
                                <div className="divError">{formik.errors.celular}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="whatsapp">WhatsApp</Label>
                            <Input
                                className="form-control form-control-sm"
                                id="whatsapp"
                                name="whatsapp"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.whatsapp}
                                minLength={15}
                                maxLength={15}
                            />
                            {formik.touched.whatsapp && formik.errors.whatsapp ? (
                                <div className="divError">{formik.errors.whatsapp}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <InputFile formik={formik} title="Imagem"/>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Label for="facebook">Facebook</Label>
                            <Input
                                className="form-control form-control-sm"
                                id="facebook"
                                name="facebook"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.facebook}
                                minLength={5}
                                maxLength={90}
                            />
                            {formik.touched.facebook && formik.errors.facebook ? (
                                <div className="divError">{formik.errors.facebook}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="instagram">Instagram</Label>
                            <Input
                                className="form-control form-control-sm"
                                id="instagram"
                                name="instagram"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.instagram}
                                minLength={5}
                                maxLength={90}
                            />
                            {formik.touched.instagram && formik.errors.instagram ? (
                                <div className="divError">{formik.errors.instagram}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup className="mb-0">
                            <Label for="ativo">* Ativo</Label>
                            <br />
                            <Switch
                                id="ativo"
                                name="ativo"
                                onColor="#28a745"
                                offColor="#dc3545"
                                checked={formik.values.ativo}
                                onChange={()=>formik.setFieldValue('ativo', !formik.values.ativo)}
                            />
                        </FormGroup>
                    </div>
                </CardBody>
                <CardFooter className="text-align-end bg-white">
                    <Link replace to="/estabelecimento">
                        <Button className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                            <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                            <span className="label-button btn-fs-def">Cancelar</span>
                        </Button>
                    </Link>

                    <Button className="btn btn-sm btn-success btn-fw bd-rd-8" type="submit">
                        <i className="fa fa-check btn-icon-append btn-fs-def"></i>
                        <span className="label-button btn-fs-def">Salvar</span>
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

class EstabelecimentoFormulario extends Component {
    constructor(props){
        super(props);

        this.state = { };
        
        this.loadingShow           = this.loadingShow.bind(this);
        this.updateEstabelecimento = this.updateEstabelecimento.bind(this);
        this.insertEstabelecimento = this.insertEstabelecimento.bind(this);
    }

    componentDidMount(){
        this.loadingShow(false);
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    async updateEstabelecimento(data, uuid){
        this.loadingShow(true);
        await putData(`/estabelecimento/${uuid}`, this.props, data, '../estabelecimento');
        this.loadingShow(false);
    }

    async insertEstabelecimento(data){
        this.loadingShow(true);
        await postData(`/estabelecimento`, this.props, data, '../estabelecimento');
        this.loadingShow(false);
    }

    render(){
        const { data, operation } = this.props.location;
        const { setResponseModal, enableResponseModal } = this.props;
        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <Formulario
                                            data={data}
                                            operation={operation}
                                            updateEstabelecimento={this.updateEstabelecimento}
                                            insertEstabelecimento={this.insertEstabelecimento}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EstabelecimentoFormulario);