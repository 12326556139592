import React from "react";
import GridTable from '@nadavshaar/react-grid-table';

export const Table = ({columns, rows, setDestroyModal, enableDestroyModal, next_url, next_url_editar, next_url_parcelar}) => {

    const colunas = columns();

    return (
        <GridTable 
            columns={colunas} 
            rows={rows}
            additionalProps={{setDestroyModal: setDestroyModal, enableDestroyModal: enableDestroyModal, next_url, next_url_editar, next_url_parcelar}}
            pageSize={10}
            pageSizes={[5, 10, 15, 20, 25]}
            onRowClick={({ rowIndex, data, column, isEdit, event }, tableManager) => {
                const elements = document.querySelectorAll(`[data-row-index="${rowIndex}"]`);
                const contain = document.querySelector(`[data-row-index="${rowIndex}"]`).classList.contains("selected-row");
                contain ? 
                    Array.from(elements).map( ele => ele.classList.remove("selected-row") ) :
                    Array.from(elements).map( ele => ele.classList.add("selected-row") )
            }}
        />
    );
}

export default Table;