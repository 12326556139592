import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Component, Fragment } from 'react';
import logo_white from '../../assets/img/logo_white.png';
import api from "../../servicos/api";
import { limpaSessao, login } from "../../servicos/auth";
//REDUX
import { setLoaderShow } from '../../reducers/index';
import { connect } from 'react-redux';

const realizaLogin = async (props, values) => {
    props.loadingShow(true);
    await api.post(`/login`, { "email": values.email, "password": values.password })
        .then((res) => {
            if(res.request.status === 200){
                login(res.data.token, res.data.tipo_id, res.data.estabelecimento_id);
                props.loadingShow(false);
                props.navigate('/inicio');
            }
        }).catch((res) => {
            props.loadingShow(false);
            alert("Falha ao realizar o login, tente novamente mais tarde.");
        });
}

const Formulario = (props) => {
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .max(255, 'O campo email deverá ter no máximo 255 caracteres.')
                .email('Email inválido.')
                .required('O campo email é obrigatório.')
                .trim(),
            password: Yup.string()
                .max(255, 'O campo senha deverá ter no máximo 255 caracteres.')
                .required('O campo senha é obrigatório.')
                .trim(),
        }),
        onSubmit: values => {
            realizaLogin(props, values);
        },
    });
    return (
        <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
                <Label className="small mb-1" for="email">Email</Label>
                <Input 
                    className="form-control form-control-sm" 
                    id="email" 
                    type="email" 
                    name="email" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                    <div className="divError">{formik.errors.email}</div>
                ) : null}
            </FormGroup>
            <FormGroup>
                <Label className="small mb-1" for="password">Senha</Label>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Input
                        className="form-control form-control-sm"
                        id="password"
                        name="password"
                        type={props.showPassword ? "text" : "password"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                    />
                    <i className={`fa ${props.showPassword ? "fa-eye-slash" : "fa-eye"}`} id="togglePassword" style={{marginLeft: "-30px", cursor: "pointer"}} onClick={props.showHidePassword}/>
                </div>
                {formik.touched.password && formik.errors.password ? (
                    <div className="divError">{formik.errors.password}</div>
                ) : null}
            </FormGroup>
            <FormGroup className="d-flex mb-0">
                <Button className="bg-red no-border btn-sm font-white btn-fw bd-rd-8" type="submit">
                    <i className="fa fa-sign-in btn-icon-append btn-fs-def"></i>
                    <span className="label-button btn-fs-def">Acessar</span>
                </Button>
            </FormGroup>
        </Form>
    );
}

class Login extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            showPassword: false
        }

        this.loadingShow = this.loadingShow.bind(this);
        this.showHidePassword = this.showHidePassword.bind(this);
    }

    componentDidMount(){
        limpaSessao();
    }

    // LOADER
    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    showHidePassword(){
        this.setState({showPassword: !this.state.showPassword})
    }

    render(){
        return (
            <Fragment>
                <div id="layoutAuthentication">
                    <div id="layoutAuthentication_content">
                        <main>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-5">
                                        <div className="card shadow-lg border-0 mt-5">
                                            <div className="card-header login-header bg-red" style={{padding: "0rem 1.25rem"}}>
                                                <h3 style={{display: "flex", justifyContent: "center"}} className="font-white fs-25 text-center font-weight-light my-4">
                                                    <img width="45px" alt="Icone no Maskats" style={{marginRight: "15px"}} src={logo_white}/>
                                                    <div style={{ textAlign: "center", alignSelf: "center" }} >Maskats</div>
                                                </h3>
                                            </div>
                                            <div className="card-body">
                                                <Formulario 
                                                    loadingShow={this.loadingShow}
                                                    navigate={this.props.history.push}
                                                    showHidePassword={this.showHidePassword}
                                                    showPassword={this.state.showPassword
                                                }/>
                                            </div>
                                            <div className='card-footer'>
                                                <Label style={{cursor: "pointer"}} onClick={() => this.props.history.push('/limpa-cache')} className="small mb-1" for="email">Clique aqui para realizar a atualização do sistema?</Label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);