import { Fragment, Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import { isAuthenticated } from "./servicos/auth";

import Login from './Pages/Auth/Login';
import LimpaCache from './Pages/Auth/LimpaCache';
import AlterarSenha from './Pages/Auth/AlterarSenha';

import Home from './Pages/Home/index';

import Estabelecimento from './Pages/Estabelecimento/index';
import EstabelecimentoFormulario from './Pages/Estabelecimento/EstabelecimentoFormulario';

import TipoUsuario from './Pages/TipoUsuario/index';
import TipoUsuarioFormulario from './Pages/TipoUsuario/TipoUsuarioFormulario';

import Usuario from './Pages/Usuario/index';
import UsuarioFormulario from './Pages/Usuario/UsuarioFormulario';

import Pedido from './Pages/Pedido/index';
import PedidoFormulario from './Pages/Pedido/PedidoFormulario';
import Parcela from './Pages/Pedido/Parcela/index';
import ParcelaFormulario from './Pages/Pedido/Parcela/ParcelaFormulario';

import CategoriaProduto from './Pages/CategoriaProduto/index';
import CategoriaProdutoFormulario from './Pages/CategoriaProduto/CategoriaProdutoFormulario';

import TipoProduto from './Pages/TipoProduto/index';
import TipoProdutoFormulario from './Pages/TipoProduto/TipoProdutoFormulario';

import Produto from './Pages/Produto/index';
import ProdutoFormulario from './Pages/Produto/ProdutoFormulario';

import Estado from './Pages/Estado/index';
import EstadoFormulario from './Pages/Estado/EstadoFormulario';

import Cidade from './Pages/Cidade/index';
import CidadeFormulario from './Pages/Cidade/CidadeFormulario';

import Bairro from './Pages/Bairro/index';
import BairroFormulario from './Pages/Bairro/BairroFormulario';

import Endereco from './Pages/Endereco/index';
import EnderecoFormulario from './Pages/Endereco/EnderecoFormulario';

import DiaIdeal from './Pages/DiaIdeal/index';
import DiaIdealFormulario from './Pages/DiaIdeal/DiaIdealFormulario';

import PeriodoIdeal from './Pages/PeriodoIdeal/index';
import PeriodoIdealFormulario from './Pages/PeriodoIdeal/PeriodoIdealFormulario';

import Cliente from './Pages/Cliente/index';
import ClienteFormulario from './Pages/Cliente/ClienteFormulario';
import ClientePedidos from './Pages/Cliente/ClientePedidos';

import PedidoFiltro from './Pages/Relatorio/PedidoFiltro';
import PedidoVisualizar from './Pages/Relatorio/PedidoVisualizar';

import ClienteFiltro from './Pages/Relatorio/ClienteFiltro';
import ClienteVisualizar from './Pages/Relatorio/ClienteVisualizar';

import IndicadoresFiltro from './Pages/Relatorio/IndicadoresFiltro';
import IndicadoresVisualizar from './Pages/Relatorio/IndicadoresVisualizar';

import GraficosFiltro from './Pages/Relatorio/GraficosFiltro';
import GraficosVisualizar from './Pages/Relatorio/GraficosVisualizar';

class ProtectedRoute extends Component {
	render() {
		const { component: Component, ...props } = this.props

		return (
			<Route 
				{...props} 
				render={props => (
					isAuthenticated() ?
						<Component {...props} /> :
						<Redirect to='/login' />
				)} 
			/>
		);
	}
}

function App() {
	return (
		<Fragment>
			<HashRouter>
				<Switch>
					<Route
						exact
						path="/"
						render={() => {
							return (
								sessionStorage.getItem('token') !== undefined && sessionStorage.getItem('token') !== null ?
									<Redirect to="/inicio" /> :
									<Redirect to="/login" /> 
							)
						}}
					/>
					<Route path="/login" component={Login}/>
					<Route path="/limpa-cache" component={LimpaCache}/>

					<ProtectedRoute path="/inicio" component={Home}/>

					<ProtectedRoute exact path="/estabelecimento" component={Estabelecimento}/>
					<ProtectedRoute path="/estabelecimento/formulario" component={EstabelecimentoFormulario}/>

					<ProtectedRoute exact path="/tipo-usuario" component={TipoUsuario}/>
					<ProtectedRoute path="/tipo-usuario/formulario" component={TipoUsuarioFormulario}/>

					<ProtectedRoute exact path="/usuario" component={Usuario}/>
					<ProtectedRoute path="/usuario/formulario" component={UsuarioFormulario}/>

          <ProtectedRoute exact path="/pedido" component={Pedido}/>
          <ProtectedRoute path="/pedido/formulario" component={PedidoFormulario}/>
          <ProtectedRoute exact path="/pedido/parcela" component={Parcela}/>
          <ProtectedRoute path="/pedido/parcela/formulario" component={ParcelaFormulario}/>

					<ProtectedRoute exact path="/categoria-produto" component={CategoriaProduto}/>
					<ProtectedRoute path="/categoria-produto/formulario" component={CategoriaProdutoFormulario}/>

					<ProtectedRoute exact path="/tipo-produto" component={TipoProduto}/>
					<ProtectedRoute path="/tipo-produto/formulario" component={TipoProdutoFormulario}/>

					<ProtectedRoute exact path="/produto" component={Produto}/>
					<ProtectedRoute path="/produto/formulario" component={ProdutoFormulario}/>

					<ProtectedRoute exact path="/estado" component={Estado}/>
					<ProtectedRoute path="/estado/formulario" component={EstadoFormulario}/>

					<ProtectedRoute exact path="/cidade" component={Cidade}/>
					<ProtectedRoute path="/cidade/formulario" component={CidadeFormulario}/>

					<ProtectedRoute exact path="/bairro" component={Bairro}/>
					<ProtectedRoute path="/bairro/formulario" component={BairroFormulario}/>

					<ProtectedRoute exact path="/endereco" component={Endereco}/>
					<ProtectedRoute path="/endereco/formulario" component={EnderecoFormulario}/>
					<ProtectedRoute path="/bairro/formulario" component={BairroFormulario}/>

					<ProtectedRoute exact path="/dia-ideal" component={DiaIdeal}/>
					<ProtectedRoute path="/dia-ideal/formulario" component={DiaIdealFormulario}/>

					<ProtectedRoute exact path="/periodo-ideal" component={PeriodoIdeal}/>
					<ProtectedRoute path="/periodo-ideal/formulario" component={PeriodoIdealFormulario}/>

					<ProtectedRoute exact path="/cliente" component={Cliente}/>
					<ProtectedRoute path="/cliente/formulario" component={ClienteFormulario}/>
					<ProtectedRoute path="/cliente/pedidos" component={ClientePedidos}/>

					<ProtectedRoute path="/relatorio/pedidos/filtro" component={PedidoFiltro}/>
					<ProtectedRoute path="/relatorio/pedidos/visualizar" component={PedidoVisualizar}/>

					<ProtectedRoute path="/relatorio/clientes/filtro" component={ClienteFiltro}/>
					<ProtectedRoute path="/relatorio/clientes/visualizar" component={ClienteVisualizar}/>

					<ProtectedRoute path="/relatorio/indicadores/filtro" component={IndicadoresFiltro}/>
					<ProtectedRoute path="/relatorio/indicadores/visualizar" component={IndicadoresVisualizar}/>

					<ProtectedRoute path="/relatorio/geral/filtro" component={GraficosFiltro}/>
					<ProtectedRoute path="/relatorio/geral/visualizar" component={GraficosVisualizar}/>

					<ProtectedRoute path="/alterar-senha" component={AlterarSenha}/>
				</Switch>
			</HashRouter>
		</Fragment>
	);
}

export default App;