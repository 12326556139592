import { Fragment, Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const modalDefault = {
    show: false,
    title: "",
    message: "",
    next_page: "",
}

class ModalResponse extends Component {

    constructor(props){
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal = () => {
        let { setResponseModal } = this.props;
        setResponseModal(modalDefault);
        if( this.props.enableResponseModal.next_page !== null && this.props.enableResponseModal.next_page !== undefined && this.props.enableResponseModal.next_page !== '' ){
            this.props.history.push(this.props.enableResponseModal.next_page);
        }
    }

    render() {
        const { show, title, message } = this.props.enableResponseModal;

        return (
            <Fragment>
                <Modal isOpen={show} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        {message}
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-sm" color="secondary" onClick={this.toggleModal}>Ok</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

export default ModalResponse;