import axios from "axios";
import { getToken } from "./auth";

const baseURL = "https://api.maskats.com.br/api/";

const api = axios.create({
    baseURL,
    headers: { 
        'Content-Type': 'application/json', 
        'Access-Control-Allow-Origin': '*'
    }
});

api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export const getData = async (uri, props) => {
    const res = await api.get(uri).catch(() => null);

    if(res && res.data && res.data.resultado){
        switch (res.data.resultado) {
            case 'S':
                return res.data.dados;
            case 'E':
                props.setResponseModal({
                    show: true,
                    title: "Aviso",
                    message: res.data.apiMensagemUsuario,
                    next_page: "",
                });
            break;
            default:
            break;
        }
        return null;
    } else {
        props.setResponseModal({
            show: true,
            title: "Aviso",
            message: "Não foi possível realizar a busca dos registros. Tente novamente mais tarde.",
            next_page: "",
        });
        return null;
    }
}

export const postData = async (uri, props, data, next_page) => {
    const res = await api.post(uri, data);

    if(res && res.data && res.data.resultado){
        switch (res.data.resultado) {
            case 'S':
                props.setResponseModal({
                    show: true,
                    title: "Confirmação",
                    message: res.data.apiMensagemUsuario,
                    next_page: next_page,
                });
            break;
            case 'E':
                props.setResponseModal({
                    show: true,
                    title: "Aviso",
                    message: res.data.apiMensagemUsuario,
                    next_page: "",
                });
            break;
            default:
            break;
        }
    } else {
        props.setResponseModal({
            show: true,
            title: "Aviso",
            message: "Não foi possível realizar o cadastro do registro. Tente novamente mais tarde.",
            next_page: "",
        });
    }
}

export const putData = async (uri, props, data, next_page) => {
    const res = await api.put(uri, data);

    if(res && res.data && res.data.resultado){
        switch (res.data.resultado) {
            case 'S':
                props.setResponseModal({
                    show: true,
                    title: "Confirmação",
                    message: res.data.apiMensagemUsuario,
                    next_page: next_page,
                });
            break;
            case 'E':
                props.setResponseModal({
                    show: true,
                    title: "Aviso",
                    message: res.data.apiMensagemUsuario,
                    next_page: "",
                });
            break;
            default:
            break;
        }
    } else {
        props.setResponseModal({
            show: true,
            title: "Aviso",
            message: "Não foi possível realizar o cadastro do registro. Tente novamente mais tarde.",
            next_page: "",
        });
    }
}

export const deleteData = async (uri, props) => {
    const res = await api.delete(uri);

    if(res && res.data && res.data.resultado){
        switch (res.data.resultado) {
            case 'S':
                props.setResponseModal({
                    show: true,
                    title: "Confirmação",
                    message: res.data.apiMensagemUsuario,
                    next_page: "",
                });
            break;
            case 'E':
                props.setResponseModal({
                    show: true,
                    title: "Aviso",
                    message: res.data.apiMensagemUsuario,
                    next_page: "",
                });
            break;
            default:
            break;
        }
    } else {
        props.setResponseModal({
            show: true,
            title: "Aviso",
            message: "Não foi possível realizar a exclusão do registro. Tente novamente mais tarde.",
            next_page: "",
        });
    }
}

export const postFilter = async (uri, props, data, next_page) => {
    const res = await api.post(uri, data);

    if(res && res.data && res.data.resultado){
        switch (res.data.resultado) {
            case 'S':
                return res.data.dados;
            case 'E':
                props.setResponseModal({
                    show: true,
                    title: "Aviso",
                    message: res.data.apiMensagemUsuario,
                    next_page: "",
                });
            break;
            default:
            break;
        }
    } else {
        props.setResponseModal({
            show: true,
            title: "Aviso",
            message: "Não foi possível realizar o cadastro do registro. Tente novamente mais tarde.",
            next_page: "",
        });
    }
}

export const postPdfData = async (uri, props, data) => {

    const token = getToken();

    const res = await axios(`${baseURL}${uri}`, {
        data,
        method: 'POST',
        responseType: 'blob',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'Content-Type': `application/json`
        }
    });

    if(res && res.data){
        return res.data;
    } else {
        props.setResponseModal({
            show: true,
            title: "Aviso",
            message: "Não foi possível realizar a geração do pdf. Tente novamente mais tarde.",
            next_page: "",
        });
    }
}

export default api;