export const SET_LOADER_SHOW = 'SET_LOADER_SHOW';
export const SET_MODAL_RESPONSE = 'SET_MODAL_RESPONSE';
export const SET_MODAL_DESTROY = 'SET_MODAL_DESTROY';
export const SET_MODAL_VISUALIZA_PEDIDO = 'SET_MODAL_VISUALIZA_PEDIDO';
export const SET_MODAL_CONFERE_PEDIDO = 'SET_MODAL_CONFERE_PEDIDO';
export const SET_MOBILE_SIDEBAR = 'SET_MOBILE_SIDEBAR';

export const SET_DADOS_RELATORIO_PEDIDO = 'SET_DADOS_RELATORIO_PEDIDO';
export const SET_DADOS_RELATORIO_CLIENTE = 'SET_DADOS_RELATORIO_CLIENTE';
export const SET_DADOS_RELATORIO_INDICADORES = 'SET_DADOS_RELATORIO_INDICADORES';
export const SET_DADOS_RELATORIO_GRAFICOS = 'SET_DADOS_RELATORIO_GRAFICOS';

export const setLoaderShow = enableLoaderShow => ({
    type: SET_LOADER_SHOW,
    enableLoaderShow
});

export const setResponseModal = enableResponseModal => ({
    type: SET_MODAL_RESPONSE,
    enableResponseModal
});

export const setDestroyModal = enableDestroyModal => ({
    type: SET_MODAL_DESTROY,
    enableDestroyModal
});

export const setMobileSidebar = enableMobileSidebar => ({
    type: SET_MOBILE_SIDEBAR,
    enableMobileSidebar
});

export const setDadosRelatorioPedido = dadosRelatorioPedido => ({
    type: SET_DADOS_RELATORIO_PEDIDO,
    dadosRelatorioPedido
});

export const setDadosRelatorioCliente = dadosRelatorioCliente => ({
    type: SET_DADOS_RELATORIO_CLIENTE,
    dadosRelatorioCliente
});

export const setDadosRelatorioIndicadores = dadosRelatorioIndicadores => ({
    type: SET_DADOS_RELATORIO_INDICADORES,
    dadosRelatorioIndicadores
});

export const setDadosRelatorioGraficos = dadosRelatorioGraficos => ({
    type: SET_DADOS_RELATORIO_GRAFICOS,
    dadosRelatorioGraficos
});

export default function reducer(state = { 
    enableLoaderShow: false,
    enableResponseModal: {
        show: false,
        title: "",
        message: "",
        next_page: "",
    },
    enableDestroyModal: { show: false, item: {} },
    enableMobileSidebar: false,
    dadosRelatorioPedido: { carregado: false, dados: [] },
    dadosRelatorioCliente: { carregado: false, dados: [] },
    dadosRelatorioIndicadores: { carregado: false, dados: [] },
    dadosRelatorioGraficos: { carregado: false, dados: [] }
}, action) {
    switch (action.type) {
        case SET_LOADER_SHOW:
            return {
                ...state,
                enableLoaderShow: action.enableLoaderShow
            };
        case SET_MODAL_RESPONSE:
            return {
                ...state,
                enableResponseModal: action.enableResponseModal
            };
        case SET_MODAL_DESTROY:
            return {
                ...state,
                enableDestroyModal: action.enableDestroyModal
            };
        case SET_MOBILE_SIDEBAR:
            return {
                ...state,
                enableMobileSidebar: action.enableMobileSidebar
            };
        case SET_DADOS_RELATORIO_PEDIDO:
            return {
                ...state,
                dadosRelatorioPedido: action.dadosRelatorioPedido
            };
        case SET_DADOS_RELATORIO_CLIENTE:
            return {
                ...state,
                dadosRelatorioCliente: action.dadosRelatorioCliente
            };
        case SET_DADOS_RELATORIO_INDICADORES:
            return {
                ...state,
                dadosRelatorioIndicadores: action.dadosRelatorioIndicadores
            };
        case SET_DADOS_RELATORIO_GRAFICOS:
            return {
                ...state,
                dadosRelatorioGraficos: action.dadosRelatorioGraficos
            };
        default:
            return state;
    }
}
