import { Component, Fragment } from 'react';
import { Card, CardBody, Row, Col, CardHeader, CardFooter, Button, Input } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';

//REDUX
import { setLoaderShow, setDadosRelatorioCliente, setResponseModal } from '../../reducers/index';
import { connect } from 'react-redux';

import { postPdfData } from '../../servicos/api'

class ClienteVisualizar extends Component {
    constructor(props){
        super(props);

        this.state = {
            clientes: [],
            showModal: false,
        };
        
        this.loadingShow = this.loadingShow.bind(this);
        this.setShowModal = this.setShowModal.bind(this);
        this.postPdfData = this.postPdfData.bind(this);
    }

    async componentDidMount(){

        this.loadingShow(true);

        if(!this.props.dadosRelatorioCliente.carregado){
            this.props.history.push('./filtro');
        } else {
            let clientes = [];

            clientes = this.props.dadosRelatorioCliente.dados;

            this.setState({clientes});

            this.loadingShow(false);
        }
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    setShowModal() {
        this.setState({showModal: !this.state.showModal})
    }

    async postPdfData(){
        this.loadingShow(true);
        let { clientes } = this.state;

        let arrClientes = [];

        clientes.map(item => {

            arrClientes.push({
                codigo: "#"+item.id,
                endereco: `${item.endereco_bairro} - ${item.endereco_logradouro}, ${item.endereco_numero}`,
                cliente: item.cliente_nome
            });
        })

        if( clientes.length > 0 ) {
            const pdf = await postPdfData('relatorio/gerar-pdf', this.props, {dados: JSON.stringify(arrClientes), option: 'clientes'});
            const file = new Blob(
                [pdf], 
                {type: 'application/pdf'}
            );
            const fileURL = URL.createObjectURL(file);

            window.open(fileURL);
        }

        this.loadingShow(false);
    }

    render(){
        
        let { setResponseModal, enableResponseModal } = this.props;

        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin">

                                        <Row style={{ marginBottom: '20px' }} >
                                            <Col sm="6" md="6" lg="6">
                                                <Button onClick={() => this.props.history.goBack()} className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                                                    <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                                                    <span className="label-button btn-fs-def">Voltar</span>
                                                </Button>
                                            </Col>
                                            <Col sm="6" md="6" lg="6" className='d-flex justify-content-end'>
                                                <Button onClick={() => this.postPdfData()} className="text-white btn btn-sm btn-success btn-fw bd-rd-8" type="button">
                                                    <i className="fa fa-download btn-icon-append btn-fs-def"></i>
                                                    <span className="label-button btn-fs-def">Exportar</span>
                                                </Button>
                                            </Col>
                                        </Row>

                                        { this.state.clientes.length > 0 ?

                                            this.state.clientes.map(item => {
                                                
                                                return (
                                                    <Fragment key={item.id}>
                                                        <Row>
                                                            <Col sm="12" md="12" lg="12">
                                                                <Input hidden id={"whatsapp"} value={item.telefone_principal === null ? '' : item.telefone_principal} readOnly/>
                                                                <Card id={`card_cliente_${item.id}`}>
                                                                    <CardHeader className='bg-maskats text-center' style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                        <h4 style={{ marginBottom: '0px' }}>Cliente #{item.id}</h4>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <p className="card-description-black"><b>Cliente: </b>{`${item.cliente_nome ? item.cliente_nome : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black"><b>Telefone: </b>{`${item.telefone_principal ? item.telefone_principal : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black"><b>Cidade: </b>{`${item.endereco_cidade ? item.endereco_cidade : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black"><b>Bairro: </b>{`${item.endereco_bairro ? item.endereco_bairro : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black"><b>Rua: </b>{`${item.endereco_logradouro ? item.endereco_logradouro : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black"><b>Número: </b>{`${item.endereco_numero ? item.endereco_numero : 'Sem dados'}`}</p>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        <hr/>
                                                    </Fragment>
                                                );
                                            })
                                        : (
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <Card>
                                                        <CardBody>
                                                            <h4 style={{ marginBottom: '0px', textAlign: 'center' }}>Não foram encontrados dados...</h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        dadosRelatorioCliente: state.dadosRelatorioCliente,
        enableResponseModal: state.enableResponseModal,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setDadosRelatorioCliente: dados => dispatch(setDadosRelatorioCliente(dados)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClienteVisualizar);