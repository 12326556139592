import CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';

const secretKey = process.env.REACT_APP_KEY_API;

export const decrypt = (hash) => {
    let key = secretKey.substr(7);
    var encrypted_json = JSON.parse(Base64.decode(hash));
    return CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
        iv : CryptoJS.enc.Base64.parse(encrypted_json.iv)
    }).toString(CryptoJS.enc.Utf8);
};
