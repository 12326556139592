import { Component, Fragment } from 'react';
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { setMobileSidebar } from '../reducers/index';
import { isAdmin } from '../servicos/auth';

import { Collapse } from 'reactstrap';

class Sidebar extends Component {

    constructor(props){
        super(props);

        this.state = {
            subMenuProdutos: false,
            subMenuEnderecos: false,
            subMenuRelatorios: false,
        }
    }

    componentDidMount(){

        let { setMobileSidebar } = this.props;

        const links = document.querySelectorAll(".link-item")
        for (const link of links) {
            link.addEventListener('click', function() {
                setMobileSidebar(false);
            });
        }
    }

    render () {
        return (
            <Fragment>
                <nav className={`sidebar sidebar-offcanvas ${this.props.enableMobileSidebar ? 'active' : ''}`} id="sidebar">
                    <ul className="nav">
                        
                        <li className={`nav-item link-item ${window.location.pathname === '/inicio' ? ' active' : ''}`}>
                            <Link replace className="nav-link" to="/inicio">
                                <i className="fa fa-home mr-20" aria-hidden="true"/>
                                <span className="menu-title">Início</span>
                            </Link>
                        </li>
    
                        {
                            isAdmin() ? 
                                <>
                                    <li className={`nav-item link-item ${window.location.pathname === '/estabelecimento' || window.location.pathname === '/estabelecimento/formulario' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/estabelecimento">
                                            <i className="icon-grid menu-icon"></i>
                                            <span className="menu-title">Estabelecimentos</span>
                                        </Link>
                                    </li>
                                    
                                    <li className={`nav-item link-item ${window.location.pathname === '/tipo-usuario' || window.location.pathname === '/tipo-usuario/formulario' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/tipo-usuario">
                                            <i className="icon-grid menu-icon"></i>
                                            <span className="menu-title">Tipos de usuário</span>
                                        </Link>
                                    </li>
                                </>
                            : ''
                        }
    
                        <li className={`nav-item link-item ${window.location.pathname === '/pedido' || window.location.pathname === '/pedido/formulario' ? ' active' : ''}`}>
                            <Link replace className="nav-link" to="/pedido">
                                <i className="fa fa-shopping-cart mr-20 fs-20" aria-hidden="true"/>
                                <span className="menu-title">Pedidos</span>
                            </Link>
                        </li>
    
                        {/* <li className={`nav-item link-item ${window.location.pathname === '/pedido-parcelado' || window.location.pathname === '/pedido-parcelado/formulario' ? ' active' : ''}`}>
                            <Link replace className="nav-link" to="/pedido-parcelado">
                                <i className="fa fa-credit-card mr-20 fs-20" aria-hidden="true"/>
                                <span className="menu-title">Pedidos parcelados</span>
                            </Link>
                        </li> */}

                        <li className={`nav-item link-item ${window.location.pathname === '/cliente' || window.location.pathname === '/cliente/formulario' ? ' active' : ''}`}>
                            <Link replace className="nav-link" to="/cliente">
                                <i className="fa fa-users mr-20 fs-20" aria-hidden="true"/>
                                <span className="menu-title">Clientes</span>
                            </Link>
                        </li>

                        <li className={`nav-item pointer ${this.state.subMenuEnderecos || window.location.pathname === '/estado' || window.location.pathname === '/estado/formulario' || window.location.pathname === '/cidade' || window.location.pathname === '/cidade/formulario' || window.location.pathname === '/bairro' || window.location.pathname === '/bairro/formulario' || window.location.pathname === '/endereco' || window.location.pathname === '/endereco/formulario' ? ' active' : ''}`} onClick={() => this.setState({subMenuEnderecos: !this.state.subMenuEnderecos})}>
                            <div className="nav-link">
                                <i className="fa fa-map-marker mr-20 fs-20" aria-hidden="true"/>
                                <span className="menu-title">Endereço</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse isOpen={this.state.subMenuEnderecos} className="br-10">
                                <ul className="nav flex-column sub-menu br-10">
                                    
                                    <li className={`nav-item link-item ${window.location.pathname === '/estado' || window.location.pathname === '/estado/formulario' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/estado">
                                            <span className="menu-title">Estados</span>
                                        </Link>
                                    </li>
                
                                    <li className={`nav-item link-item ${window.location.pathname === '/cidade' || window.location.pathname === '/cidade/formulario' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/cidade">
                                            <span className="menu-title">Cidades</span>
                                        </Link>
                                    </li>
                
                                    <li className={`nav-item link-item ${window.location.pathname === '/bairro' || window.location.pathname === '/bairro/formulario' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/bairro">
                                            <span className="menu-title">Bairros</span>
                                        </Link>
                                    </li>

                                    <li className={`nav-item link-item ${window.location.pathname === '/endereco' || window.location.pathname === '/endereco/formulario' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/endereco">
                                            <span className="menu-title">Endereços</span>
                                        </Link>
                                    </li>

                                </ul>
                            </Collapse>
                        </li>

                        <li className={`nav-item pointer ${this.state.subMenuProdutos || window.location.pathname === '/produto' || window.location.pathname === '/produto/formulario' || window.location.pathname === '/tipo-produto' || window.location.pathname === '/tipo-produto/formulario' || window.location.pathname === '/categoria-produto' || window.location.pathname === '/categoria-produto/formulario' ? ' active' : ''}`} onClick={() => this.setState({subMenuProdutos: !this.state.subMenuProdutos})}>
                            <div className="nav-link">
                                <i className="fa fa-list mr-20 fs-20" aria-hidden="true"/>
                                <span className="menu-title">Produto</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse isOpen={this.state.subMenuProdutos} className="br-10">
                                <ul className="nav flex-column sub-menu br-10">
                                    
                                    <li className={`nav-item link-item ${window.location.pathname === '/categoria-produto' || window.location.pathname === '/categoria-produto/formulario' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/categoria-produto">
                                            <span className="menu-title">Categorias de produto</span>
                                        </Link>
                                    </li>
                
                                    <li className={`nav-item link-item ${window.location.pathname === '/tipo-produto' || window.location.pathname === '/tipo-produto/formulario' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/tipo-produto">
                                            <span className="menu-title">Tipos de produto</span>
                                        </Link>
                                    </li>
                                    
                                    <li className={`nav-item link-item ${window.location.pathname === '/produto' || window.location.pathname === '/produto/formulario' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/produto">
                                            <span className="menu-title">Produtos</span>
                                        </Link>
                                    </li>

                                </ul>
                            </Collapse>
                        </li>
    
                        <li className={`nav-item pointer ${this.state.subMenuRelatorios || window.location.pathname === '/relatorio/pedidos/filtro' || window.location.pathname === '/relatorio/pedidos/visualizar' || window.location.pathname === '/relatorio/indicadores/filtro' || window.location.pathname === '/relatorio/indicadores/visualizar' || window.location.pathname === '/relatorio/geral/filtro' || window.location.pathname === '/relatorio/geral/visualizar' ? ' active' : ''}`} onClick={() => this.setState({subMenuRelatorios: !this.state.subMenuRelatorios})}>
                            <div className="nav-link">
                                <i className="fa fa-line-chart mr-20 fs-20" aria-hidden="true"/>
                                <span className="menu-title">Relatório</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse isOpen={this.state.subMenuRelatorios} className="br-10">
                                <ul className="nav flex-column sub-menu br-10">
                                    
                                    <li className={`nav-item link-item ${window.location.pathname === '/relatorio/pedidos/filtro' || window.location.pathname === '/relatorio/pedidos/visualizar' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/relatorio/pedidos/filtro">
                                            <span className="menu-title">Pedidos</span>
                                        </Link>
                                    </li>

                                    <li className={`nav-item link-item ${window.location.pathname === '/relatorio/clientes/filtro' || window.location.pathname === '/relatorio/clientes/visualizar' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/relatorio/clientes/filtro">
                                            <span className="menu-title">Clientes</span>
                                        </Link>
                                    </li>
                
                                    {/* <li className={`nav-item link-item ${window.location.pathname === '/relatorio/indicadores/filtro' || window.location.pathname === '/relatorio/indicadores/visualizar' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/relatorio/indicadores/filtro">
                                            <span className="menu-title">Indicadores</span>
                                        </Link>
                                    </li> */}
                
                                    <li className={`nav-item link-item ${window.location.pathname === '/relatorio/geral/filtro' || window.location.pathname === '/relatorio/geral/visualizar' ? ' active' : ''}`}>
                                        <Link replace className="nav-link" to="/relatorio/geral/filtro">
                                            <span className="menu-title">Geral</span>
                                        </Link>
                                    </li>

                                </ul>
                            </Collapse>
                        </li>
    
                        <li className={`nav-item link-item ${window.location.pathname === '/usuario' || window.location.pathname === '/usuario/formulario' ? ' active' : ''}`}>
                            <Link replace className="nav-link" to="/usuario">
                                <i className="fa fa-user-o mr-20 fs-20" aria-hidden="true"/>
                                <span className="menu-title">Usuários</span>
                            </Link>
                        </li>
                        
                        <li className={`nav-item link-item ${window.location.pathname === '/dia-ideal' || window.location.pathname === '/dia-ideal/formulario' ? ' active' : ''}`}>
                            <Link replace className="nav-link" to="/dia-ideal">
                                <i className="fa fa-calendar mr-20 fs-20" aria-hidden="true"/>
                                <span className="menu-title">Dias ideais</span>
                            </Link>
                        </li>
                        
                        <li className={`nav-item link-item ${window.location.pathname === '/periodo-ideal' || window.location.pathname === '/periodo-ideal/formulario' ? ' active' : ''}`}>
                            <Link replace className="nav-link" to="/periodo-ideal">
                                <i className="fa fa-calendar mr-20 fs-20" aria-hidden="true"/>
                                <span className="menu-title">Períodos ideais</span>
                            </Link>
                        </li>
    
                    </ul>
                </nav>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        enableMobileSidebar: state.enableMobileSidebar,
    }
};

const mapDispatchToProps = dispatch => ({
    setMobileSidebar: enable => dispatch(setMobileSidebar(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);