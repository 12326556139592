import React from "react";
export default class ImageComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = { isOpen: false };
    }

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        return (
            <>
                <img
                    className="after-img rounded image-fit zoom-in"
                    src={this.props.imagem}
                    onClick={this.handleShowDialog}
                    alt="Imagem produto"
                />
                {this.state.isOpen && (
                <dialog
                    className="dialog zoom-out"
                    open
                    onClick={this.handleShowDialog}
                >
                    <img
                        className="image"
                        src={this.props.imagem}
                        onClick={this.handleShowDialog}
                        alt="Imagem produto"
                    />
                </dialog>
                )}
            </>
        );
    }
}
