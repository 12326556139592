export default function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    date.toLocaleString('pt-BR', { month: 'long' });
    return capitalize(date.toLocaleString('pt-BR', { month: 'long' })).substr(0, 3);
}

const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

const randomNum = () => Math.floor(Math.random() * 255);

export const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;