import { decrypt } from '../utilidades/crypto';

import { queryClient } from '../utilidades/queryClient';

export const TOKEN = "token";
export const TIPO_ID = "tipo_id";
export const ESTABELECIMENTO_ID = "estabelecimento_id";
const estabelecimento_id_admin = process.env.REACT_APP_ESTABELECIMENTO_ADMIN_ID;

export const isAuthenticated = () => sessionStorage.getItem(TOKEN) !== null;
export const getToken = () => sessionStorage.getItem(TOKEN);

export const getTipo = () => {
    let tipo_id = sessionStorage.getItem(TIPO_ID);
    return decrypt(tipo_id);
}

export const isAdmin = () => {
    let estabelecimento_id_enc = sessionStorage.getItem(ESTABELECIMENTO_ID);
    let estabelecimento_id_dec = decrypt(estabelecimento_id_enc);
    return estabelecimento_id_admin === estabelecimento_id_dec ? true : false;
}

export const login = (token, tipo_id, estabelecimento_id) => {
    sessionStorage.setItem(TOKEN, token);
    sessionStorage.setItem(TIPO_ID, tipo_id);
    sessionStorage.setItem(ESTABELECIMENTO_ID, estabelecimento_id);
};

export const limpaSessao = () => {
    sessionStorage.removeItem(TOKEN);
    sessionStorage.removeItem(TIPO_ID);
    sessionStorage.removeItem(ESTABELECIMENTO_ID);
    queryClient.removeQueries();
};

export const logout = () => {
    sessionStorage.removeItem(TOKEN);
    sessionStorage.removeItem(TIPO_ID);
    sessionStorage.removeItem(ESTABELECIMENTO_ID);
    queryClient.removeQueries();
};