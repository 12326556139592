import error_icon from '../../assets/img/error_icon.png'
import success_icon from '../../assets/img/success_icon.png'

import { Link } from "react-router-dom";
import { Button } from 'reactstrap';

const Entregue = ({ value }) => {
    return (
        <div className='rgt-cell-inner' style={{display: 'flex', justifyContent: 'center', overflow: 'hidden'}}>
            <img style={{width: "28px"}} alt={value === 1 || value === '1' ? "Entregue" : "Não entregue"} src={value === 1 || value === '1' ? success_icon : error_icon}/>
        </div>
    );
}

const Pago = ({ value }) => {
    return (
        <div className='rgt-cell-inner' style={{display: 'flex', justifyContent: 'center', overflow: 'hidden'}}>
            <img style={{width: "28px"}} alt={value === 1 || value === '1' ? "Pago" : "Não pago"} src={value === 1 || value === '1' ? success_icon : error_icon}/>
        </div>
    );
}

const BotaoEditar = ({ data, next_url_editar }) => {
    return (
        <Link replace
            to={{
                pathname: next_url_editar,
                data: data,
                operation: "alt"
            }}
        >
            <Button id={`editar_${data.id}`} type="button" aria-label="buy product" className="btn btn-rounded btn-sm btn-warning btn-icon mr-2 bd-rd-8">
                <i className="fa fa-edit"></i>
            </Button>
        </Link>
    )
}

const BotaoParcelas = ({ data, next_url_parcelar }) => {
    return (
        <Link replace
            to={{
                pathname: next_url_parcelar,
                data: data,
                operation: "par"
            }}
        >
            <Button onClick={() => sessionStorage.setItem('uuid_pedido', data.uuid)} id={`parcelas_${data.id}`} type="button" aria-label="parcelas" style={{backgroundColor: '#0060cd', borderColor:'#0060cd'}} className="btn btn-rounded btn-sm btn-info btn-icon mr-2 bd-rd-8">
                <i className="fa fa-credit-card"></i>
            </Button>
        </Link>
    )
}

const OpcoesPedido = ({data, next_url_editar, next_url_parcelar}) => {
    return(
        <div className='rgt-cell-inner' style={{display: 'flex', justifyContent: 'center', overflow: 'hidden'}}>
            <BotaoEditar data={data} next_url_editar={next_url_editar}/>
            { data && data.parcelado ? <BotaoParcelas data={data} next_url_parcelar={next_url_parcelar}/> : '' }
        </div>
    );
}

const colunas = () => {
    return [
        {
            id: 1,
            field: 'id', 
            label: 'Código',
            width: 'minmax(150px, auto)',
        },
        {
            id: 2, 
            width: 'minmax(200px, auto)',
            field: 'nome', 
            label: 'Nome',
        },
        {
            id: 3,
            width: '1fr',
            field: 'valor_formatado',
            label: 'Valor',
        },
        {
            id: 6, 
            width: 'minmax(150px, auto)',
            field: 'pago', 
            label: 'Pago',
            cellRenderer: Pago,
        },
        {
            id: 4,
            width: 'minmax(150px, auto)',
            field: 'entregue', 
            label: 'Entregue',
            cellRenderer: Entregue,
        },
        {
            id: 5,
            label: 'Opções',
            width: 'minmax(200px, auto)',
            cellRenderer: ( { tableManager, data } ) => {
                return (<OpcoesPedido
                    next_url_editar={tableManager.config.additionalProps.next_url_editar}
                    next_url_parcelar={tableManager.config.additionalProps.next_url_parcelar}
                    data={data}
                />);
            },
        }
    ];
}

export default colunas;
