import error_icon from '../../../assets/img/error_icon.png'
import success_icon from '../../../assets/img/success_icon.png'

import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import { ButtonDestroy } from '../../../Components/Destroy';

const Pago = ({ value }) => {
    return (
        <div className='rgt-cell-inner' style={{display: 'flex', justifyContent: 'center', overflow: 'hidden'}}>
            <img style={{width: "28px"}} alt={value === 1 || value === '1' ? "Pago" : "Não pago"} src={value === 1 || value === '1' ? success_icon : error_icon}/>
        </div>
    );
}

const BotaoEditar = ({ data, next_url_editar }) => {
    return (
        <Link replace
            to={{
                pathname: next_url_editar,
                data: data,
                operation: "alt"
            }}
        >
            <Button id={`editar_${data.id}`} type="button" aria-label="buy product" className="btn btn-rounded btn-sm btn-warning btn-icon mr-2 bd-rd-8">
                <i className="fa fa-edit"></i>
            </Button>
        </Link>
    )
}

const OpcoesPedido = ({setDestroyModal, enableDestroyModal, data, next_url_editar}) => {
    return(
        <div className='rgt-cell-inner' style={{display: 'flex', justifyContent: 'center', overflow: 'hidden'}}>
            <BotaoEditar data={data} next_url_editar={next_url_editar}/>
            <ButtonDestroy
                setDestroyModal={setDestroyModal}
                enableDestroyModal={enableDestroyModal}
                item={{ id: data.id, uuid: data.uuid }}
            />
        </div>
    );
}

const colunas = () => {
    return [
        {
            id: 1,
            field: 'numero_formatado', 
            label: 'Número',
            width: 'minmax(150px, auto)',
        },
        {
            id: 2,
            width: '1fr',
            field: 'valor_formatado',
            label: 'Valor',
        },
        {
            id: 3,
            width: '1fr',
            field: 'valor_pago_formatado',
            label: 'Valor pago',
        },
        {
            id: 4, 
            width: 'minmax(150px, auto)',
            field: 'pago', 
            label: 'Pago',
            cellRenderer: Pago,
        },
        {
            id: 5,
            label: 'Opções',
            width: 'minmax(200px, auto)',
            cellRenderer: ( { tableManager, data } ) => {
                return (<OpcoesPedido
                    setDestroyModal={tableManager.config.additionalProps.setDestroyModal}
                    enableDestroyModal={tableManager.config.additionalProps.enableDestroyModal}
                    next_url_editar={tableManager.config.additionalProps.next_url_editar}
                    next_url_parcelar={tableManager.config.additionalProps.next_url_parcelar}
                    data={data}
                />);
            },
        }
    ];
}

export default colunas;
