import { Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Switch from 'react-switch';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { useFormikContext } from 'formik';

const numberMask = createNumberMask({
    prefix: 'R$ ',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    allowNegative: false,
    requireDecimal: true
});

const ModalConferePedido = () => {

    const { values, setFieldValue } = useFormikContext();

    const toggleModal = () => {
        setFieldValue('enableConferePedidoModal', !values.enableConferePedidoModal);
    }

    return (
        <Fragment>
            <Modal isOpen={values.enableConferePedidoModal} id="modalConfere" toggle={toggleModal}>
                <ModalHeader className="bg-maskats text-center">
                    {
                        values.id ? 
                            `Informações do pedido ${values.id}` : 'Informações do pedido'
                    }
                </ModalHeader>
                <ModalBody>
                    <p className="card-description"><b>Cliente: </b>{`${values.cliente_id ? values.cliente_id.label : 'Sem dados'}`}</p>
                    <p className="card-description"><b>Data de entrega: </b>{`${values.data_entrega ? values.data_entrega : 'Sem dados'}`}</p>
                    <p className="card-description"><b>Entregue: </b>{`${values.entregue ? 'Sim' : 'Não'}`}</p>
                    <p className="card-description"><b>Pago: </b>{`${values.pago ? 'Sim' : 'Não'}`}</p>
                    <p className="card-description" style={{whiteSpace: 'pre-line'}}><b>Obseração: </b><br/>{`${values.observacao ? values.observacao : 'Sem dados'}`}</p>
                    <p className="card-description"><b>Produtos: </b>{`${values.produto_id && values.produto_id.length > 0 ? '' : 'Sem dados'}`} </p>
                    <ul className="list-arrow">
                        {
                            values.produto_id && values.produto_id.length > 0 ?
                                values.produto_id.map(item => {
                                    return <li key={item.value} id={item.value}><b>{ (item.nome.toLowerCase()).indexOf('kg') === -1 ? `${item.quantidade_produto}` : `${(item.quantidade_produto).toFixed(3).replaceAll('.', ',')} Kg`} - R${item.valor_momento_venda !== '' ? item.valor_momento_venda : item.valor}</b> {'->'} {item.nome}</li>
                                }) : ''
                        }
                    </ul>
                    <p className="card-description"><b>Total sem {parseFloat(values.desconto.replaceAll(',','.')).toFixed(2) >= 0 ? 'desconto' : 'acréscimo'}: </b> R${values.valor_total ? values.valor_total : 'Sem dados'} </p>
                    <p className="card-description"><b>{parseFloat(values.desconto.replaceAll(',','.')).toFixed(2) >= 0 ? 'Desconto' : 'Acréscimo'}: </b> R${values.desconto ? values.desconto.replaceAll('-','') : 'Sem dados'} </p>
                    <p className="card-description"><b>Total com {parseFloat(values.desconto.replaceAll(',','.')).toFixed(2) >= 0 ? 'desconto' : 'acréscimo'}: </b> R${values.valor_total_momento_venda ? values.valor_total_momento_venda : 'Sem dados'} </p>
                    <p className="card-description"><b>Total pago: </b> R${values.valor_pago ? values.valor_pago : 'Sem dados'} </p>
                    <p className="card-description"><b>{parseFloat(values.total_pendente.replaceAll(',','.')).toFixed(2) >= 0 ? 'Total à receber' : 'Troco'}: </b> R${values.total_pendente ? values.total_pendente.replaceAll('-','') : 'Sem dados'} </p>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-sm" color="secondary" onClick={toggleModal}>Fechar</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export const ModalAdicionaProdutoPedido = () => {

    const { values, touched, errors, setFieldValue, handleBlur, handleChange } = useFormikContext();

    const toggleModal = () => {
        setFieldValue('enableAdicionaProdutoPedidoModal', !values.enableAdicionaProdutoPedidoModal);
        setFieldValue('peso', false);
    }

    const salvaProdutoPedido = () => {

        let produto = JSON.parse(document.querySelector('#produto_selecionado').value);

        let index = values.produto_id.findIndex(item => item.value === produto.value);

        let produtos = [];
        
        produtos = values.produto_id;

        let valor_momento_venda = produtos[index].valor;
        let quantidade_produto = 1.00;

        if(values.quantidade_produto !== '' && values.valor_momento_venda !== ''){
            if(values.peso) {
                quantidade_produto = values.quantidade_produto;
                valor_momento_venda = (quantidade_produto * parseFloat(((values.valor_momento_venda.replaceAll('R$ ', '')).replaceAll('.', '')).replaceAll(',', '.'))).toFixed(2).replaceAll('.', ',');
            } else {
                valor_momento_venda = (parseFloat(((values.valor_momento_venda.replaceAll('R$ ', '')).replaceAll('.', '')).replaceAll(',', '.'))).toFixed(2).replaceAll('.', ',');
                quantidade_produto = parseInt(values.quantidade_produto);
            }
        } else if (values.quantidade_produto !== '' && !(values.valor_momento_venda !== '')) {
            if(values.peso) {
                quantidade_produto = values.quantidade_produto;
                valor_momento_venda = (quantidade_produto * parseFloat(((valor_momento_venda.replaceAll('R$ ', '')).replaceAll('.', '')).replaceAll(',', '.'))).toFixed(2).replaceAll('.', ',');
            } else {
                quantidade_produto = parseInt(values.quantidade_produto);
                valor_momento_venda = (quantidade_produto * parseFloat(((valor_momento_venda.replaceAll('R$ ', '')).replaceAll('.', '')).replaceAll(',', '.'))).toFixed(2).replaceAll('.', ',');
            }
        } else if (!(values.quantidade_produto !== '') && values.valor_momento_venda !== '') {
            valor_momento_venda = (quantidade_produto * parseFloat(((values.valor_momento_venda.replaceAll('R$ ', '')).replaceAll('.', '')).replaceAll(',', '.'))).toFixed(2).replaceAll('.', ',');
        }

        produtos[index].valor_momento_venda = valor_momento_venda;
        produtos[index].quantidade_produto  = quantidade_produto;

        setFieldValue('produto_id', produtos);
        document.querySelector('#produtos').value = JSON.stringify(produtos);
        toggleModal();
    }

    return (
        <Fragment>
            <Modal backdrop="static" id="modalAdicionaProduto" isOpen={values.enableAdicionaProdutoPedidoModal} toggle={toggleModal}>
                <ModalHeader className="bg-maskats text-center">
                    Adicionar produto
                </ModalHeader>
                <ModalBody>

                    <FormGroup>
                        <Label for="valor_momento_venda">Valor</Label>
                        <MaskedInput
                            mask={numberMask}
                            guide={false}
                            className="form-control form-control-sm" 
                            id="valor_momento_venda" 
                            name="valor_momento_venda" 
                            type="text" 
                            inputMode="decimal"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.valor_momento_venda}
                        />
                        {touched.valor_momento_venda && errors.valor_momento_venda ? (
                            <div className="divError">{errors.valor_momento_venda}</div>
                        ) : null}
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col md={9} sm={9} xs={9}>
                                <Label for="quantidade_produto">Quantidade</Label>
                                <br/>
                                <Input
                                    className="form-control form-control-sm" 
                                    id="quantidade_produto" 
                                    name="quantidade_produto" 
                                    type="number" 
                                    inputMode={values.peso ? "decimal" : "numeric"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.quantidade_produto}
                                />
                                {touched.quantidade_produto && errors.quantidade_produto ? (
                                    <div className="divError">{errors.quantidade_produto}</div>
                                ) : null}
                            </Col>
                            <Col md={3} sm={3} xs={3}>
                                <Label for="peso">Peso</Label>
                                <br/>
                                <Switch
                                    id="peso"
                                    name="peso"
                                    onColor="#28a745"
                                    offColor="#dc3545"
                                    checked={values.peso}
                                    onChange={()=>setFieldValue('peso', !values.peso)}
                                />
                            </Col>
                        </Row>
                    </FormGroup>

                </ModalBody>
                <ModalFooter>
                    <Button className="btn-sm" color="secondary" onClick={toggleModal}>Cancelar</Button>
                    <Button className="btn-sm" color="success" onClick={salvaProdutoPedido}>Adicionar</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default ModalConferePedido;