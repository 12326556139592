import React, { useState, useEffect } from 'react';
import { postData, putData, getData } from "../../servicos/api";
import { isAdmin } from "../../servicos/auth";
import * as Yup from 'yup';
import { FormGroup, Label, Input, Button, Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';
import ModalConferePedido, { ModalAdicionaProdutoPedido } from '../../Components/ConferePedido';
import Combobox from '../../Components/Combobox';
import { Link } from "react-router-dom";
import Switch from 'react-switch';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { Formik, Form } from 'formik';

//REDUX
import { setLoaderShow, setResponseModal } from '../../reducers/index';
import { connect } from 'react-redux';

import { useQuery } from 'react-query';

import { queryClient } from '../../utilidades/queryClient';

const numberMask = createNumberMask({
    prefix: 'R$ ',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    allowNegative: false,
    requireDecimal: true
});

const Formulario = (props) => {
    let estabelecimento;
    if (props.data && props.data.estabelecimento_id !== undefined && props.data.estabelecimento_id !== null && props.listaEstabelecimentos.length > 0) {
        estabelecimento = props.listaEstabelecimentos.filter(function (elem, i, object) {
            return elem.value === props.data.estabelecimento_id;
        });
        if(estabelecimento[0] !== undefined){
            estabelecimento = { value: estabelecimento[0].value, label: estabelecimento[0].label };
        }
    }

    let cliente;
    if (props.data && props.data.cliente_id !== undefined && props.data.cliente_id !== null && props.listaClientes.length > 0) {
        cliente = props.listaClientes.filter(function (elem, i, object) {
            return elem.value === props.data.cliente_id;
        });
        if(cliente[0] !== undefined){
            cliente = { value: cliente[0].value, label: cliente[0].label };
        }
    }

    let produtos = [];
    if (props.data && props.data.produtos.length > 0 && props.listaProdutos.length > 0) {
        props.data.produtos.map(function (item) {
            produtos.push(JSON.stringify(props.listaProdutos.filter(
                (elem, i, object) => {
                    if(elem.value === item.id){
                        elem.valor_momento_venda = item.valor_momento_venda;
                        elem.quantidade_produto = item.quantidade_produto;
                        return elem;
                    }else{
                        return null;
                    }
                }   
            )));
            return 0;
        });
        produtos = produtos.map(function (value) {
            let arr = JSON.parse(value);
            return arr[0];
        });
        document.querySelector('#produtos').value = JSON.stringify(produtos);
    }

    let validarAdmin = Yup.object({
        estabelecimento_id: Yup.object()
            .required('O campo estabelecimento é obrigatório.'),
        cliente_id: Yup.object()
            .required('O campo cliente é obrigatório.'),
        produto_id: Yup.array()
            .required('O campo produto é obrigatório.'),
        data_entrega: Yup.string()
            .min(10, 'O campo data de entrega deverá ter no mínimo 10 caracteres.')
            .max(10, 'O campo data de entrega deverá ter no máximo 10 caracteres.')
            .nullable()
            .trim(),
        data_pagamento: Yup.string()
            .min(10, 'O campo data de pagamento deverá ter no mínimo 10 caracteres.')
            .max(10, 'O campo data de pagamento deverá ter no máximo 10 caracteres.')
            .nullable()
            .trim(),
        observacao: Yup.string()
            .max(500, 'O observação deverá ter no máximo 500 caracteres.')
            .nullable()
            .trim()
    });

    let validarEstabelecimento = Yup.object({
        cliente_id: Yup.object()
            .required('O campo cliente é obrigatório.'),
        produto_id: Yup.array()
            .required('O campo produto é obrigatório.'),
        data_entrega: Yup.string()
            .min(10, 'O campo data de entrega deverá ter no mínimo 10 caracteres.')
            .max(10, 'O campo data de entrega deverá ter no máximo 10 caracteres.')
            .nullable()
            .trim(),
        data_pagamento: Yup.string()
            .min(10, 'O campo data de pagamento deverá ter no mínimo 10 caracteres.')
            .max(10, 'O campo data de pagamento deverá ter no máximo 10 caracteres.')
            .nullable()
            .trim(),
        observacao: Yup.string()
            .max(500, 'O observação deverá ter no máximo 500 caracteres.')
            .nullable()
            .trim()
    });

    const conferePedido = (formik) => {

        let valor_total_momento_venda = formik.values.produto_id.map( item => parseFloat((item.valor_momento_venda.replaceAll('.', '')).replaceAll(',', '.') )).reduce((partialSum, a) => partialSum + a, 0).toFixed(2);
        let valor_total = formik.values.produto_id.map( item => (parseFloat((item.valor.replaceAll('.', '')).replaceAll(',', '.') ) * item.quantidade_produto)).reduce((partialSum, a) => partialSum + a, 0).toFixed(2);
        let desconto = (valor_total - valor_total_momento_venda).toFixed(2);

        let valor_pago_novo = formik.values.valor_pago === '' ? parseFloat(((('R$ 0,00').replaceAll('.', '')).replaceAll(',', '.')).replaceAll('R$ ', '')).toFixed(2) : parseFloat(((formik.values.valor_pago.replaceAll('.', '')).replaceAll(',', '.')).replaceAll('R$ ', '')).toFixed(2);

        let total_pendente = (parseFloat(valor_total_momento_venda) - valor_pago_novo).toFixed(2);

        formik.setFieldValue('valor_total', valor_total.replaceAll('.', ','));
        formik.setFieldValue('valor_total_momento_venda', valor_total_momento_venda.replaceAll('.', ','));
        formik.setFieldValue('total_pendente', total_pendente.replaceAll('.', ','));
        formik.setFieldValue('valor_pago', valor_pago_novo.replaceAll('.', ','));
        formik.setFieldValue('desconto', desconto.replaceAll('.', ','));
        
        formik.setFieldValue('enableConferePedidoModal', !formik.values.enableConferePedidoModal);
    }

    return (
        <Formik
            initialValues={{
                estabelecimento_id:  estabelecimento,
                cliente_id:          cliente,
                produto_id:          produtos,
                id:                  props.data !== undefined && props.data !== null ? props.data.id : '',
                data_entrega:        props.data !== undefined && props.data !== null ? props.data.data_entrega : '',
                data_pagamento:      props.data !== undefined && props.data !== null ? props.data.data_pagamento : '',
                observacao:          props.data !== undefined && props.data !== null ? props.data.observacao : '',
                valor_total:         props.data !== undefined && props.data !== null ? props.data.valor_total : '',
                valor_pago:          props.data !== undefined && props.data !== null ? props.data.valor_pago : '0,00',
                entregue:            props.data !== undefined && props.data !== null ? !!parseInt(props.data.entregue) : true,
                peso:                false,
                pago:                props.data !== undefined && props.data !== null ? !!parseInt(props.data.pago) : false,
                parcelado:           props.data !== undefined && props.data !== null ? !!parseInt(props.data.parcelado) : false,
                produto_selecionado: '',
                quantidade_produto:  '',

                valor_total_momento_venda: '',
                desconto: '',
                total_pendente: '',

                enableConferePedidoModal: false,
                enablePedidoParcelaModal: false,
                enableAdicionaProdutoPedidoModal: false
            }}
            enableReinitialize={true}
            dirty={false}
            validationSchema={isAdmin() ? validarAdmin : validarEstabelecimento}
            onSubmit= { values => {

                let valor_pago = values.valor_pago === '' ? 'R$ 0,00' : values.valor_pago;

                valor_pago = parseFloat(((valor_pago.replaceAll('.', '')).replaceAll(',', '.')).replaceAll('R$ ', ''));

                let valor_total = 0.00;

                let produtos = JSON.parse(document.querySelector('#produtos').value);

                produtos = produtos.map( item => {
                    item.valor = parseFloat(((item.valor.replaceAll('.', '')).replaceAll(',', '.')));
                    item.valor_momento_venda = parseFloat(((item.valor_momento_venda.replaceAll('.', '')).replaceAll(',', '.')));

                    valor_total += item.valor_momento_venda;
                    return item;
                });

                let data = {
                    "estabelecimento_id": values.estabelecimento_id || (isAdmin()) ? values.estabelecimento_id.value : null,
                    "cliente_id": values.cliente_id ? values.cliente_id.value : null,
                    "produto_id": produtos,
                    "data_entrega" : values.data_entrega === "" ? null : values.data_entrega,
                    "data_pagamento" : values.data_pagamento === "" ? null : values.data_pagamento,
                    "observacao" : values.observacao === "" ? null : values.observacao,
                    "valor_total" : valor_total.toFixed(2),
                    "valor_pago" : valor_pago.toFixed(2),
                    "entregue": values.entregue,
                    "pago": values.pago,
                    "parcelado": values.parcelado,
                    "ativo": true,
                }

                if (props.operation !== undefined && props.operation !== null && props.operation === 'alt') {
                    props.updatePedido(data, props.data.uuid);
                } else {
                    props.insertPedido(data);
                }
            }}
            >
            { formik => (
                <>
                    <Form className="forms-sample col-12" onSubmit={formik.handleSubmit}>
                        <Card>
                            <CardHeader className="bg-maskats text-center">
                                <h4 className="headerTitle">Cadastro de pedidos</h4>
                            </CardHeader>
                            <CardBody>
                                <div>

                                    {
                                        isAdmin() ? 
                                            <FormGroup>
                                                <Label for="estabelecimento_id">* Estabelecimento</Label>
                                                <Combobox
                                                    invalid={formik.touched.estabelecimento_id && formik.errors.estabelecimento_id}
                                                    valid={formik.touched.estabelecimento_id && !formik.errors.estabelecimento_id}
                                                    id="estabelecimento_id"
                                                    name="estabelecimento_id"
                                                    placeholder={'Selecione um item'}
                                                    defaultValue={formik.values.estabelecimento_id}
                                                    value={formik.values.estabelecimento_id}
                                                    options={props.listaEstabelecimentos}
                                                    isMulti={false}
                                                    loadingMessage={"Carregando..."}
                                                    noOptionsMessage={"Não existem dados cadastrados."}
                                                    onChange={formik.setFieldValue}
                                                    onBlur={formik.setFieldTouched}
                                                    maxMenuHeight={190}
                                                />
                                                {formik.touched.estabelecimento_id && formik.errors.estabelecimento_id ? (
                                                    <div className="divError">{formik.errors.estabelecimento_id}</div>
                                                ) : null}
                                            </FormGroup>
                                        : ""
                                    }

                                    <FormGroup>
                                        <Label for="cliente_id">* Cliente</Label>
                                        <Combobox
                                            invalid={formik.touched.cliente_id && formik.errors.cliente_id}
                                            valid={formik.touched.cliente_id && !formik.errors.cliente_id}
                                            id="cliente_id"
                                            name="cliente_id"
                                            placeholder={'Selecione um item'}
                                            defaultValue={formik.values.cliente_id}
                                            value={formik.values.cliente_id}
                                            options={props.listaClientes}
                                            isMulti={false}
                                            loadingMessage={"Carregando..."}
                                            noOptionsMessage={"Não existem dados cadastrados."}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            maxMenuHeight={190}
                                        />
                                        {formik.touched.cliente_id && formik.errors.cliente_id ? (
                                            <div className="divError">{formik.errors.cliente_id}</div>
                                        ) : null}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="produto_id">* Produtos</Label>
                                        <Combobox
                                            invalid={formik.touched.produto_id && formik.errors.produto_id}
                                            valid={formik.touched.produto_id && !formik.errors.produto_id}
                                            id="produto_id"
                                            name="produto_id"
                                            placeholder={'Selecione um item'}
                                            defaultValue={formik.values.produto_id}
                                            value={formik.values.produto_id}
                                            options={props.listaProdutos}
                                            isMulti={true}
                                            loadingMessage={"Carregando..."}
                                            noOptionsMessage={"Não existem dados cadastrados."}
                                            onChange={ (input, value) => {
                                                formik.setFieldValue(input, value);
                                                formik.setFieldValue('valor_momento_venda', '');
                                                formik.setFieldValue('quantidade_produto', '');

                                                if (value.length >= formik.values.produto_id.length) {
                                                    document.querySelector('#produto_selecionado').value = JSON.stringify(value[value.length - 1]);
                                                    formik.setFieldValue('enableAdicionaProdutoPedidoModal', !formik.values.enableAdicionaProdutoPedidoModal);
                                                }
                                            }}
                                            onBlur={formik.setFieldTouched}
                                            maxMenuHeight={190}
                                        />
                                        {formik.touched.produto_id && formik.errors.produto_id ? (
                                            <div className="divError">{formik.errors.produto_id}</div>
                                        ) : null}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="data_entrega">Data de entrega</Label>
                                        <MaskedInput
                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                            guide={false}
                                            className="form-control form-control-sm" 
                                            id="data_entrega" 
                                            name="data_entrega" 
                                            type="text" 
                                            inputMode="numeric"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.data_entrega}
                                            minLength={10}
                                            maxLength={10}
                                        />
                                        {formik.touched.data_entrega && formik.errors.data_entrega ? (
                                            <div className="divError">{formik.errors.data_entrega}</div>
                                        ) : null}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="data_pagamento">Data de pagamento</Label>
                                        <MaskedInput
                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                            guide={false}
                                            className="form-control form-control-sm" 
                                            id="data_pagamento" 
                                            name="data_pagamento" 
                                            type="text" 
                                            inputMode="numeric"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.data_pagamento}
                                            minLength={10}
                                            maxLength={10}
                                        />
                                        {formik.touched.data_pagamento && formik.errors.data_pagamento ? (
                                            <div className="divError">{formik.errors.data_pagamento}</div>
                                        ) : null}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="valor_pago">Valor pago</Label>
                                        <MaskedInput
                                            mask={numberMask}
                                            guide={false}
                                            className="form-control form-control-sm" 
                                            id="valor_pago" 
                                            name="valor_pago" 
                                            type="text" 
                                            inputMode="decimal"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.valor_pago}
                                        />
                                        {formik.touched.valor_pago && formik.errors.valor_pago ? (
                                            <div className="divError">{formik.errors.valor_pago}</div>
                                        ) : null}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="observacao">Observação</Label>
                                        <Input 
                                            className="form-control form-control-sm" 
                                            id="observacao" 
                                            name="observacao" 
                                            type="textarea"
                                            rows="4"
                                            style={{whiteSpace: "pre-wrap"}}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.observacao}
                                            maxLength={500}
                                        />
                                        {formik.touched.observacao && formik.errors.observacao ? (
                                            <div className="divError">{formik.errors.observacao}</div>
                                        ) : null}
                                    </FormGroup>

                                    <FormGroup>
                                        <Row>
                                            <Col md={2} sm={3}>
                                                <Label for="entregue">Entregue</Label>
                                                <br/>
                                                <Switch
                                                    id="entregue"
                                                    name="entregue"
                                                    onColor="#28a745"
                                                    offColor="#dc3545"
                                                    checked={formik.values.entregue}
                                                    onChange={()=>formik.setFieldValue('entregue', !formik.values.entregue)}
                                                    className="mr-2 mb-2"
                                                />
                                            </Col>
                                            <Col md={2} sm={3}>
                                                <Label for="pago">Pago</Label>
                                                <br />
                                                <Switch
                                                    id="pago"
                                                    name="pago"
                                                    onColor="#28a745"
                                                    offColor="#dc3545"
                                                    checked={formik.values.pago}
                                                    onChange={()=>formik.setFieldValue('pago', !formik.values.pago)}
                                                />
                                            </Col>
                                            <Col md={2} sm={3}>
                                                <Label for="parcelado">Parcelado</Label>
                                                <br />
                                                <Switch
                                                    id="parcelado"
                                                    name="parcelado"
                                                    onColor="#28a745"
                                                    offColor="#dc3545"
                                                    checked={formik.values.parcelado}
                                                    onChange={() => {
                                                        formik.setFieldValue('parcelado', !formik.values.parcelado);
                                                        if(!formik.values.parcelado){
                                                            formik.setFieldValue('enablePedidoParcelaModal', !formik.values.enablePedidoParcelaModal);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    
                                    <Input 
                                        className="form-control form-control-sm" 
                                        id="produto_selecionado" 
                                        name="produto_selecionado" 
                                        type="hidden"
                                    />
                                    <Input 
                                        className="form-control form-control-sm" 
                                        id="produtos" 
                                        name="produtos" 
                                        type="hidden"
                                    />
                                </div>
                            </CardBody>
                            <CardFooter className="text-align-end bg-white">
                                <Link replace to="/pedido">
                                    <Button className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                                        <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                                        <span className="label-button btn-fs-def">Cancelar</span>
                                    </Button>
                                </Link>

                                <Button className="mr-2 text-white btn btn-sm btn-success btn-fw bd-rd-8" onClick={() => conferePedido(formik)} type="button">
                                    <i className="fa fa-list btn-icon-append btn-fs-def"></i>
                                    <span className="label-button btn-fs-def">Conferir</span>
                                </Button>

                                <Button className="btn text-white btn-sm btn-warning btn-fw bd-rd-8" type="submit">
                                    <i className="fa fa-check btn-icon-append btn-fs-def"></i>
                                    <span className="label-button btn-fs-def">Salvar</span>
                                </Button>
                            </CardFooter>
                        </Card>
                    </Form>
                    <ModalAdicionaProdutoPedido/>
                    <ModalConferePedido/>
                </>
            )}
        </Formik>
    );
}

function PedidoFormulario(props) {
    const [listaEstabelecimentos, setListaEstabelecimentos] = useState([]);
    const [listaClientes, setListaClientes] = useState([]);
    const [listaProdutos, setListaProdutos] = useState([]);
    let [dadosPedido, setDadosPedido] = useState(null);
    const [enableConferePedidoModal, setEnableConferePedidoModal] = useState(false);
    const [enableAdicionaProdutoPedidoModal, setEnableAdicionaProdutoPedidoModal] = useState(false);

    const staleTime = 1000 * 60 * 60;

    const estabelecimentosQuery = useQuery('estabelecimentos', () => getData(`/estabelecimento`, props), { staleTime });
    const clientesQuery = useQuery('clientes', () => getData(`/cliente`, props), { staleTime });
    const produtosQuery = useQuery('produtos', () => getData(`/produto`, props), { staleTime });
    const pedidoQuery = useQuery('pedido', () => {
        if (props.location.data?.uuid !== undefined) {
            return getData(`/pedido/${props.location.data.uuid}`, props);
        }
        return null;
    }, { staleTime: 0 } );

    const updatePedido = async (data, uuid) => {
        loadingShow(true);
        queryClient.removeQueries('pedidos');
        await putData(`/pedido/${uuid}`, props, data, '../pedido');
        loadingShow(false);
    }
    const insertPedido = data => {
        loadingShow(true);
        queryClient.removeQueries('pedidos');
        postData(`/pedido`, props, data, '../pedido');
        loadingShow(false);
    }

    function loadingShow(value) {
        let { setLoaderShow } = props;
        setLoaderShow(value);
    }

    useEffect(() => {
        loadingShow(true);
    }, []);

    useEffect(() => {
        if (estabelecimentosQuery.isSuccess && estabelecimentosQuery.data) {
            const mappedEstabelecimentos = estabelecimentosQuery.data.map(value => ({
                value: value.id,
                label: value.nome,
                ativo: value.ativo
            }));
            setListaEstabelecimentos(mappedEstabelecimentos);
        }

        if (clientesQuery.isSuccess && clientesQuery.data) {
            const mappedClientes = clientesQuery.data.map(value => ({
                value: value.id,
                label: value.nome,
                ativo: value.ativo
            }));
            setListaClientes(mappedClientes);
        }

        if (produtosQuery.isSuccess && produtosQuery.data) {
            const mappedProdutos = produtosQuery.data.map(value => ({
                value: value.id,
                label: `${value.id} - ${value.nome}`,
                ativo: value.ativo,
                valor: value.valor,
                valor_momento_venda: value.valor,
                quantidade_produto: 1,
                nome: `${value.nome} (${value.tipo_nome})`
            }));
            setListaProdutos(mappedProdutos);
        }

        if (pedidoQuery.isSuccess && pedidoQuery.data) {
            setDadosPedido(pedidoQuery.data);
        }

        if (estabelecimentosQuery.isSuccess && clientesQuery.isSuccess && produtosQuery.isSuccess && pedidoQuery.isSuccess) {
            loadingShow(false);
        }

    }, [
        estabelecimentosQuery.isSuccess,
        estabelecimentosQuery.data,
        clientesQuery.isSuccess,
        clientesQuery.data,
        produtosQuery.isSuccess,
        produtosQuery.data,
        pedidoQuery.isSuccess,
        pedidoQuery.data
    ]);

    const { operation } = props.location;
    const { setResponseModal, enableResponseModal } = props;

    return (
        <>
            <div className="container-scroller">
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin stretch-card">
                                    <Formulario
                                        data={dadosPedido}
                                        operation={operation}
                                        updatePedido={updatePedido}
                                        insertPedido={insertPedido}
                                        listaEstabelecimentos={listaEstabelecimentos}
                                        listaClientes={listaClientes}
                                        listaProdutos={listaProdutos}
                                        setConferePedidoModal={setEnableConferePedidoModal}
                                        enableConferePedidoModal={enableConferePedidoModal}
                                        setAdicionaProdutoPedidoModal={setEnableAdicionaProdutoPedidoModal}
                                        enableAdicionaProdutoPedidoModal={enableAdicionaProdutoPedidoModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalResponse
                history={props.history}
                setResponseModal={setResponseModal}
                enableResponseModal={enableResponseModal}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PedidoFormulario);