import { Component, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Button, Tooltip } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';
import { postFilter } from "../../servicos/api";

//REDUX
import { setLoaderShow, setDadosRelatorioPedido, setResponseModal } from '../../reducers/index';
import { connect } from 'react-redux';

import { Pie, Doughnut } from 'react-chartjs-2';
import getMonthName, { randomRGB } from '../../utilidades/utilidades';

const datasets_pedidosXmes = [
    {
        label: 'Pedidos X Mes',
        data: [],
        fill: false,
        backgroundColor: 'rgb(6, 61, 114)',
        borderColor: 'rgb(6, 61, 114)',
    },
];

const datasets_pedidosXcidade = [
    {
        label: 'Pedidos X Cidade',
        data: [],
        fill: false,
        backgroundColor: [],
        borderColor: [],
    },
];

const datasets_pagosXnao_pagos = [
    {
        label: 'Pagos X Não pagos',
        data: [],
        backgroundColor: ['rgb(40 167 69)', 'rgb(255 71 71)'],
        borderColor: ['rgb(40 167 69)', 'rgb(255 71 71)'],
    },
];

const datasets_entreguesXnao_entregues = [
    {
        label: 'Entregues X Não entregues',
        data: [],
        backgroundColor: ['rgb(40 167 69)', 'rgb(255 71 71)'],
        borderColor: ['rgb(40 167 69)', 'rgb(255 71 71)'],
    },
];

const datasets_valor_a_receberXvalor_recebido = [
    {
        label: 'Recebidos X À receber',
        data: [],
        backgroundColor: ['rgb(40 167 69)', 'rgb(255 71 71)'],
        borderColor: ['rgb(40 167 69)', 'rgb(255 71 71)'],
    },
];

class GraficosVisualizar extends Component {
    constructor(props){
        super(props);

        this.state = {
            tooltipTotalPedidosOpen: false,
            tooltipTotalPedidosEntreguesOpen: false,
            tooltipTotalPedidosNaoEntreguesOpen: false,
            tooltipTotalPedidosPagosOpen: false,
            tooltipTotalPedidosValorPagosOpen: false,
            tooltipTotalPedidosNaoPagosOpen: false,
            tooltipTotalPedidosValorNaoPagosOpen: false,
            graficos: {
                pedidosXmes : {
                    labels: [],
                    datasets: datasets_pedidosXmes
                },
                pedidosXcidade : {
                    labels: [],
                    datasets: datasets_pedidosXcidade
                },
                pedidos_pagosXnaopagos : {
                    labels: [],
                    datasets: datasets_pagosXnao_pagos
                },
                pedidos_entreguesXnaoentregues : {
                    labels: [],
                    datasets: datasets_entreguesXnao_entregues
                },
                pedidos_valor_a_receberXvalor_recebido : {
                    labels: [],
                    datasets: datasets_valor_a_receberXvalor_recebido
                }
            },
            indicadores: { 
                quantidade: { 
                    pedidos_total: '',
                    pedidos_entregues: '',
                    pedidos_nao_entregues: '',
                    pedidos_pagos: '',
                    pedidos_nao_pagos: '',
                    valor_recebido: '',
                    valor_a_receber: ''
                },
            },
        };
        
        this.loadingShow = this.loadingShow.bind(this);
        this.desdobrar = this.desdobrar.bind(this);
        this.setDados     = this.setDados.bind(this);
    }

    async componentDidMount(){
        this.loadingShow(true);

        if(!this.props.dadosRelatorioGraficos.carregado){
            this.props.history.push('./filtro');
        } else {
            let graficos = [];
            let indicadores = [];

            graficos = this.props.dadosRelatorioGraficos.dados.graficos
            indicadores = this.props.dadosRelatorioGraficos.dados.indicadores

            //Grafico pedidos x mes
            let arrMeses = [];
            let arrTotal = [];
            let arrColor = [];
            graficos.pedidosXmes.map(function (item) {
                let mesNome = getMonthName(item.mes);
                arrMeses.push(mesNome);
                arrTotal.push(item.total);
                arrColor.push(randomRGB());
                return {
                    mesNome,
                    mes: item.mes,
                    total: item.total 
                };
            });
            let datasetsPedidosXmes = datasets_pedidosXmes;
            datasetsPedidosXmes[0].data = arrTotal;
            datasetsPedidosXmes[0].backgroundColor = arrColor;
            datasetsPedidosXmes[0].borderColor = arrColor;
            graficos.pedidosXmes.labels = arrMeses;
            graficos.pedidosXmes.datasets = datasetsPedidosXmes;

            //Grafico pedidos x cidade
            let arrCidade = [];
            let arrTotalCidade = [];
            let arrColorCidade = [];
            graficos.pedidosXcidade.map(function (item) {
                arrCidade.push(item.cidade_nome);
                arrTotalCidade.push(item.total);
                arrColorCidade.push(randomRGB());
                return {
                    cidade_nome: item.cidade_nome,
                    total: item.total 
                };
            });
            let datasetsPedidosXcidade = datasets_pedidosXcidade;
            datasetsPedidosXcidade[0].data = arrTotalCidade;
            datasetsPedidosXcidade[0].backgroundColor = arrColorCidade;
            datasetsPedidosXcidade[0].borderColor = arrColorCidade;
            graficos.pedidosXcidade.labels = arrCidade;
            graficos.pedidosXcidade.datasets = datasetsPedidosXcidade;

            //Grafico pagos x não pagos
            let datasetsPagosXnao_pagos = datasets_pagosXnao_pagos;
            datasetsPagosXnao_pagos[0].data = graficos.pedidos_pagosXnaopagos.data;
            graficos.pedidos_pagosXnaopagos.datasets = datasetsPagosXnao_pagos;

            //Grafico entregues x não entregues
            let datasetsEntreguesXnao_entregues = datasets_entreguesXnao_entregues;
            datasetsEntreguesXnao_entregues[0].data = graficos.pedidos_entreguesXnaoentregues.data;
            graficos.pedidos_entreguesXnaoentregues.datasets = datasetsEntreguesXnao_entregues;

            //Grafico recebidos x à receber
            let datasetsValor_recebidoXvalor_a_receber = datasets_valor_a_receberXvalor_recebido;
            datasetsValor_recebidoXvalor_a_receber[0].data = graficos.pedidos_valor_a_receberXvalor_recebido.data;
            graficos.pedidos_valor_a_receberXvalor_recebido.datasets = datasetsValor_recebidoXvalor_a_receber;

            this.setState({graficos, indicadores});

            this.loadingShow(false);
        }
    }

    setDados(value) {
        let { setDadosRelatorioPedido } = this.props;
        setDadosRelatorioPedido(value);
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    async desdobrar(item){

        let filtros = JSON.parse(sessionStorage.getItem('filtro_graficos'));

        let data_inicio = filtros.data_inicio !== null ? new Date(filtros.data_inicio) : null;
        let data_termino = filtros.data_termino !== null ? new Date(filtros.data_termino) : null;

        let entregue = null;
        let pago = null;

        if( item === 'entregue' ){
            entregue = 1;
        } else if ( item === 'nao_entregue' ){
            entregue = 0;
        } else if ( item === 'pago' ){
            pago = 1;
        } else if ( item === 'nao_pago' ){
            pago = 0;
        }

        let data = {
            cliente_id: null,
            cidade_id: null,
            bairro_id: null,
            data_inicio: data_inicio !== null ? (data_inicio.toLocaleDateString()) + ' 00:00:00' : null,
            data_termino: data_termino !== null ? (data_termino.toLocaleDateString()) + ' 23:59:59' : null,
            entregue: entregue,
            pago: pago,
        }

        this.loadingShow(true);
        const dados = await postFilter(`/relatorio/pedidos`, this.props, data, './');

        this.setDados({ carregado: true, dados });
        this.props.history.push('../pedidos/visualizar');
        this.loadingShow(false);
    }

    render(){
        
        let { setResponseModal, enableResponseModal } = this.props;

        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">

                                <Row style={{ marginBottom: '20px' }} >
                                    <Col sm="12" md="12" lg="12">
                                        <Button onClick={() => this.props.history.goBack()} className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                                            <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                                            <span className="label-button btn-fs-def">Voltar</span>
                                        </Button>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Card className="bd-rd-8">
                                            <CardHeader className="bg-maskats text-center">
                                                <h4 className="headerTitle">Indicadores</h4>
                                            </CardHeader>
                                            <CardBody className="text-center bggreylight" style={{borderRadius: '0 0 8px 8px'}}>
                                                <div className="row">
                                                    
                                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center" id="tooltipTotalPedidos" onClick={() => { this.setState({tooltipTotalPedidosOpen: !this.state.tooltipTotalPedidosOpen}) }}>
                                                                <h4 className="headerTitle">
                                                                    Total de pedidos 
                                                                    <i className="fa fa-info-circle" style={{marginLeft: '5px'}} aria-hidden="true"/>
                                                                </h4>
                                                                <Tooltip
                                                                    isOpen={this.state.tooltipTotalPedidosOpen}
                                                                    target="tooltipTotalPedidos"
                                                                    toggle={() => { this.setState({tooltipTotalPedidosOpen: !this.state.tooltipTotalPedidosOpen}) }}
                                                                >
                                                                    Total de pedidos cadastrados dentro do período filtrado
                                                                </Tooltip>
                                                            </CardHeader>
                                                            <CardBody className="text-center pointer" onClick={ () => this.desdobrar('total') }>
                                                                <h3 className='txt-green'>{this.state.indicadores.quantidade.pedidos_total}
                                                                    <i className="fa fa-external-link" style={{marginLeft: '10px'}} aria-hidden="true"/>
                                                                </h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>

                                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center" id="tooltipTotalPedidosEntregues" onClick={() => { this.setState({tooltipTotalPedidosEntreguesOpen: !this.state.tooltipTotalPedidosEntreguesOpen}) }}>
                                                                <h4 className="headerTitle">
                                                                    Pedidos entregues
                                                                    <i className="fa fa-info-circle" style={{marginLeft: '5px'}} aria-hidden="true"/>
                                                                </h4>
                                                                <Tooltip
                                                                    isOpen={this.state.tooltipTotalPedidosEntreguesOpen}
                                                                    target="tooltipTotalPedidosEntregues"
                                                                    toggle={() => { this.setState({tooltipTotalPedidosEntreguesOpen: !this.state.tooltipTotalPedidosEntreguesOpen}) }}
                                                                >
                                                                    Total de pedidos marcados como entregues e com data de entrega dentro do período filtrado
                                                                </Tooltip>
                                                            </CardHeader>
                                                            <CardBody className="text-center pointer" onClick={ () => this.desdobrar('entregue') }>
                                                                <h3 className='txt-green'>{this.state.indicadores.quantidade.pedidos_entregues}
                                                                    <i className="fa fa-external-link" style={{marginLeft: '10px'}} aria-hidden="true"/>
                                                                </h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    
                                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center" id="tooltipTotalPedidosNaoEntregues" onClick={() => { this.setState({tooltipTotalPedidosNaoEntreguesOpen: !this.state.tooltipTotalPedidosNaoEntreguesOpen}) }}>
                                                                <h4 className="headerTitle">
                                                                    Pedidos não entregues
                                                                    <i className="fa fa-info-circle" style={{marginLeft: '5px'}} aria-hidden="true"/>
                                                                </h4>
                                                                <Tooltip
                                                                    isOpen={this.state.tooltipTotalPedidosNaoEntreguesOpen}
                                                                    target="tooltipTotalPedidosNaoEntregues"
                                                                    toggle={() => { this.setState({tooltipTotalPedidosNaoEntreguesOpen: !this.state.tooltipTotalPedidosNaoEntreguesOpen}) }}
                                                                >
                                                                    Total de pedidos marcados como não entregues e com data de entrega dentro do período filtrado
                                                                </Tooltip>
                                                            </CardHeader>
                                                            <CardBody className="text-center pointer" onClick={ () => this.desdobrar('nao_entregue') }>
                                                                <h3 className='txt-red'>{this.state.indicadores.quantidade.pedidos_nao_entregues}
                                                                    <i className="fa fa-external-link" style={{marginLeft: '10px'}} aria-hidden="true"/>
                                                                </h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    
                                                </div>

                                                <hr/>

                                                <div className="row">
                                                    
                                                    <div className="col-sm-12 col-md-3 col-lg-3 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center" id="tooltipTotalPedidosPagos" onClick={() => { this.setState({tooltipTotalPedidosPagosOpen: !this.state.tooltipTotalPedidosPagosOpen}) }}>
                                                                <h4 className="headerTitle">
                                                                    Pedidos pagos
                                                                    <i className="fa fa-info-circle" style={{marginLeft: '5px'}} aria-hidden="true"/>
                                                                </h4>
                                                                <Tooltip
                                                                    isOpen={this.state.tooltipTotalPedidosPagosOpen}
                                                                    target="tooltipTotalPedidosPagos"
                                                                    toggle={() => { this.setState({tooltipTotalPedidosPagosOpen: !this.state.tooltipTotalPedidosPagosOpen}) }}
                                                                >
                                                                    Total de pedidos marcados como pagos e com data de pagamento dentro do período filtrado
                                                                </Tooltip>
                                                            </CardHeader>
                                                            <CardBody className="text-center pointer" onClick={ () => this.desdobrar('pago') }>
                                                                <h3 className='txt-green'>{this.state.indicadores.quantidade.pedidos_pagos}
                                                                    <i className="fa fa-external-link" style={{marginLeft: '10px'}} aria-hidden="true"/>
                                                                </h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>

                                                    <div className="col-sm-12 col-md-3 col-lg-3 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center" id="tooltipTotalPedidosValorPagos" onClick={() => { this.setState({tooltipTotalPedidosValorPagosOpen: !this.state.tooltipTotalPedidosValorPagosOpen}) }}>
                                                                <h4 className="headerTitle">
                                                                    Valor recebido
                                                                    <i className="fa fa-info-circle" style={{marginLeft: '5px'}} aria-hidden="true"/>
                                                                </h4>
                                                                <Tooltip
                                                                    isOpen={this.state.tooltipTotalPedidosValorPagosOpen}
                                                                    target="tooltipTotalPedidosValorPagos"
                                                                    toggle={() => { this.setState({tooltipTotalPedidosValorPagosOpen: !this.state.tooltipTotalPedidosValorPagosOpen}) }}
                                                                >
                                                                    Valor relacionado aos pedidos pagos
                                                                </Tooltip>
                                                            </CardHeader>
                                                            <CardBody className="text-center">
                                                                <h3 className='txt-green'>{this.state.indicadores.quantidade.valor_recebido}</h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>

                                                    <div className="col-sm-12 col-md-3 col-lg-3 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center" id="tooltipTotalPedidosNaoPagos" onClick={() => { this.setState({tooltipTotalPedidosNaoPagosOpen: !this.state.tooltipTotalPedidosNaoPagosOpen}) }}>
                                                                <h4 className="headerTitle">
                                                                    Pedidos não pagos
                                                                    <i className="fa fa-info-circle" style={{marginLeft: '10px'}} aria-hidden="true"/>
                                                                </h4>
                                                                <Tooltip
                                                                    isOpen={this.state.tooltipTotalPedidosNaoPagosOpen}
                                                                    target="tooltipTotalPedidosNaoPagos"
                                                                    toggle={() => { this.setState({tooltipTotalPedidosNaoPagosOpen: !this.state.tooltipTotalPedidosNaoPagosOpen}) }}
                                                                >
                                                                    Total de pedidos marcados como não pagos e com data de pagamento dentro do período filtrado
                                                                </Tooltip>
                                                            </CardHeader>
                                                            <CardBody className="text-center pointer" onClick={ () => this.desdobrar('nao_pago') }>
                                                                <h3 className='txt-red'>{this.state.indicadores.quantidade.pedidos_nao_pagos}
                                                                    <i className="fa fa-external-link" style={{marginLeft: '5px'}} aria-hidden="true"/>
                                                                </h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    
                                                    <div className="col-sm-12 col-md-3 col-lg-3 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center" id="tooltipTotalPedidosValorNaoPagos" onClick={() => { this.setState({tooltipTotalPedidosValorNaoPagosOpen: !this.state.tooltipTotalPedidosValorNaoPagosOpen}) }}>
                                                                <h4 className="headerTitle">
                                                                    Valor à receber
                                                                    <i className="fa fa-info-circle" style={{marginLeft: '5px'}} aria-hidden="true"/>
                                                                </h4>
                                                                <Tooltip
                                                                    isOpen={this.state.tooltipTotalPedidosValorNaoPagosOpen}
                                                                    target="tooltipTotalPedidosValorNaoPagos"
                                                                    toggle={() => { this.setState({tooltipTotalPedidosValorNaoPagosOpen: !this.state.tooltipTotalPedidosValorNaoPagosOpen}) }}
                                                                >
                                                                    Valor relacionado aos pedidos não pagos
                                                                </Tooltip>
                                                            </CardHeader>
                                                            <CardBody className="text-center">
                                                                <h3 className='txt-red'>{this.state.indicadores.quantidade.valor_a_receber}</h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <hr/>

                                <Row>
                                    <Col>
                                        <Card className="bd-rd-8">
                                            <CardHeader className="bg-maskats text-center">
                                                <h4 className="headerTitle">Gráficos</h4>
                                            </CardHeader>
                                            <CardBody className="text-center bggreylight" style={{borderRadius: '0 0 8px 8px'}}>
                                                <Row>

                                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Entregues X Não entregues</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Pie data={this.state.graficos.pedidos_entreguesXnaoentregues} />
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    
                                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Pagos X Não pagos</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Pie data={this.state.graficos.pedidos_pagosXnaopagos} />
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    
                                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Recebido X À receber (R$)</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Pie data={this.state.graficos.pedidos_valor_a_receberXvalor_recebido} />
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                
                                                </Row>

                                                <hr/>

                                                <Row>
                                                    
                                                    <div className="col-sm-12 col-md-6 col-lg-6 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Pedidos X Mes</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Doughnut data={this.state.graficos.pedidosXmes} />
                                                            </CardBody>
                                                        </Card>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 col-lg-6 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Pedidos X Cidade</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Doughnut data={this.state.graficos.pedidosXcidade} />
                                                            </CardBody>
                                                        </Card>
                                                    </div>

                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                            </div>
                        </div>
                    </div>
                </div>
                
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        dadosRelatorioGraficos: state.dadosRelatorioGraficos,
        dadosRelatorioPedido: state.dadosRelatorioPedido,
        enableResponseModal: state.enableResponseModal,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setDadosRelatorioPedido: dados => dispatch(setDadosRelatorioPedido(dados)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GraficosVisualizar);