import error_icon from '../../assets/img/error_icon.png'
import success_icon from '../../assets/img/success_icon.png'

import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import { ButtonDestroy } from '../../Components/Destroy';

const Ativo = ({ value }) => {
    return (
        <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            <img style={{width: "28px"}} alt={value === 1 || value === '1' ? "Ativo" : "Inativo"} src={value === 1 || value === '1' ? success_icon : error_icon}/>
        </div>
    );
}

const BotaoEditar = ({ data, next_url }) => {
    return (
        <Link replace
            to={{
                pathname: next_url,
                data: data,
                operation: "alt"
            }}
        >
            <Button id={`editar_${data.id}`} type="button" className="btn btn-rounded btn-sm btn-warning btn-icon mr-2 bd-rd-8">
                <i className="fa fa-edit"></i>
            </Button>
        </Link>
    )
}

const Opcoes = ({setDestroyModal, enableDestroyModal, data, next_url}) => {
    return(
        <>
            <BotaoEditar data={data} next_url={next_url}/>
            <ButtonDestroy
                setDestroyModal={setDestroyModal}
                enableDestroyModal={enableDestroyModal}
                item={{ id: data.id, name: data.nome, uuid: data.uuid }}
            />
        </>
    );
}

const colunas = () => {
    return [
        {
            id: 1, 
            visible: false,
            field: 'id', 
            label: 'Id',
            width: 'auto'
        },
        {
            id: 2, 
            width: '1fr',
            field: 'nome', 
            label: 'Nome',
        },
        {
            id: 3, 
            width: '1fr',
            field: 'ativo', 
            label: 'Ativo',
            cellRenderer: Ativo,
        },
        {
            id: 4,
            label: 'Opções',
            width: 'minmax(200px, auto)',
            cellRenderer: ( { tableManager, data } ) => {
                return (<Opcoes style={{alignItems: "center"}}
                    setDestroyModal={tableManager.config.additionalProps.setDestroyModal}
                    enableDestroyModal={tableManager.config.additionalProps.enableDestroyModal}
                    next_url={tableManager.config.additionalProps.next_url}
                    data={data}
                />);
            },
        },
    ];
}

export default colunas;
