import { Component, Fragment } from 'react';
import { postFilter } from "../../servicos/api";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Button, Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';
import { Link } from "react-router-dom";
import DatePicker from 'react-date-picker';

//REDUX
import { setLoaderShow, setResponseModal, setDadosRelatorioGraficos } from '../../reducers/index';
import { connect } from 'react-redux';

const salvaFiltro = ( key, value ) => {
    sessionStorage.setItem(key, JSON.stringify(value));
}

const buscaFiltro = ( key ) => {
    return JSON.parse(sessionStorage.getItem(key));
}

const removeFiltro = ( key ) => {
    sessionStorage.removeItem(key);
}

const FormularioListagem = (props) => {

    let filtros = buscaFiltro('filtro_graficos');

    let validacao = Yup.object({});

    let dateStart = new Date();
    let dateEnd = new Date();

    dateStart.setDate(dateEnd.getDate() - 7);

    const formik = useFormik({
        initialValues: {
            data_inicio: filtros !== null && filtros.data_inicio !== null ? new Date(filtros.data_inicio) : dateStart,
            data_termino: filtros !== null && filtros.data_termino !== null ? new Date(filtros.data_termino) : dateEnd
        },
        enableReinitialize: false,
        validationSchema: validacao,
        onSubmit: values => {

            let data_inicio = values.data_inicio !== null ? (values.data_inicio.toLocaleDateString()) + ' 00:00:00' : null;
            let data_termino = values.data_termino !== null ? (values.data_termino.toLocaleDateString()) + ' 23:59:59' : null;

            let dataFiltro = {
                data_inicio: values.data_inicio !== null ? values.data_inicio : null,
                data_termino: values.data_termino !== null ? values.data_termino : null
            }
            salvaFiltro('filtro_graficos', dataFiltro);

            let data = {
                "data_inicio": data_inicio,
                "data_termino": data_termino
            }

            props.filtrar(data);
        },
    });
    return ( 
        <Form className="forms-sample col-12" onSubmit={formik.handleSubmit}>
            <Card>
                <CardHeader className="bg-white">
                    <Row>
                        <Col className='col_lbl_filtro' sm='12' md='6'>
                            <h4 className="headerTitle">Filtro de geral</h4>
                        </Col>
                        <Col className='col_btn_filtro' sm='12' md='6'>
                            <Button 
                                className="btn btn-sm btn-success btn-fw bd-rd-8" 
                                type="button" 
                                onClick={
                                    () => { 
                                        removeFiltro('filtro_graficos');

                                        formik.setFieldValue('data_inicio', dateStart);
                                        formik.setFieldValue('data_termino', dateEnd);
                                    }
                                }
                            >
                                <i className="fa fa-check btn-icon-append btn-fs-def"></i>
                                <span className="label-button btn-fs-def">Limpar filtro</span>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div>

                        <FormGroup>
                            <Label for="data_inicio">Data de início</Label>
                            <br/>
                            <DatePicker 
                                id='data_inicio'
                                name='data_inicio'
                                className={'form-control form-control-sm'}
                                onChange={(newDate) => formik.setFieldValue('data_inicio', newDate)} 
                                value={formik.values.data_inicio}
                                closeCalendar={true}
                                locale={'pt-BR'}
                            />
                            {formik.touched.data_inicio && formik.errors.data_inicio ? (
                                <div className="divError">{formik.errors.data_inicio}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="data_fim">Data de término</Label>
                            <br/>
                            <DatePicker 
                                id='data_termino'
                                name='data_termino'
                                className={'form-control form-control-sm'}
                                onChange={(newDate) => formik.setFieldValue('data_termino', newDate)} 
                                value={formik.values.data_termino}
                                closeCalendar={true}
                                locale={'pt-BR'}
                            />
                            {formik.touched.data_termino && formik.errors.data_termino ? (
                                <div className="divError">{formik.errors.data_termino}</div>
                            ) : null}
                        </FormGroup>

                    </div>
                </CardBody>
                <CardFooter className="text-align-end bg-white">
                    <Link replace to="/inicio">
                        <Button className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                            <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                            <span className="label-button btn-fs-def">Voltar</span>
                        </Button>
                    </Link>

                    <Button className="btn btn-sm btn-success btn-fw bd-rd-8" type="submit">
                        <i className="fa fa-check btn-icon-append btn-fs-def"></i>
                        <span className="label-button btn-fs-def">Filtrar</span>
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

class GraficosFiltro extends Component {
    constructor(props){
        super(props);

        this.state = { };
        
        this.loadingShow = this.loadingShow.bind(this);
        this.filtrar     = this.filtrar.bind(this);
        this.setDados    = this.setDados.bind(this);
    }

    componentDidMount(){
        this.loadingShow(false);
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    setDados(value) {
        let { setDadosRelatorioGraficos } = this.props;
        setDadosRelatorioGraficos(value);
    }

    async filtrar(data){
        this.loadingShow(true);
        const dados = await postFilter(`/relatorio/graficos`, this.props, data, '../');

        this.setDados({ carregado: true, dados });
        this.props.history.push('./visualizar');
        this.loadingShow(false);
    }

    render(){
        const { setResponseModal, enableResponseModal } = this.props;
        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <Card>
                                            <CardBody>
                                                <div>
                                                    <FormularioListagem
                                                        filtrar={this.filtrar}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal,
        dadosRelatorioGraficos: state.dadosRelatorioGraficos,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
    setDadosRelatorioGraficos: dados => dispatch(setDadosRelatorioGraficos(dados)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GraficosFiltro);