import { Component, Fragment } from 'react';
import { postData, putData, getData } from "../../servicos/api";
import { isAdmin } from "../../servicos/auth";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';
import Combobox from '../../Components/Combobox';
import { Link } from "react-router-dom";
import Switch from 'react-switch';

//REDUX
import { setLoaderShow, setResponseModal } from '../../reducers/index';
import { connect } from 'react-redux';

const Formulario = (props) => {
    let tipo;
    if (props.data && props.data.tipo_id !== undefined && props.data.tipo_id !== null && props.listaTipos.length > 0) {
        tipo = props.listaTipos.filter(function (elem, i, object) {
            return elem.value === props.data.tipo_id;
        });
        if(tipo[0] !== undefined){
            tipo = { value: tipo[0].value, label: tipo[0].label };
        }
    }

    let estabelecimento;
    if (props.data && props.data.estabelecimento_id !== undefined && props.data.estabelecimento_id !== null && props.listaEstabelecimentos.length > 0) {
        estabelecimento = props.listaEstabelecimentos.filter(function (elem, i, object) {
            return elem.value === props.data.estabelecimento_id;
        });
        if(estabelecimento[0] !== undefined){
            estabelecimento = { value: estabelecimento[0].value, label: estabelecimento[0].label };
        }
    }

    let validarAdmin = Yup.object({
        nome: Yup.string()
            .min(2, 'O campo nome deverá ter no mínimo 2 caracteres.')
            .max(90, 'O campo nome deverá ter no máximo 90 caracteres.')
            .required('O campo nome é obrigatório.')
            .trim(),
        password: props.operation !== 'alt' ? Yup.string()
            .required(() => props.operation !== 'alt' ? 'O campo senha é obrigatório.' : false)
            .matches(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
                "O campo senha deve conter no mínimo 6 caracteres e possuir ao menos um número, um caractere especial, uma letra maiúscula e uma letra minúscula."
            )
            .min(6, 'O campo senha deverá ter no mínimo 6 caracteres.')
            .max(15, 'O campo senha deverá ter no máximo 15 caracteres.')
            .trim() : Yup.string(),
        email: Yup.string()
            .min(5, 'O campo email deverá ter no mínimo 5 caracteres.')
            .max(120, 'O campo email deverá ter no máximo 120 caracteres.')
            .email('O campo email deverá receber um email válido.')
            .required('O campo email é obrigatório.')
            .trim(),
        tipo_id: Yup.object()
            .required('O campo tipo de usuário é obrigatório.'),
    });

    let validarEstabelecimento = Yup.object({
        nome: Yup.string()
            .min(2, 'O campo nome deverá ter no mínimo 2 caracteres.')
            .max(90, 'O campo nome deverá ter no máximo 90 caracteres.')
            .required('O campo nome é obrigatório.')
            .trim(),
        password: props.operation !== 'alt' ? Yup.string()
            .required(() => props.operation !== 'alt' ? 'O campo senha é obrigatório.' : false)
            .matches(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
                "O campo senha deve conter no mínimo 6 caracteres e possuir ao menos um número, um caractere especial, uma letra maiúscula e uma letra minúscula."
            )
            .min(6, 'O campo senha deverá ter no mínimo 6 caracteres.')
            .max(15, 'O campo senha deverá ter no máximo 15 caracteres.')
            .trim() : Yup.string(),
        email: Yup.string()
            .min(5, 'O campo email deverá ter no mínimo 5 caracteres.')
            .max(120, 'O campo email deverá ter no máximo 120 caracteres.')
            .email('O campo email deverá receber um email válido.')
            .required('O campo email é obrigatório.')
            .trim(),
    });

    const formik = useFormik({
        initialValues: {
            nome:               props.data !== undefined && props.data !== null ? props.data.nome : '',
            email:              props.data !== undefined && props.data !== null ? props.data.email : '',
            password:           props.data !== undefined && props.data !== null ? props.data.password : '',
            ativo:              props.data !== undefined && props.data !== null ? !!parseInt(props.data.ativo) : true,
            tipo_id:            tipo,
            estabelecimento_id: estabelecimento,
        },
        enableReinitialize: true,
        validationSchema: isAdmin() ? validarAdmin : validarEstabelecimento,
        onSubmit: values => {
            let data = {
                "nome" : values.nome,
                "email" : values.email,
                "tipo_id": values.tipo_id || (isAdmin()) ? values.tipo_id.value : null,
                "estabelecimento_id": values.estabelecimento_id || (isAdmin()) ? values.estabelecimento_id.value : null,
                "password": values.password,
                "ativo": values.ativo,
            }

            if (props.operation !== undefined && props.operation !== null && props.operation === 'alt') {
                props.updateUsuario(data, props.data.uuid);
            } else {
                props.insertUsuario(data);
            }
        },
    });
    return ( 
        <Form className="forms-sample col-12" onSubmit={formik.handleSubmit}>
            <Card>
                <CardHeader className="bg-maskats text-center">
                    <h4 className="headerTitle">Cadastro de usuários</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        
                        {
                            isAdmin() ? 
                                <>    
                                    <FormGroup>
                                        <Label for="tipo_id">* Tipo de usuário</Label>
                                        <Combobox
                                            invalid={formik.touched.tipo_id && formik.errors.tipo_id}
                                            valid={formik.touched.tipo_id && !formik.errors.tipo_id}
                                            id="tipo_id"
                                            name="tipo_id"
                                            placeholder={'Selecione um item'}
                                            defaultValue={formik.values.tipo_id}
                                            value={formik.values.tipo_id}
                                            options={props.listaTipos}
                                            isMulti={false}
                                            loadingMessage={"Carregando..."}
                                            noOptionsMessage={"Não existem dados cadastrados."}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            maxMenuHeight={190}
                                        />
                                        {formik.touched.tipo_id && formik.errors.tipo_id ? (
                                            <div className="divError">{formik.errors.tipo_id}</div>
                                        ) : null}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="estabelecimento_id">Estabelecimento</Label>
                                        <Combobox
                                            invalid={formik.touched.estabelecimento_id && formik.errors.estabelecimento_id}
                                            valid={formik.touched.estabelecimento_id && !formik.errors.estabelecimento_id}
                                            id="estabelecimento_id"
                                            name="estabelecimento_id"
                                            placeholder={'Selecione um item'}
                                            defaultValue={formik.values.estabelecimento_id}
                                            value={formik.values.estabelecimento_id}
                                            options={props.listaEstabelecimentos}
                                            isMulti={false}
                                            loadingMessage={"Carregando..."}
                                            noOptionsMessage={"Não existem dados cadastrados."}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            maxMenuHeight={190}
                                        />
                                        {formik.touched.estabelecimento_id && formik.errors.estabelecimento_id ? (
                                            <div className="divError">{formik.errors.estabelecimento_id}</div>
                                        ) : null}
                                    </FormGroup>
                                </>
                            : ""
                        }

                        <FormGroup>
                            <Label for="nome">* Nome</Label>
                            <Input 
                                className="form-control form-control-sm" 
                                id="nome" 
                                name="nome" 
                                type="text" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.nome}
                                minLength={2}
                                maxLength={90}
                            />
                            {formik.touched.nome && formik.errors.nome ? (
                                <div className="divError">{formik.errors.nome}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="email">* Email</Label>
                            <Input 
                                className="form-control form-control-sm" 
                                id="email" 
                                name="email" 
                                type="email" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                minLength={5}
                                maxLength={120}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="divError">{formik.errors.email}</div>
                            ) : null}
                        </FormGroup>

                        {
                            props.operation !== 'alt' ? 
                                <FormGroup>
                                    <Label for="password">* Senha</Label>
                                    <Input 
                                        className="form-control form-control-sm" 
                                        id="password" 
                                        name="password" 
                                        type="password" 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                        minLength={5}
                                        maxLength={90}
                                    />
                                    {formik.touched.nome && formik.errors.password ? (
                                        <div className="divError">{formik.errors.password}</div>
                                    ) : null}
                                </FormGroup>
                                : null
                        }
                        
                        <FormGroup className="mb-0">
                            <Label for="ativo">Ativo</Label>
                            <br />
                            <Switch
                                id="ativo"
                                name="ativo"
                                onColor="#28a745"
                                offColor="#dc3545"
                                checked={formik.values.ativo}
                                onChange={()=>formik.setFieldValue('ativo', !formik.values.ativo)}
                            />
                        </FormGroup>
                    </div>
                </CardBody>
                <CardFooter className="text-align-end bg-white">
                    <Link replace to="/usuario">
                        <Button className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                            <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                            <span className="label-button btn-fs-def">Cancelar</span>
                        </Button>
                    </Link>

                    <Button className="btn btn-sm btn-success btn-fw bd-rd-8" type="submit">
                        <i className="fa fa-check btn-icon-append btn-fs-def"></i>
                        <span className="label-button btn-fs-def">Salvar</span>
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

class UsuarioFormulario extends Component {
    constructor(props){
        super(props);

        this.state = {
            listaTiposUsuario: [],
            listaEstabelecimentos: [],
        };
        
        this.loadingShow   = this.loadingShow.bind(this);
        this.updateUsuario = this.updateUsuario.bind(this);
        this.insertUsuario = this.insertUsuario.bind(this);
    }

    async componentDidMount(){
        this.loadingShow(true);

        let [listaEstabelecimentos, listaTiposUsuario] = await Promise.all([
            getData(`/estabelecimento`, this.props),
            getData(`/tipo-usuario`, this.props)
        ]);

        if(listaEstabelecimentos !== null){
            listaEstabelecimentos = listaEstabelecimentos.map(function (value) {
                return { value: value.id, label: value.nome, ativo: value.ativo };
            });
        }

        if(listaTiposUsuario !== null){
            listaTiposUsuario = listaTiposUsuario.map(function (value) {
                return { value: value.id, label: value.nome, ativo: value.ativo };
            });
        }

        this.setState({listaEstabelecimentos, listaTiposUsuario});

        this.loadingShow(false);
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    async updateUsuario(data, uuid){
        this.loadingShow(true);
        await putData(`/usuario/${uuid}`, this.props, data, '../usuario');
        this.loadingShow(false);
    }

    async insertUsuario(data){
        this.loadingShow(true);
        await postData(`/usuario`, this.props, data, '../usuario');
        this.loadingShow(false);
    }

    render(){
        const { data, operation } = this.props.location;
        const { setResponseModal, enableResponseModal } = this.props;
        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <Formulario
                                            data={data}
                                            operation={operation}
                                            updateUsuario={this.updateUsuario}
                                            insertUsuario={this.insertUsuario}
                                            listaTipos={this.state.listaTiposUsuario}
                                            listaEstabelecimentos={this.state.listaEstabelecimentos}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioFormulario);