import { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const modalDefault = { show: false, item: {} }

class ModalDestroy extends Component {
    constructor(props){
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    handleClick(){
        const { setDestroyModal } = this.props;
        const { item } = this.props.enableDestroyModal;
        this.props.destroy(item.uuid);
        setDestroyModal(modalDefault);
    }

    toggleModal(){
        let { setDestroyModal } = this.props;
        setDestroyModal(modalDefault);
    }

    render(){
        const { show } = this.props.enableDestroyModal;

        return (
            <Fragment>
                <Modal isOpen={show} toggle={this.toggleModal} className={this.props.className}>
                    <ModalHeader toggle={this.toggleModal}>
                        {"Remover"}
                    </ModalHeader>
                    <ModalBody>
                        {"Deseja remover o registro selecionado?"}
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-sm" color="secondary" onClick={this.toggleModal}>Não</Button>{' '}
                        <Button className="btn-sm" color="primary" onClick={this.handleClick}>Sim</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

export class ButtonDestroy extends Component {
    constructor(props){
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        let { setDestroyModal, item } = this.props;
        setDestroyModal({show: true, item: item });
    }

    render(){
        return (
            <button id={`destroy_${this.props.id}`} type="button" className="btn btn-rounded btn-sm btn-danger btn-icon bd-rd-8" onClick={this.handleClick}>
                <i className="fa fa-trash"></i>
            </button>
        );
    }
}

export default ModalDestroy;