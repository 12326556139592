import { connect } from 'react-redux';
import loaderImg from "../assets/img/loader.gif";

const Loader = (props) => {
    return props.enableLoaderShow ?
        <div id="loader-container">
            <div id="loader">
                <img src={loaderImg} alt="" width={'100px'}/>
            </div>
        </div>
        : null
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
    }
};

export default connect(mapStateToProps)(Loader);