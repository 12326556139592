import { Component, Fragment } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Link } from "react-router-dom";

import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import { getData } from "../../servicos/api";
import ModalResponse from '../../Components/ModalResponse';

import getMonthName from '../../utilidades/utilidades';

//REDUX
import { setLoaderShow, setResponseModal } from '../../reducers/index';
import { connect } from 'react-redux';

import { Bar } from 'react-chartjs-2';

const options = {};

const datasets = [
    {
        label: 'Pedidos X Mes',
        data: [],
        fill: false,
        backgroundColor: 'rgb(6, 61, 114)',
        borderColor: 'rgb(6, 61, 114)',
    },
];

class Home extends Component {

    constructor(props){
        super(props);

        this.state = { 
            indicadores: { 
                quantidade: { 
                    pedidos_entregues: '',
                    pedidos_pendentes: '',
                    produtos_ativos: '',
                    clientes_ativos: '',
                    total_vendas: ''
                },
                graficos: {
                    pedidosXmes : {
                        labels: [],
                        datasets: datasets
                    }
                }
            },
        };

        this.getDataIndicadores = this.getDataIndicadores.bind(this);
        this.loadingShow        = this.loadingShow.bind(this);
    }

    componentDidMount() {
        this.loadingShow(false);
        this.getDataIndicadores();
    }

    // LOADER
    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    async getDataIndicadores(){
        this.loadingShow(true);
        var indicadores = await getData(`/inicio`, this.props);
        if(indicadores !== null){
            
            let arrMeses = [];
            let arrTotal = [];
            indicadores.graficos.pedidosXmes.map(function (item) {
                let mesNome = getMonthName(item.mes);
                arrMeses.push(mesNome);
                arrTotal.push(item.total);
                return {
                    mesNome,
                    mes: item.mes,
                    total: item.total 
                };
            });
            let datasetsPedidosXmes = datasets;
            datasetsPedidosXmes[0].data = arrTotal;
            indicadores.graficos.pedidosXmes.labels = arrMeses;
            indicadores.graficos.pedidosXmes.datasets = datasetsPedidosXmes;
            
            this.setState({indicadores});
        }
        this.loadingShow(false);
    }
    
    render() {
        let { setResponseModal, enableResponseModal } = this.props;
        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">

                                <div className="row grid-margin">
                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                        <Card className="bd-rd-8">
                                            <Link className='decoration-none' replace to="/pedido/formulario">
                                                <CardHeader className="bg-maskats text-center">
                                                    <h4 className="headerTitle">Cadastrar pedido</h4>
                                                </CardHeader>
                                            </Link>
                                        </Card>
                                    </div>

                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                        <Card className="bd-rd-8">
                                            <Link className='decoration-none' replace to="/cliente/formulario">
                                                <CardHeader className="bg-maskats text-center">
                                                    <h4 className="headerTitle">Cadastrar cliente</h4>
                                                </CardHeader>
                                            </Link>
                                        </Card>
                                    </div>

                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                        <Card className="bd-rd-8">
                                            <Link className='decoration-none' replace to="/produto/formulario">
                                                <CardHeader className="bg-maskats text-center">
                                                    <h4 className="headerTitle">Cadastrar produto</h4>
                                                </CardHeader>
                                            </Link>
                                        </Card>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12 grid-margin stretch-card ">
                                        <Card className="bd-rd-8">
                                            <CardHeader className="bg-maskats text-center">
                                                <h4 className="headerTitle">Números da semana</h4>
                                            </CardHeader>
                                            <CardBody className="text-center bggreylight" style={{borderRadius: '0 0 8px 8px'}}>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Pedidos entregues</h4>
                                                            </CardHeader>
                                                            <CardBody className="text-center">
                                                                <h3>{this.state.indicadores.quantidade.pedidos_entregues}</h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Pedidos pendentes</h4>
                                                            </CardHeader>
                                                            <CardBody className="text-center">
                                                                <h3>{this.state.indicadores.quantidade.pedidos_pendentes}</h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 col-lg-4 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Valor recebido</h4>
                                                            </CardHeader>
                                                            <CardBody className="text-center">
                                                                <h3>{this.state.indicadores.quantidade.total_vendas}</h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12 grid-margin stretch-card">
                                        <Card className="bd-rd-8">
                                            <CardHeader className="bg-maskats text-center">
                                                <h4 className="headerTitle">Pedidos X Mes</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <Bar data={this.state.indicadores.graficos.pedidosXmes} options={options} />
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);