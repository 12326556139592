import { Component, Fragment } from 'react';
import { postData, putData, getData } from "../../servicos/api";
import { isAdmin } from "../../servicos/auth";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';
import Combobox from '../../Components/Combobox';
import { Link } from "react-router-dom";
import Switch from 'react-switch';

//REDUX
import { setLoaderShow, setResponseModal } from '../../reducers/index';
import { connect } from 'react-redux';

const Formulario = (props) => {
    let estabelecimento;
    if (props.data && props.data.estabelecimento_id !== undefined && props.data.estabelecimento_id !== null && props.listaEstabelecimentos.length > 0) {
        estabelecimento = props.listaEstabelecimentos.filter(function (elem, i, object) {
            return elem.value === props.data.estabelecimento_id;
        });
        if(estabelecimento[0] !== undefined){
            estabelecimento = { value: estabelecimento[0].value, label: estabelecimento[0].label };
        }
    }

    let categoria_produto;
    if (props.data && props.data.categoria_produto_id !== undefined && props.data.categoria_produto_id !== null && props.listaCategoriaProdutos.length > 0) {
        categoria_produto = props.listaCategoriaProdutos.filter(function (elem, i, object) {
            return elem.value === props.data.categoria_produto_id;
        });
        if(categoria_produto[0] !== undefined){
            categoria_produto = { value: categoria_produto[0].value, label: categoria_produto[0].label };
        }
    }
    
    let validarAdmin = Yup.object({
        nome: Yup.string()
            .min(2, 'O campo nome deverá ter no mínimo 2 caracteres.')
            .max(90, 'O campo nome deverá ter no máximo 90 caracteres.')
            .required('O campo nome é obrigatório.')
            .trim(),
        prioridade: Yup.number()
            .integer('O campo prioridade deverá receber um número inteiro.')
            .nullable(),
        estabelecimento_id: Yup.object()
            .required('O campo estabelecimento é obrigatório.'),
        categoria_produto_id: Yup.object()
            .required('O campo categoria do produto é obrigatório.'),
    });

    let validarEstabelecimento = Yup.object({
        nome: Yup.string()
            .min(2, 'O campo nome deverá ter no mínimo 2 caracteres.')
            .max(90, 'O campo nome deverá ter no máximo 90 caracteres.')
            .required('O campo nome é obrigatório.')
            .trim(),
        prioridade: Yup.number()
            .integer('O campo prioridade deverá receber um número inteiro.')
            .nullable(),
        categoria_produto_id: Yup.object()
            .required('O campo categoria do produto é obrigatório.'),
    });

    const formik = useFormik({
        initialValues: {
            nome:                 props.data !== undefined && props.data !== null ? props.data.nome : '',
            prioridade:           props.data !== undefined && props.data !== null ? props.data.prioridade :   '',
            ativo:                props.data !== undefined && props.data !== null ? !!parseInt(props.data.ativo) : true,
            estabelecimento_id:   estabelecimento,
            categoria_produto_id: categoria_produto,
        },
        enableReinitialize: true,
        validationSchema: isAdmin() ? validarAdmin : validarEstabelecimento,
        onSubmit: values => {
            let data = {
                "nome" : values.nome,
                "prioridade" : values.prioridade,
                "estabelecimento_id": values.estabelecimento_id || (isAdmin()) ? values.estabelecimento_id.value : null,
                "categoria_produto_id": values.categoria_produto_id || (isAdmin()) ? values.categoria_produto_id.value : null,
                "ativo": values.ativo,
            }

            if (props.operation !== undefined && props.operation !== null && props.operation === 'alt') {
                props.updateTipoProduto(data, props.data.uuid);
            } else {
                props.insertTipoProduto(data);
            }
        },
    });
    return ( 
        <Form className="forms-sample col-12" onSubmit={formik.handleSubmit}>
            <Card>
                <CardHeader className="bg-maskats text-center">
                    <h4 className="headerTitle">Cadastro de tipos de produto</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        
                        {
                            isAdmin() ? 
                                <FormGroup>
                                    <Label for="estabelecimento_id">* Estabelecimento</Label>
                                    <Combobox
                                        invalid={formik.touched.estabelecimento_id && formik.errors.estabelecimento_id}
                                        valid={formik.touched.estabelecimento_id && !formik.errors.estabelecimento_id}
                                        id="estabelecimento_id"
                                        name="estabelecimento_id"
                                        placeholder={'Selecione um item'}
                                        defaultValue={formik.values.estabelecimento_id}
                                        value={formik.values.estabelecimento_id}
                                        options={props.listaEstabelecimentos}
                                        isMulti={false}
                                        loadingMessage={"Carregando..."}
                                        noOptionsMessage={"Não existem dados cadastrados."}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        maxMenuHeight={190}
                                    />
                                    {formik.touched.estabelecimento_id && formik.errors.estabelecimento_id ? (
                                        <div className="divError">{formik.errors.estabelecimento_id}</div>
                                    ) : null}
                                </FormGroup>
                            : ""
                        }

                        <FormGroup>
                            <Label for="categoria_produto_id">* Categoria do produto</Label>
                            <Combobox
                                invalid={formik.touched.categoria_produto_id && formik.errors.categoria_produto_id}
                                valid={formik.touched.categoria_produto_id && !formik.errors.categoria_produto_id}
                                id="categoria_produto_id"
                                name="categoria_produto_id"
                                placeholder={'Selecione um item'}
                                defaultValue={formik.values.categoria_produto_id}
                                value={formik.values.categoria_produto_id}
                                options={props.listaCategoriaProdutos}
                                isMulti={false}
                                loadingMessage={"Carregando..."}
                                noOptionsMessage={"Não existem dados cadastrados."}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                maxMenuHeight={190}
                            />
                            {formik.touched.categoria_produto_id && formik.errors.categoria_produto_id ? (
                                <div className="divError">{formik.errors.categoria_produto_id}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="nome">* Nome</Label>
                            <Input 
                                className="form-control form-control-sm" 
                                id="nome" 
                                name="nome" 
                                type="text" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.nome}
                                minLength={2}
                                maxLength={90}
                            />
                            {formik.touched.nome && formik.errors.nome ? (
                                <div className="divError">{formik.errors.nome}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="prioridade">Prioridade</Label>
                            <Input 
                            className="form-control form-control-sm" 
                            id="prioridade" 
                            name="prioridade" 
                            type="number" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.prioridade}
                        />
                            {formik.touched.prioridade && formik.errors.prioridade ? (
                                <div className="divError">{formik.errors.prioridade}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup className="mb-0">
                            <Label for="ativo">Ativo</Label>
                            <br />
                            <Switch
                                id="ativo"
                                name="ativo"
                                onColor="#28a745"
                                offColor="#dc3545"
                                checked={formik.values.ativo}
                                onChange={()=>formik.setFieldValue('ativo', !formik.values.ativo)}
                            />
                        </FormGroup>
                    </div>
                </CardBody>
                <CardFooter className="text-align-end bg-white">
                    <Link replace to="/tipo-produto">
                        <Button className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                            <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                            <span className="label-button btn-fs-def">Cancelar</span>
                        </Button>
                    </Link>

                    <Button className="btn btn-sm btn-success btn-fw bd-rd-8" type="submit">
                        <i className="fa fa-check btn-icon-append btn-fs-def"></i>
                        <span className="label-button btn-fs-def">Salvar</span>
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

class TipoProdutoFormulario extends Component {
    constructor(props){
        super(props);

        this.state = {
            listaEstabelecimentos: [],
            listaCategoriaProdutos: [],
        };
        
        this.loadingShow       = this.loadingShow.bind(this);
        this.updateTipoProduto = this.updateTipoProduto.bind(this);
        this.insertTipoProduto = this.insertTipoProduto.bind(this);
    }

    async componentDidMount(){
        this.loadingShow(true);

        let [listaEstabelecimentos, listaCategoriaProdutos] = await Promise.all([
            getData(`/estabelecimento`, this.props),
            getData(`/categoria-produto`, this.props)
        ]);

        if(listaEstabelecimentos !== null){
            listaEstabelecimentos = listaEstabelecimentos.map(function (value) {
                return { value: value.id, label: value.nome, ativo: value.ativo };
            });
        }

        if(listaCategoriaProdutos !== null){
            listaCategoriaProdutos = listaCategoriaProdutos.map(function (value) {
                return { value: value.id, label: value.nome, ativo: value.ativo };
            });
        }

        this.setState({listaEstabelecimentos});
        this.setState({listaCategoriaProdutos});

        this.loadingShow(false);
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    async updateTipoProduto(data, uuid){
        this.loadingShow(true);
        await putData(`/tipo-produto/${uuid}`, this.props, data, '../tipo-produto');
        this.loadingShow(false);
    }

    async insertTipoProduto(data){
        this.loadingShow(true);
        await postData(`/tipo-produto`, this.props, data, '../tipo-produto');
        this.loadingShow(false);
    }

    render(){
        const { data, operation } = this.props.location;
        const { setResponseModal, enableResponseModal } = this.props;
        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <Formulario
                                            data={data}
                                            operation={operation}
                                            updateTipoProduto={this.updateTipoProduto}
                                            insertTipoProduto={this.insertTipoProduto}
                                            listaEstabelecimentos={this.state.listaEstabelecimentos}
                                            listaCategoriaProdutos={this.state.listaCategoriaProdutos}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TipoProdutoFormulario);