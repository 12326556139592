import { Component, Fragment } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalDestroy from '../../Components/Destroy';
import ModalResponse from '../../Components/ModalResponse';
import { Table } from '../../Components/Table';
import { getData, deleteData } from "../../servicos/api";
import { setLoaderShow, setResponseModal, setDestroyModal } from '../../reducers/index';

import colunas from './colunasTabela';

class TipoUsuario extends Component {
    constructor(props){
        super(props);

        this.state = { 
            tipos_usuario: [],
        };

        this.getDataTipoUsuario  = this.getDataTipoUsuario.bind(this);
        this.destroyTipoUsuario  = this.destroyTipoUsuario.bind(this);
        this.loadingShow         = this.loadingShow.bind(this);
    }

    componentDidMount() {
        this.loadingShow(false);
        this.getDataTipoUsuario();
    }

    // LOADER
    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    async getDataTipoUsuario(){
        this.loadingShow(true);
        var tipos_usuario = await getData(`/tipo-usuario`, this.props);
        if(tipos_usuario !== null){
            this.setState({tipos_usuario});
        }
        this.loadingShow(false);
    }

    async destroyTipoUsuario(uuid) {
        this.loadingShow(true);
        await deleteData(`/tipo-usuario/${uuid}`, this.props);
        await this.getDataTipoUsuario();
        this.loadingShow(false);
    }

    render(){
        let { setResponseModal, enableResponseModal, setDestroyModal, enableDestroyModal } = this.props;
        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-lg-12 grid-margin stretch-card">
                                        <Card>
                                            <CardHeader className="bg-maskats text-center">
                                                <h4 className="headerTitle">Tipos de usuário</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <div>
                                                    <Table
                                                        columns={colunas}
                                                        rows={this.state.tipos_usuario}
                                                        setDestroyModal={setDestroyModal} 
                                                        enableDestroyModal={enableDestroyModal}
                                                        next_url='tipo-usuario/formulario'
                                                    />
                                                </div>
                                            </CardBody>
                                            <CardFooter className="text-align-end bg-white">
                                                <Link replace to="/tipo-usuario/formulario">
                                                    <Button className="btn btn-sm btn-success btn-fw bd-rd-8" type="button">
                                                        <i className="fa fa-plus btn-icon-append btn-fs-def"></i>
                                                        <span className="label-button btn-fs-def">Adicionar</span>
                                                    </Button>
                                                </Link>
                                            </CardFooter>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />

                <ModalDestroy 
                    destroy={this.destroyTipoUsuario}
                    setDestroyModal={setDestroyModal} 
                    enableDestroyModal={enableDestroyModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal,
        enableDestroyModal: state.enableDestroyModal,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
    setDestroyModal: enable => dispatch(setDestroyModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TipoUsuario);