import { Label, Button, Row } from 'reactstrap';
import { Component, Fragment } from 'react';
import logo_white from '../../assets/img/logo_white.png';
import { limpaSessao } from "../../servicos/auth";
//REDUX
import { setLoaderShow } from '../../reducers/index';
import { connect } from 'react-redux';

class Login extends Component {
    
    constructor(props){
        super(props);

        this.loadingShow = this.loadingShow.bind(this);
        this.limparCache = this.limparCache.bind(this);
        this.cancelarLimpeza = this.cancelarLimpeza.bind(this);
    }

    // LOADER
    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    limparCache() {
        this.loadingShow(true);

        navigator.serviceWorker.getRegistrations().then(function(registrations) {
            for(let registration of registrations) {
                registration.unregister();
            } 
        });

        localStorage.clear();
        sessionStorage.clear();
        caches.delete('maskats-cache');
        
        limpaSessao();

        this.loadingShow(false);
        window.location = window.location.href;
        window.location.href = '/';
    }

    cancelarLimpeza(){
        this.props.history.push('/');
    }

    render(){
        return (
            <Fragment>
                <div id="layoutAuthentication">
                    <div id="layoutAuthentication_content">
                        <main>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-5">
                                        <div className="card shadow-lg border-0 mt-5">
                                            <div className="card-header login-header bg-red" style={{padding: "0rem 1.25rem"}}>
                                                <h3 style={{display: "flex", justifyContent: "center"}} className="font-white fs-25 text-center font-weight-light my-4">
                                                    <img width="45px" alt="Icone no Maskats" style={{marginRight: "15px"}} src={logo_white}/>
                                                    <div style={{ textAlign: "center", alignSelf: "center" }} >Maskats</div>
                                                </h3>
                                            </div>
                                            <div className="card-body">
                                                <Row style={{display: 'flex', justifyContent: "center"}}>
                                                    <Label className="small mb-1" for="email">Deseja realizar a atualização do sistema?</Label>
                                                </Row>
                                            </div>
                                            <div className='card-footer'>
                                                <Row style={{display: 'flex', justifyContent: "center"}}>
                                                    <Button onClick={this.limparCache} style={{width: "60px"}} className="bg-red no-border btn-sm font-white btn-fw bd-rd-8" aria-label='Botão limpa cache sim' type="button">
                                                        SIM
                                                    </Button>
                                                    <Button onClick={this.cancelarLimpeza} style={{marginLeft: "15px", width:"60px"}} className="bg-red no-border btn-sm font-white btn-fw bd-rd-8" aria-label='Botão limpa cache não' type="button">
                                                        NÂO
                                                    </Button>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);