import React from 'react';

import Select from 'react-select';

function Combobox(props) {

    const customStyles = {
        control: base => ({
            ...base,
            fontSize: '0.875rem'
        })
    };
    
    function handleChange(value) {
        if (value != null) {
            props.onChange(props.id, value);
        } else {
            props.onChange(props.id, '');
        }
    };

    function handleBlur() {
        props.onBlur(props.id, true);
    };

    return (
        <>
            <Select
                className={props.className}
                placeholder=""
                maxMenuHeight={props.maxMenuHeight !== undefined ? props.maxMenuHeight : undefined}
                menuPlacement="bottom"
                id={props.id}
                defaultValue={props.defaultValue}
                value={props.value}
                options={props.options}
                onChange={handleChange}
                onBlur={handleBlur}
                isDisabled={false}
                isClearable={true}
                isSearchable={true}
                isMulti={props.isMulti}
                isLoading={false}
                loadingMessage={() => props.loadingMessage}
                noOptionsMessage={() => props.noOptionsMessage}
                styles={customStyles}
            />
        </>
    );

}

export default Combobox;