import React from 'react';

import { Input, Button, InputGroup, Col, Label } from 'reactstrap';
import empty from '../assets/img/empty.png'
import ImageFile from '../Components/ImageFile';

function Combobox(props) {

    const setImageValue = srcData => props.formik.setFieldValue('arquivo', srcData);

    let values = props.formik.values;

    return (
        <>
            <Col md='2'>
                {values.arquivo !== null && values.arquivo !== undefined && values.arquivo !== ''
                    ?
                    <ImageFile imagem={values.arquivo}/>
                    :
                    <ImageFile imagem={empty}/>
                }
            </Col>
            <Col md='10'>
                <Label for="arquivo">{props.title}</Label>
                <InputGroup>
                    <Input
                        id="arquivo_label"
                        placeholder={values.arquivo_label}
                        type="text"
                        disabled={true}
                    />
                    <Input
                        accept=".jpg, .jpeg, .png"
                        type="file"
                        id="arquivo"
                        name="arquivo"
                        className="upload-imagem-input"
                        label={values.arquivo !== '' && values.arquivo !== undefined && values.arquivo !== null ? values.arquivo : "Selecione um arquivo"}
                        invalid={props.formik.touched.arquivo && props.formik.errors.arquivo}
                        valid={props.formik.touched.arquivo && !props.formik.errors.arquivo}
                        onChange={(event) => {
                            var fileToLoad = event.currentTarget.files[0];
                            if (fileToLoad) {
                                const fileReader = new FileReader();
                                fileReader.onload = fileLoadedEvent => setImageValue(fileLoadedEvent.target.result);
                                fileReader.readAsDataURL(fileToLoad);
                                props.formik.setFieldValue("arquivo_label", "Arquivo selecionado");
                            }
                        }}
                    />
                    {
                        values.arquivo !== '' && values.arquivo !== undefined && values.arquivo !== null ?
                            <Button
                                className="btn-danger rounded-0"
                                type="button"
                                onClick={() => {
                                    document.querySelector("#arquivo").value = "";
                                    props.formik.setFieldValue("arquivo", "");
                                    props.formik.setFieldValue("arquivo_label", "");
                                }}
                            >
                                <i className="ti-trash btn-icon-append btn-fs-def" />
                            </Button>
                        : ''
                    }
                    <Button
                        id="imagem-button"
                        className="btn-info upload-imagem-button-borda"
                        type="button"
                        onClick={() => document.querySelector('#arquivo').click()}
                    >
                        <i className="ti-clip btn-icon-append btn-fs-def" />
                        <span className="label-button btn-fs-def">Selecionar arquivo</span>
                    </Button>
                </InputGroup>
                {props.formik.touched.arquivo && props.formik.errors.arquivo ? (
                    <div className="divError">{props.formik.errors.arquivo}</div>
                ) : null}
            </Col>
        </>
    );

}

export default Combobox;