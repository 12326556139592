import { Component, Fragment } from 'react';
import { getData, putData } from "../../servicos/api";
import { Card, CardBody, Row, Col, CardHeader, CardFooter, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';
import { Link } from "react-router-dom";

//REDUX
import { setLoaderShow, setResponseModal } from '../../reducers/index';
import { connect } from 'react-redux';

import { queryClient } from '../../utilidades/queryClient';

class ClientePedidos extends Component {
    constructor(props){
        super(props);

        this.state = {
            pedidos: [],
            valor_total_pendente: 0,
            showModal: false
        };
        
        this.loadingShow = this.loadingShow.bind(this);
        this.setShowModal = this.setShowModal.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.updatePago = this.updatePago.bind(this);
    }

    async componentDidMount(){

        this.loadingShow(true);

        if(!this.props.location.data){
            this.props.history.push('/cliente');
        } else {
            let pedidos = [];

            pedidos = await getData(`/cliente/pedidos/${this.props.location.data.uuid}`, this.props);

            this.setState({pedidos: pedidos.dados, valor_total_pendente: pedidos.valor_total_pendente});

            this.loadingShow(false);
        }
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    setShowModal() {
        this.setState({showModal: !this.state.showModal})
    }

    sendMessage(item_id, parcelado){
        let whatsapp = document.querySelector(`#whatsapp`).value;
        document.querySelector(`#pago_${item_id}`).style.display = 'none';
        if(parcelado !== 1){
            document.querySelector(`#total_receber_${item_id}`).style.display = 'none';
        }
        
        let message_data = document.querySelector(`#card_body_pedido_${item_id}`).innerText;

        document.querySelector(`#pago_${item_id}`).style.display = 'block';
        if(parcelado !== 1){
            document.querySelector(`#total_receber_${item_id}`).style.display = 'block';
        }

        message_data = message_data.replaceAll('\n\nEnviar pedido no WhatsApp', '');
        let message_final = `Olá, obrigado por comprar em nossa loja!\nAqui estão os dados do seu pedido.\n\nPedido #${item_id}\n\n${message_data}\n\n--Mensagem automática--`;

        var url = 'https://api.whatsapp.com/send?phone=+55' 
            + whatsapp 
            + '&text=' 
            + encodeURIComponent(message_final);

        if(whatsapp !== ""){
            window.open(url, '_blank');
        } else {
            this.setShowModal();
        }
    }

    async updateEntregue(pedido_uuid, entregue){

        queryClient.removeQueries('pedidos');

        let data = { entregue };
        this.loadingShow(true);
        await putData(`/pedido/entregue/${pedido_uuid}`, this.props, data, '');
        localStorage.removeItem('pedidos');
        
        let pedidos = [];
        pedidos = await getData(`/cliente/pedidos/${this.props.location.data.uuid}`, this.props);
        this.setState({pedidos: pedidos.dados, valor_total_pendente: pedidos.valor_total_pendente});


        this.loadingShow(false);
    }

    async updatePago(pedido_uuid, pago){

        queryClient.removeQueries('pedidos');
        
        let data = { pago };
        this.loadingShow(true);
        await putData(`/pedido/pago/${pedido_uuid}`, this.props, data, '');
        localStorage.removeItem('pedidos');
        
        let pedidos = [];
        pedidos = await getData(`/cliente/pedidos/${this.props.location.data.uuid}`, this.props);
        this.setState({pedidos: pedidos.dados, valor_total_pendente: pedidos.valor_total_pendente});


        this.loadingShow(false);
    }

    render(){
        const { setResponseModal, enableResponseModal } = this.props;
        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin">
                                        <Row>
                                            <Col sm="12" md="12" lg="12">
                                                <Link replace to="/cliente">
                                                    <Button className="btn btn-sm btn-secondary btn-fw bd-rd-8 mb-2" type="button">
                                                        <i className="fa fa-arrow-left btn-icon-append btn-fs-def text-white"></i>
                                                        <span className="label-button btn-fs-def text-white">Voltar</span>
                                                    </Button>
                                                </Link>
                                                <Card id={`card_total`}>
                                                    <CardBody>
                                                        <p style={{textAlign: 'center', fontSize: '20px', marginBottom: '0px'}} id={`card_total_valor`} className={ parseFloat(this.state.valor_total_pendente).toFixed(2) >= 0 ? "card-description-red" : "card-description-green" }><b>{parseFloat(this.state.valor_total_pendente).toFixed(2) >= 0 ? 'Total à receber' : 'Troco'}: </b> R${this.state.valor_total_pendente ? (this.state.valor_total_pendente.replaceAll('.', ',')).replaceAll('-', '') : 'Sem dados'} </p>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <hr/>

                                    {this.state.pedidos.length > 0 ?

                                        this.state.pedidos.map(item => {
                                            
                                            let valor_total = parseFloat((item.valor_total.replaceAll('.', '')).replaceAll(',', '.') );
                                            let valor_pago = parseFloat((item.valor_pago.replaceAll('.', '')).replaceAll(',', '.') );

                                            let total = (valor_total - valor_pago).toFixed(2);
                                            
                                            return (
                                                <Fragment key={item.id}>
                                                    <Row>
                                                        <Col sm="12" md="12" lg="12">
                                                            <Input hidden id={"whatsapp"} onChange={() => null } value={item.telefone_principal}/>
                                                            <Card id={`card_pedido_${item.id}`}>
                                                                <CardHeader className='bg-maskats text-center' style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                    <h4 style={{ marginBottom: '0px' }}>Pedido #{item.id}</h4>
                                                                </CardHeader>
                                                                <CardBody id={`card_body_pedido_${item.id}`}>
                                                                    <p className="card-description-black"><b>Cliente: </b>{`${item.cliente_nome ? item.cliente_nome : 'Sem dados'}`}</p>
                                                                    <p className="card-description-black"><b>Entregue: </b>{`${item.entregue === 1 ? 'Sim' : 'Não'}`}</p>
                                                                    <p className="card-description-black"><b>Data de entrega: </b>{`${item.data_entrega ? item.data_entrega : 'Sem dados'}`}</p>
                                                                    <p id={`pago_${item.id}`} className="card-description-black"><b>Pago: </b>{`${item.pago === 1 ? 'Sim' : 'Não'}`}</p>
                                                                    <p className="card-description-black" style={{whiteSpace: 'pre-line'}}><b>Observação: </b>{item.observacao ? <br/> : ''}{`${item.observacao ? `${item.observacao}` : `Sem dados`}`}</p>
                                                                    <p className="card-description-black"><b>Produtos: </b>{`${item.produtos && item.produtos.length > 0 ? '' : 'Sem dados'}`} </p>
                                                                    <ul className="list-arrow">
                                                                        {
                                                                            item.produtos && item.produtos.length > 0 ?
                                                                                item.produtos.map(produto => {
                                                                                    return <li key={produto.id} id={produto.id}><b>{ (produto.nome.toLowerCase()).indexOf('kg') === -1 ? `${produto.quantidade_produto}` : `${(produto.quantidade_produto).toFixed(3).replaceAll('.', ',')} Kg`} - R${produto.valor_momento_venda !== '' ? produto.valor_momento_venda : produto.valor}</b> {'->'} {produto.nome}</li>
                                                                                }) : ''
                                                                        }
                                                                    </ul>
                                                                    {
                                                                        item.parcelado === 1 ?
                                                                            <Fragment>
                                                                                <p className="card-description-black"><b>Parcelas: </b>{`${item.parcelas && item.parcelas.length > 0 ? '' : 'Sem dados'}`} </p>
                                                                                <ul className="list-arrow">
                                                                                    {
                                                                                        item.parcelas && item.parcelas.length > 0 ?
                                                                                            item.parcelas.map(parcela => {
                                                                                                return (
                                                                                                    <li key={parcela.id} id={parcela.id}>
                                                                                                        <b>{ `${parcela.numero}/${item.parcelas.length} - R$${parcela.valor_pago} / R$${parcela.valor}`}</b>
                                                                                                        {` -> ${parcela.data_pagamento} `}
                                                                                                        <b>{`${parcela.pago === 1 ? ' - (pago)' : ''}`}</b>
                                                                                                    </li>
                                                                                                )
                                                                                            }) : ''
                                                                                    }
                                                                                </ul>
                                                                            </Fragment>
                                                                        :
                                                                            <Fragment>
                                                                                <p className="card-description-black"><b>Total: </b> R${item.valor_total ? item.valor_total : 'Sem dados'} </p>
                                                                                <p className="card-description-black"><b>Total pago: </b> R${item.valor_pago ? item.valor_pago : 'Sem dados'} </p>
                                                                                <p id={`total_receber_${item.id}`} className={ total > 0 ? "card-description-red" : "card-description-green" }><b>{parseFloat(total).toFixed(2) >= 0 ? 'Total à receber' : 'Troco'}: </b> R${total ? (total.replaceAll('.', ',')).replaceAll('-', '') : 'Sem dados'} </p>
                                                                            </Fragment>
                                                                    }
                                                                </CardBody>
                                                                <CardFooter style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                    { 
                                                                        !item.entregue ?
                                                                            <Button className="btn-sm" color="secondary" onClick={() => this.updateEntregue(item.uuid, 1)}>Pedido entregue</Button>
                                                                        :    
                                                                            <Button className="btn-sm" color="secondary" onClick={() => this.updateEntregue(item.uuid, 0)}>Pedido não entregue</Button>
                                                                    }
                                                                    { 
                                                                        !item.parcelado ?
                                                                            !item.pago ?
                                                                                <Button className="btn-sm ml-2" color="warning" onClick={() => this.updatePago(item.uuid, 1)}>Pedido pago</Button>
                                                                            :
                                                                                <Button className="btn-sm ml-2" color="warning" onClick={() => this.updatePago(item.uuid, 0)}>Pedido não pago</Button>
                                                                        : ''
                                                                    }
                                                                    <Button className="btn-sm ml-2" color="success" onClick={() => this.sendMessage(item.id, item.parcelado)}>Enviar pedido no WhatsApp</Button>
                                                                </CardFooter>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                </Fragment>
                                            );
                                        })
                                    : "" }

                                    </div>
                                </div>
                                <Modal isOpen={this.state.showModal} toggle={this.setShowModal}>
                                    <ModalHeader toggle={this.setShowModal}>
                                        Atenção!
                                    </ModalHeader>
                                    <ModalBody>
                                        Para enviar o pedido ao cliente ele precisa possuir um WhatsApp cadastrado.
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className="btn-sm" color="secondary" onClick={this.setShowModal}>Fechar</Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientePedidos);