import logo_mini from '../assets/img/logo_white.png';
import hamburger_icon from '../assets/img/hamburger_icon_white.png';
import { Link } from "react-router-dom";
import { Component, Fragment } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { connect } from 'react-redux';
import { setMobileSidebar } from '../reducers/index';

const mq = window.matchMedia( "(max-width: 991px)" );

class Navbar extends Component {

    constructor(props){
        super(props);

        this.toggleSidebar = this.toggleSidebar.bind(this);
    }

    toggleSidebar(){
        let { setMobileSidebar, enableMobileSidebar } = this.props;
        setMobileSidebar(!enableMobileSidebar);
    }

    render(){
        return (
            <Fragment>
                <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">

                    {
                        mq.matches ?
                            <>
                                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center" style={{ backgroundColor: "#063d72" }}>
                                    <Link replace className="navbar-brand brand-logo-mini" aria-label='Acessar barra lateral' onClick={this.toggleSidebar} to={'#'}>
                                        <img src={hamburger_icon} alt="logo" width={40} height={34}/>
                                    </Link>
                                </div>
                                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center center-logo-nav" style={{ backgroundColor: "#063d72" }}>
                                    <Link replace className="navbar-brand brand-logo-mini" to="/inicio">
                                        <img src={logo_mini} alt="logo" width={40} height={34}/>
                                    </Link>
                                </div>
                            </>
                        :
                            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center center-logo-nav" style={{ backgroundColor: "#063d72" }}>
                                <Link replace className="navbar-brand brand-logo mr-5" style={{display: "flex"}} to="/inicio">
                                    <img src={logo_mini} className="mr-2" alt="logo" width={40} height={34}/>
                                    <span style={{ fontSize: "18px", color: "#fff", alignSelf: "center"}}>Maskats</span>
                                </Link>
                                <Link replace className="navbar-brand brand-logo-mini" to="/inicio">
                                    <img src={logo_mini} alt="logo" width={40} height={34}/>
                                </Link>
                            </div>
                    }
                    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end" style={{ backgroundColor: "#063d72" }}>
                        <UncontrolledDropdown size="sm" className="navbar-nav navbar-nav-right">
                            <DropdownToggle caret className="btn-light btn-dropdowntoggle"/>
                            <DropdownMenu>
                                <Link replace to="/alterar-senha">
                                    <DropdownItem>
                                        <i className="fa fa-edit btn-icon-append btn-fs-def"></i>
                                        <span className="label-button btn-fs-def">Alterar senha</span>
                                    </DropdownItem>
                                </Link>
                                <Link replace to="/login">
                                    <DropdownItem>
                                        <i className="fa fa-sign-out btn-icon-append btn-fs-def"></i>
                                        <span className="label-button btn-fs-def">Sair</span>
                                    </DropdownItem>
                                </Link>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </nav>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableMobileSidebar: state.enableMobileSidebar,
    }
};

const mapDispatchToProps = dispatch => ({
    setMobileSidebar: enable => dispatch(setMobileSidebar(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);