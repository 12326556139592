import React from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';
import { Table } from '../../Components/Table';
import { getData } from '../../servicos/api';
import { setLoaderShow, setResponseModal } from '../../reducers/index';

import colunas from './colunasTabela';
import { useQuery } from 'react-query';

import { queryClient } from '../../utilidades/queryClient';

function Pedido(props) {
    const { data: pedidos = [] } = useQuery('pedidos', async () => {
        loadingShow(true);
        let data = await getData(`/pedido`, props);
        if(data !== null){
            data.map(function (value) {
                value.valor_formatado = `R$ ${value.valor_total}`;
                value.id = `#${value.id}`;
                return value;
            });
        }
        loadingShow(false);
        return data;
    }, {
        staleTime: 24 * 60 * 60 * 1000,
    });

    queryClient.removeQueries('pedido');

    const loadingShow = (value) => {
        let { setLoaderShow } = props;
        setLoaderShow(value);
    }

    return (
        <>
            <div className="container-scroller">
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-lg-12 grid-margin stretch-card">
                                    <Card>
                                        <CardHeader className="bg-maskats text-center">
                                            <h4 className="headerTitle">Pedidos</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <div>
                                                <Table
                                                    columns={colunas}
                                                    rows={pedidos}
                                                    next_url_editar='pedido/formulario'
                                                    next_url_parcelar='/pedido/parcela'
                                                />
                                            </div>
                                        </CardBody>
                                        <CardFooter className="text-align-end bg-white">
                                            <Link replace to="/pedido/formulario">
                                                <Button name="botao-adicionar" className="btn btn-sm btn-success btn-fw bd-rd-8" type="button">
                                                    <i className="fa fa-plus btn-icon-append btn-fs-def"></i>
                                                    <span className="label-button btn-fs-def">Adicionar</span>
                                                </Button>
                                            </Link>
                                        </CardFooter>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalResponse
                history={props.history}
                setResponseModal={props.setResponseModal}
                enableResponseModal={props.enableResponseModal}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal,
    };
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pedido);