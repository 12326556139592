import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';

import './assets/css/styles.css';
import './assets/css/vertical-layout-light/style.css';
import './assets/vendors/feather/feather.css';
import './assets/vendors/ti-icons/css/themify-icons.css';
import './assets/vendors/font-awesome/css/font-awesome.min.css';

import App from './App';
import Loader from './Components/Loader';

import { QueryClientProvider, queryClient } from './utilidades/queryClient';

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <Loader />
            <App />
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
);
reportWebVitals();
