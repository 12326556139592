import { Component, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';
import { postFilter } from "../../servicos/api";

//REDUX
import { setLoaderShow, setDadosRelatorioPedido, setResponseModal } from '../../reducers/index';
import { connect } from 'react-redux';

class IndicadoresVisualizar extends Component {
    constructor(props){
        super(props);

        this.state = {
            indicadores: { 
                quantidade: { 
                    pedidos_entregues: '',
                    pedidos_nao_entregues: '',
                    pedidos_pagos: '',
                    pedidos_nao_pagos: '',
                    valor_recebido: '',
                    valor_a_receber: ''
                },
            },
        };
        
        this.loadingShow = this.loadingShow.bind(this);
        this.desdobrar = this.desdobrar.bind(this);
        this.setDados     = this.setDados.bind(this);
    }

    async componentDidMount(){
        this.loadingShow(false);
        this.props.history.push('../geral/filtro');

        if(!this.props.dadosRelatorioIndicadores.carregado){
            this.props.history.push('./filtro');
        } else {
            let indicadores = [];

            indicadores = this.props.dadosRelatorioIndicadores.dados

            this.setState({indicadores});

            this.loadingShow(false);
        }
    }

    setDados(value) {
        let { setDadosRelatorioPedido } = this.props;
        setDadosRelatorioPedido(value);
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    async desdobrar(item){

        let filtros = JSON.parse(sessionStorage.getItem('filtro_indicadores'));

        let data_inicio = filtros.data_inicio !== null ? new Date(filtros.data_inicio) : null;
        let data_termino = filtros.data_termino !== null ? new Date(filtros.data_termino) : null;

        let entregue = null;
        let pago = null;

        if( item === 'entregue' ){
            entregue = 1;
        } else if ( item === 'nao_entregue' ){
            entregue = 0;
        } else if ( item === 'pago' ){
            pago = 1;
        } else if ( item === 'nao_pago' ){
            pago = 0;
        }

        let data = {
            cliente_id: null,
            cidade_id: null,
            bairro_id: null,
            data_inicio: data_inicio !== null ? (data_inicio.toLocaleDateString()) + ' 00:00:00' : null,
            data_termino: data_termino !== null ? (data_termino.toLocaleDateString()) + ' 23:59:59' : null,
            entregue: entregue,
            pago: pago,
        }

        this.loadingShow(true);
        const dados = await postFilter(`/relatorio/pedidos`, this.props, data, './');

        this.setDados({ carregado: true, dados });
        this.props.history.push('../pedidos/visualizar');
        this.loadingShow(false);
    }

    render(){
        
        let { setResponseModal, enableResponseModal } = this.props;

        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">

                                <Row style={{ marginBottom: '20px' }} >
                                    <Col sm="12" md="12" lg="12">
                                        <Button onClick={() => this.props.history.goBack()} className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                                            <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                                            <span className="label-button btn-fs-def">Voltar</span>
                                        </Button>
                                    </Col>
                                </Row>

                                <div className="row">
                                    <div className="col-lg-12 grid-margin stretch-card ">

                                        <Card className="bd-rd-8">
                                            <CardHeader className="bg-maskats text-center">
                                                <h4 className="headerTitle">Indicadores</h4>
                                            </CardHeader>
                                            <CardBody className="text-center bggreylight" style={{borderRadius: '0 0 8px 8px'}}>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-3 col-lg-3 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8 pointer" onClick={ () => this.desdobrar('entregue') }>
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Pedidos entregues <i className="fa fa-external-link" style={{marginLeft: '5px'}} aria-hidden="true"/></h4>
                                                            </CardHeader>
                                                            <CardBody className="text-center">
                                                                <h3 className='txt-green'>{this.state.indicadores.quantidade.pedidos_entregues}</h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    <div className="col-sm-12 col-md-3 col-lg-3 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8 pointer" onClick={ () => this.desdobrar('nao_entregue') }>
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Pedidos não entregues <i className="fa fa-external-link" style={{marginLeft: '5px'}} aria-hidden="true"/></h4>
                                                            </CardHeader>
                                                            <CardBody className="text-center">
                                                                <h3 className='txt-red'>{this.state.indicadores.quantidade.pedidos_nao_entregues}</h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    <div className="col-sm-12 col-md-3 col-lg-3 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8 pointer" onClick={ () => this.desdobrar('pago') }>
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Pedidos pagos <i className="fa fa-external-link" style={{marginLeft: '5px'}} aria-hidden="true"/></h4>
                                                                
                                                            </CardHeader>
                                                            <CardBody className="text-center">
                                                                <h3 className='txt-green'>{this.state.indicadores.quantidade.pedidos_pagos}</h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    <div className="col-sm-12 col-md-3 col-lg-3 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8 pointer" onClick={ () => this.desdobrar('nao_pago') }>
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Pedidos não pagos <i className="fa fa-external-link" style={{marginLeft: '5px'}} aria-hidden="true"/></h4>
                                                            </CardHeader>
                                                            <CardBody className="text-center">
                                                                <h3 className='txt-red'>{this.state.indicadores.quantidade.pedidos_nao_pagos}</h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </div>

                                                <hr/>

                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6 col-lg-6 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Valor recebido</h4>
                                                            </CardHeader>
                                                            <CardBody className="text-center">
                                                                <h3 className='txt-green'>{this.state.indicadores.quantidade.valor_recebido}</h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-6 stretch-card card-mb-15">
                                                        <Card className="bd-rd-8">
                                                            <CardHeader className="bg-maskats text-center">
                                                                <h4 className="headerTitle">Valor à receber</h4>
                                                            </CardHeader>
                                                            <CardBody className="text-center">
                                                                <h3 className='txt-red'>{this.state.indicadores.quantidade.valor_a_receber}</h3>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        dadosRelatorioIndicadores: state.dadosRelatorioIndicadores,
        dadosRelatorioPedido: state.dadosRelatorioPedido,
        enableResponseModal: state.enableResponseModal,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setDadosRelatorioPedido: dados => dispatch(setDadosRelatorioPedido(dados)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndicadoresVisualizar);