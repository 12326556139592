import { Component, Fragment } from 'react';
import { postData } from "../../servicos/api";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';
import { Link } from "react-router-dom";

//REDUX
import { setLoaderShow, setResponseModal } from '../../reducers/index';
import { connect } from 'react-redux';

const Formulario = (props) => {
    const formik = useFormik({
        initialValues: {
            password_antiga: '',
            password_nova: '',
            password_nova_confirmacao: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            password_antiga: Yup.string()
                .required('O campo senha antiga é obrigatório.')
                .trim(),
            password_nova: Yup.string()
                .required('O campo senha nova é obrigatório.')
                .matches(
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
                    "O campo senha nova deve conter no mínimo 6 caracteres e possuir ao menos um número, um caractere especial, uma letra maiúscula e uma letra minúscula."
                )
                .min(6, 'O campo senha nova deverá ter no mínimo 6 caracteres.')
                .max(15, 'O campo senha nova deverá ter no máximo 15 caracteres.')
                .trim(),
            password_nova_confirmacao: Yup.string()
                .required('O campo confirmação da senha nova é obrigatório.')
                .min(6, 'O campo confirmação da senha nova deverá ter no mínimo 6 caracteres.')
                .max(15, 'O campo confirmação da senha nova deverá ter no máximo 15 caracteres.')
                .oneOf([Yup.ref('password_nova'), null], 'A senha nova e a confirmação devem ser iguais.')
                .trim(),
        }),
        onSubmit: values => {
            let data = { 
                "password_antiga" : values.password_antiga,
                "password_nova" : values.password_nova
            }

            props.updateSenha(data);
        },
    });
    return ( 
        <Form className="forms-sample col-12" onSubmit={formik.handleSubmit}>
            <Card>
                <CardHeader className="bg-maskats text-center">
                    <h4 className="headerTitle">Alteração de senha</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        <FormGroup>
                            <Label for="password_antiga">* Senha antiga</Label>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Input 
                                    className="form-control form-control-sm" 
                                    id="password_antiga" 
                                    name="password_antiga" 
                                    type={props.showPassword ? "text" : "password"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password_antiga}
                                    minLength={6}
                                    maxLength={15}
                                />
                                <i className={`fa ${props.showPassword ? "fa-eye-slash" : "fa-eye"}`} id="togglePassword" style={{marginLeft: "-30px", cursor: "pointer"}} onClick={props.showHidePassword}/>
                            </div>
                            {formik.touched.password_antiga && formik.errors.password_antiga ? (
                                <div className="divError">{formik.errors.password_antiga}</div>
                            ) : null}
                        </FormGroup>
                        
                        <FormGroup>
                            <Label for="password_nova">* Senha nova</Label>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Input 
                                    className="form-control form-control-sm" 
                                    id="password_nova" 
                                    name="password_nova" 
                                    type={props.showPasswordNew ? "text" : "password"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password_nova}
                                    minLength={6}
                                    maxLength={15}
                                />
                                <i className={`fa ${props.showPasswordNew ? "fa-eye-slash" : "fa-eye"}`} id="togglePassword" style={{marginLeft: "-30px", cursor: "pointer"}} onClick={props.showHidePasswordNew}/>
                            </div>
                            {formik.touched.password_nova && formik.errors.password_nova ? (
                                <div className="divError">{formik.errors.password_nova}</div>
                            ) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="password_nova_confirmacao">* Confirmação da senha nova</Label>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Input 
                                    className="form-control form-control-sm" 
                                    id="password_nova_confirmacao" 
                                    name="password_nova_confirmacao" 
                                    type={props.showPasswordConfirm ? "text" : "password"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password_nova_confirmacao}
                                    minLength={6}
                                    maxLength={15}
                                />
                                <i className={`fa ${props.showPasswordConfirm ? "fa-eye-slash" : "fa-eye"}`} id="togglePassword" style={{marginLeft: "-30px", cursor: "pointer"}} onClick={props.showHidePasswordConfirm}/>
                            </div>
                            {formik.touched.password_nova_confirmacao && formik.errors.password_nova_confirmacao ? (
                                <div className="divError">{formik.errors.password_nova_confirmacao}</div>
                            ) : null}
                        </FormGroup>
                    </div>
                </CardBody>
                <CardFooter className="text-align-end bg-white">
                    <Link replace to="/inicio">
                        <Button className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                            <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                            <span className="label-button btn-fs-def">Cancelar</span>
                        </Button>
                    </Link>

                    <Button className="btn btn-sm btn-success btn-fw bd-rd-8" type="submit">
                        <i className="fa fa-check btn-icon-append btn-fs-def"></i>
                        <span className="label-button btn-fs-def">Salvar</span>
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

class AlterarSenha extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            showPassword: false,
            showPasswordNew: false,
            showPasswordConfirm: false
        }

        this.loadingShow      = this.loadingShow.bind(this);
        this.updateSenha      = this.updateSenha.bind(this);
        this.showHidePassword = this.showHidePassword.bind(this);
        this.showHidePasswordNew = this.showHidePasswordNew.bind(this);
        this.showHidePasswordConfirm = this.showHidePasswordConfirm.bind(this);
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    async updateSenha(data){
        this.loadingShow(true);
        await postData(`/usuario/alterar-senha`, this.props, data, '../inicio');
        this.loadingShow(false);
    }

    showHidePassword(){
        this.setState({showPassword: !this.state.showPassword})
    }

    showHidePasswordNew(){
        this.setState({showPasswordNew: !this.state.showPasswordNew})
    }

    showHidePasswordConfirm(){
        this.setState({showPasswordConfirm: !this.state.showPasswordConfirm})
    }

    render(){
        const { setResponseModal, enableResponseModal } = this.props;
        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <Formulario 
                                            updateSenha={this.updateSenha} 
                                            showHidePassword={this.showHidePassword} 
                                            showHidePasswordNew={this.showHidePasswordNew} 
                                            showHidePasswordConfirm={this.showHidePasswordConfirm} 
                                            showPassword={this.state.showPassword}
                                            showPasswordNew={this.state.showPasswordNew}
                                            showPasswordConfirm={this.state.showPasswordConfirm}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlterarSenha);