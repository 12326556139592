import { Component, Fragment } from 'react';
import { postData, putData } from "../../../servicos/api";
import { FormGroup, Label, Input, Button, Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import Navbar from '../../../Components/Navbar';
import Sidebar from '../../../Components/Sidebar';
import ModalResponse from '../../../Components/ModalResponse';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import Switch from 'react-switch';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { Formik, Form } from 'formik';

//REDUX
import { setLoaderShow, setResponseModal } from '../../../reducers/index';
import { connect } from 'react-redux';

const numberMask = createNumberMask({
    prefix: 'R$ ',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    allowNegative: false,
    requireDecimal: true
});

const Formulario = (props) => {

    let validar = Yup.object({
        numero: Yup.number()
            .required('O campo número é obrigatório.')
            .integer('O campo número deverá receber um número inteiro.'),
        valor: Yup.string()
            .required('O campo valor é obrigatório.')
            .trim(),
        valor_pago: Yup.string()
            .nullable()
            .trim(),
        data_pagamento: Yup.string()
            .min(10, 'O campo data de pagamento deverá ter no mínimo 10 caracteres.')
            .max(10, 'O campo data de pagamento deverá ter no máximo 10 caracteres.')
            .nullable()
            .trim(),
        observacao: Yup.string()
            .max(500, 'O observação deverá ter no máximo 500 caracteres.')
            .nullable()
            .trim()
    });

    return (
        <Formik
            initialValues={{
                numero:         props.data !== undefined && props.data !== null ? props.data.numero : '',
                valor:          props.data !== undefined && props.data !== null ? props.data.valor : '0,00',
                pago:           props.data !== undefined && props.data !== null ? !!parseInt(props.data.pago) : false,
                valor_pago:     props.data !== undefined && props.data !== null && props.data.valor_pago !== 0 ? props.data.valor_pago : '0,00',
                data_pagamento: props.data !== undefined && props.data !== null ? props.data.data_pagamento : '',
                observacao:     props.data !== undefined && props.data !== null ? props.data.observacao : undefined
            }}
            enableReinitialize={true}
            dirty={false}
            validationSchema={validar}
            onSubmit= { values => {
                
                let valor = values.valor === '' ? 'R$ 0,00' : values.valor;
                valor = parseFloat(((valor.replaceAll('.', '')).replaceAll(',', '.')).replaceAll('R$ ', ''));

                let valor_pago = values.valor_pago === '' ? 'R$ 0,00' : values.valor_pago;
                valor_pago = parseFloat(((valor_pago.replaceAll('.', '')).replaceAll(',', '.')).replaceAll('R$ ', ''));

                let data = {
                    "pedido_uuid": sessionStorage.getItem('uuid_pedido'),
                    "numero" : values.numero === "" ? null : values.numero,
                    "valor" : valor.toFixed(2),
                    "pago": values.pago,
                    "valor_pago" : valor_pago.toFixed(2),
                    "data_pagamento" : values.data_pagamento === "" ? null : values.data_pagamento,
                    "observacao" : values.observacao === "" ? null : values.observacao
                }

                if (props.operation !== undefined && props.operation !== null && props.operation === 'alt') {
                    props.updateParcela(data, props.data.uuid);
                } else {
                    props.insertParcela(data);
                }
            }}
            >
            { formik => (
                <>
                    <Form className="forms-sample col-12" onSubmit={formik.handleSubmit}>
                        <Card>
                            <CardHeader className="bg-maskats text-center">
                                <h4 className="headerTitle">Cadastro de parcelas</h4>
                            </CardHeader>
                            <CardBody>
                                <div>

                                    <FormGroup>
                                        <Label for="numero">* Número</Label>
                                        <Input 
                                            className="form-control form-control-sm" 
                                            id="numero" 
                                            name="numero" 
                                            type="number" 
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.numero}
                                        />
                                        {formik.touched.numero && formik.errors.numero ? (
                                            <div className="divError">{formik.errors.numero}</div>
                                        ) : null}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="valor">Valor</Label>
                                        <MaskedInput
                                            mask={numberMask}
                                            guide={false}
                                            className="form-control form-control-sm" 
                                            id="valor" 
                                            name="valor" 
                                            type="text" 
                                            inputMode="decimal"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.valor}
                                        />
                                        {formik.touched.valor && formik.errors.valor ? (
                                            <div className="divError">{formik.errors.valor}</div>
                                        ) : null}
                                    </FormGroup>

                                    <FormGroup>
                                        <Row>
                                            <Col md={2} sm={3}>
                                                <Label for="pago">Pago</Label>
                                                <br />
                                                <Switch
                                                    id="pago"
                                                    name="pago"
                                                    onColor="#28a745"
                                                    offColor="#dc3545"
                                                    checked={formik.values.pago}
                                                    onChange={()=>formik.setFieldValue('pago', !formik.values.pago)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="valor_pago">Valor pago</Label>
                                        <MaskedInput
                                            mask={numberMask}
                                            guide={false}
                                            className="form-control form-control-sm" 
                                            id="valor_pago" 
                                            name="valor_pago" 
                                            type="text" 
                                            inputMode="decimal"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.valor_pago}
                                        />
                                        {formik.touched.valor_pago && formik.errors.valor_pago ? (
                                            <div className="divError">{formik.errors.valor_pago}</div>
                                        ) : null}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="data_pagamento">Data de pagamento</Label>
                                        <MaskedInput
                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                            guide={false}
                                            className="form-control form-control-sm" 
                                            id="data_pagamento" 
                                            name="data_pagamento" 
                                            type="text" 
                                            inputMode="numeric"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.data_pagamento}
                                            minLength={10}
                                            maxLength={10}
                                        />
                                        {formik.touched.data_pagamento && formik.errors.data_pagamento ? (
                                            <div className="divError">{formik.errors.data_pagamento}</div>
                                        ) : null}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="observacao">Observação</Label>
                                        <Input 
                                            className="form-control form-control-sm" 
                                            id="observacao" 
                                            name="observacao" 
                                            type="textarea"
                                            rows="4"
                                            style={{whiteSpace: "pre-wrap"}}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.observacao}
                                            maxLength={500}
                                        />
                                        {formik.touched.observacao && formik.errors.observacao ? (
                                            <div className="divError">{formik.errors.observacao}</div>
                                        ) : null}
                                    </FormGroup>
                                    
                                </div>
                            </CardBody>
                            <CardFooter className="text-align-end bg-white">
                                <Link replace to="./">
                                    <Button className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                                        <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                                        <span className="label-button btn-fs-def">Cancelar</span>
                                    </Button>
                                </Link>

                                <Button className="btn text-white btn-sm btn-success btn-fw bd-rd-8" type="submit">
                                    <i className="fa fa-check btn-icon-append btn-fs-def"></i>
                                    <span className="label-button btn-fs-def">Salvar</span>
                                </Button>
                            </CardFooter>
                        </Card>
                    </Form>
                </>
            )}
        </Formik>
    );
}

class ParcelaFormulario extends Component {

    constructor(props){
        super(props);

        this.state = { };
        
        this.loadingShow   = this.loadingShow.bind(this);
        this.updateParcela = this.updateParcela.bind(this);
        this.insertParcela = this.insertParcela.bind(this);
    }

    componentDidMount(){
        if( sessionStorage.getItem('uuid_pedido') === null ){
            this.props.history.push('/pedido');
        }
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    async updateParcela(data, uuid){
        this.loadingShow(true);
        await putData(`/pedido/parcela/${uuid}`, this.props, data, './');
        this.loadingShow(false);
    }

    async insertParcela(data){
        this.loadingShow(true);
        await postData(`/pedido/parcela`, this.props, data, './');
        this.loadingShow(false);
    }

    render(){
        const { data, operation } = this.props.location;
        const { setResponseModal, enableResponseModal } = this.props;
        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin stretch-card">
                                        <Formulario
                                            data={data}
                                            operation={operation}
                                            updateParcela={this.updateParcela}
                                            insertParcela={this.insertParcela}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        enableResponseModal: state.enableResponseModal
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParcelaFormulario);