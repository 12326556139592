import { Component, Fragment } from 'react';
import { putData } from "../../servicos/api";
import { Card, CardBody, Row, Col, CardHeader, CardFooter, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/Sidebar';
import ModalResponse from '../../Components/ModalResponse';

//REDUX
import { setLoaderShow, setDadosRelatorioPedido, setResponseModal } from '../../reducers/index';
import { connect } from 'react-redux';

import { postPdfData } from '../../servicos/api'

class PedidoVisualizar extends Component {
    constructor(props){
        super(props);

        this.state = {
            pedidos: [],
            showModal: false,
            showModalOpcaoEntregue: false
        };
        
        this.loadingShow = this.loadingShow.bind(this);
        this.setShowModal = this.setShowModal.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.postPdfData = this.postPdfData.bind(this);
        this.setShowModalOpcaoEntregue = this.setShowModalOpcaoEntregue.bind(this);
        this.updatePedidoEntregue = this.updatePedidoEntregue.bind(this);
    }

    async componentDidMount(){

        this.loadingShow(true);

        if(!this.props.dadosRelatorioPedido.carregado){
            this.props.history.push('./filtro');
        } else {
            let pedidos = [];

            pedidos = this.props.dadosRelatorioPedido.dados;

            this.setState({pedidos});

            this.loadingShow(false);
        }
    }

    loadingShow(value) {
        let { setLoaderShow } = this.props;
        setLoaderShow(value);
    }

    setShowModal() {
        this.setState({showModal: !this.state.showModal})
    }

    sendMessage(item_id){
        let whatsapp = document.querySelector(`#whatsapp`).value;
        document.querySelector(`#pago_${item_id}`).style.display = 'none';
        document.querySelector(`#total_receber_${item_id}`).style.display = 'none';
        
        let message_data = document.querySelector(`#card_pedido_${item_id}`).innerText;

        document.querySelector(`#pago_${item_id}`).style.display = 'block';
        document.querySelector(`#total_receber_${item_id}`).style.display = 'block';

        message_data = message_data.replaceAll('\n\nEnviar pedido no WhatsApp', '');
        let message_final = `Olá, obrigado por comprar em nossa loja!\nAqui estão os dados de seu pedido.\n\n${message_data}`;

        var url = 'https://api.whatsapp.com/send?phone=+55' 
            + whatsapp 
            + '&text=' 
            + encodeURIComponent(message_final);

        if(whatsapp !== ""){
            window.open(url, '_blank');
        } else {
            this.setShowModal();
        }
    }

    setShowModalOpcaoEntregue() {
        this.setState({showModalOpcaoEntregue: !this.state.showModalOpcaoEntregue})
    }

    async updatePedidoEntregue(uuid){
        this.loadingShow(true);
        await putData(`/pedido/entregue/${uuid}`, this.props, {}, './');
        this.loadingShow(false);
    }

    async postPdfData(){
        this.loadingShow(true);
        let { pedidos } = this.state;

        let arrPedidos = [];

        pedidos.map(item => {
            let valor_total = parseFloat((item.valor_total.replaceAll('.', '')).replaceAll(',', '.') );
            let valor_pago = parseFloat((item.valor_pago.replaceAll('.', '')).replaceAll(',', '.') );
            item.total = (valor_total - valor_pago).toFixed(2);

            arrPedidos.push({
                codigo: "#"+item.id,
                data: item.data_pagamento,
                endereco: `${item.endereco_bairro} - ${item.endereco_logradouro}, ${item.endereco_numero}`,
                cliente: item.cliente_nome,
                valor: "R$"+item.total.replace('.', ','),
            });
        })

        if( pedidos.length > 0 ) {
            const pdf = await postPdfData('relatorio/gerar-pdf', this.props, {dados: JSON.stringify(arrPedidos), option: 'pedidos'});
            const file = new Blob(
                [pdf], 
                {type: 'application/pdf'}
            );
            const fileURL = URL.createObjectURL(file);

            window.open(fileURL);
        }

        this.loadingShow(false);
    }

    render(){
        
        let { setResponseModal, enableResponseModal } = this.props;

        return (
            <Fragment>
                <div className="container-scroller">
                    <Navbar/>
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin">

                                        <Row style={{ marginBottom: '20px' }} >
                                            <Col sm="6" md="6" lg="6">
                                                <Button onClick={() => this.props.history.goBack()} className="mr-2 text-white btn btn-sm btn-secondary btn-fw bd-rd-8" type="button">
                                                    <i className="fa fa-arrow-left btn-icon-append btn-fs-def"></i>
                                                    <span className="label-button btn-fs-def">Voltar</span>
                                                </Button>
                                            </Col>
                                            <Col sm="6" md="6" lg="6" className='d-flex justify-content-end'>
                                                <Button onClick={() => this.postPdfData()} className="text-white btn btn-sm btn-success btn-fw bd-rd-8" type="button">
                                                    <i className="fa fa-download btn-icon-append btn-fs-def"></i>
                                                    <span className="label-button btn-fs-def">Exportar</span>
                                                </Button>
                                            </Col>
                                        </Row>

                                        { this.state.pedidos.length > 0 ?

                                            this.state.pedidos.map(item => {
                                                
                                                let valor_total = parseFloat((item.valor_total.replaceAll('.', '')).replaceAll(',', '.') );
                                                let valor_pago = parseFloat((item.valor_pago.replaceAll('.', '')).replaceAll(',', '.') );

                                                let total = (valor_total - valor_pago).toFixed(2);
                                                
                                                return (
                                                    <Fragment key={item.id}>
                                                        <Row>
                                                            <Col sm="12" md="12" lg="12">
                                                                <Input hidden id={"whatsapp"} value={item.telefone_principal === null ? '' : item.telefone_principal} readOnly/>
                                                                <Card id={`card_pedido_${item.id}`}>
                                                                    <CardHeader className='bg-maskats text-center' style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                        <h4 style={{ marginBottom: '0px' }}>Pedido #{item.id}</h4>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <p className="card-description-black"><b>Cliente: </b>{`${item.cliente_nome ? item.cliente_nome : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black"><b>Telefone: </b>{`${item.telefone_principal ? item.telefone_principal : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black"><b>Cidade: </b>{`${item.endereco_cidade ? item.endereco_cidade : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black"><b>Bairro: </b>{`${item.endereco_bairro ? item.endereco_bairro : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black"><b>Rua: </b>{`${item.endereco_logradouro ? item.endereco_logradouro : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black"><b>Número: </b>{`${item.endereco_numero ? item.endereco_numero : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black"><b>Entregue: </b>{`${item.entregue === 1 || item.entregue === '1' ? 'Sim' : 'Não'}`}</p>
                                                                        <p className="card-description-black"><b>Data de entrega: </b>{`${item.data_entrega ? item.data_entrega : 'Sem dados'}`}</p>
                                                                        <p id={`pago_${item.id}`} className="card-description-black"><b>Pago: </b>{`${item.pago === 1 || item.pago === '1' ? 'Sim' : 'Não'}`}</p>
                                                                        <p className="card-description-black"><b>Data de pagamento: </b>{`${item.data_pagamento ? item.data_pagamento : 'Sem dados'}`}</p>
                                                                        <p className="card-description-black" style={{whiteSpace: 'pre-line'}}><b>Obseração: </b>{item.observacao ? <br/> : ''}{`${item.observacao ? `${item.observacao}` : `Sem dados`}`}</p>
                                                                        <p className="card-description-black"><b>Produtos: </b>{`${item.produtos && item.produtos.length > 0 ? '' : 'Sem dados'}`} </p>
                                                                        <ul className="list-arrow">
                                                                            {
                                                                                item.produtos && item.produtos.length > 0 ?
                                                                                    item.produtos.map(produto => {
                                                                                        return <li key={produto.id} id={produto.id}><b>{ (produto.nome.toLowerCase()).indexOf('kg') === -1 ? `${produto.quantidade_produto}` : `${(produto.quantidade_produto).toFixed(3).replaceAll('.', ',')} Kg`} - R${produto.valor_momento_venda !== '' ? produto.valor_momento_venda : produto.valor}</b> {'->'} {produto.nome}</li>
                                                                                    }) : ''
                                                                            }
                                                                        </ul>
                                                                        <p className="card-description-black"><b>Total: </b> R${item.valor_total ? item.valor_total : 'Sem dados'} </p>
                                                                        <p className="card-description-black"><b>Total pago: </b> R${item.valor_pago ? item.valor_pago : 'Sem dados'} </p>
                                                                        <p id={`total_receber_${item.id}`} className={ total > 0 ? "card-description-red" : "card-description-green" }><b>{parseFloat(total).toFixed(2) >= 0 ? 'Total à receber' : 'Troco'}: </b> R${total ? (total.replaceAll('.', ',')).replaceAll('-', '') : 'Sem dados'} </p>
                                                                    </CardBody>
                                                                    <CardFooter style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                        <Button className="btn-sm" color="success" onClick={() => this.sendMessage(item.id)} style={{ marginRight: "20px"}}>Enviar pedido no WhatsApp</Button>
                                                                        {/* <Button className="btn-sm" color="warning" onClick={() => this.setShowModalOpcaoEntregue(item.uuid)}>Opções</Button> */}
                                                                    </CardFooter>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        <hr/>
                                                    </Fragment>
                                                );
                                            })
                                        : (
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <Card>
                                                        <CardBody>
                                                            <h4 style={{ marginBottom: '0px', textAlign: 'center' }}>Não foram encontrados dados...</h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}

                                    </div>
                                </div>
                                <Modal isOpen={this.state.showModal} toggle={this.setShowModal}>
                                    <ModalHeader toggle={this.setShowModal}>
                                        Atenção!
                                    </ModalHeader>
                                    <ModalBody>
                                        Para enviar o pedido ao cliente ele precisa possuir um WhatsApp cadastrado.
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className="btn-sm" color="secondary" onClick={this.setShowModal}>Fechar</Button>
                                    </ModalFooter>
                                </Modal>

                                <Modal isOpen={this.state.showModalOpcaoEntregue} toggle={this.setShowModalOpcaoEntregue}>
                                    <ModalHeader toggle={this.setShowModalOpcaoEntregue}>
                                        Atenção!
                                    </ModalHeader>
                                    <ModalBody>
                                        Deseja definir este pedido como entregue?
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className="btn-sm" color="secondary" onClick={this.setShowModalOpcaoEntregue}>Não</Button>
                                        <Button className="btn-sm" color="success" onClick={this.setShowModalOpcaoEntregue}>Sim</Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponse 
                    history={this.props.history} 
                    setResponseModal={setResponseModal} 
                    enableResponseModal={enableResponseModal}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableLoaderShow: state.enableLoaderShow,
        dadosRelatorioPedido: state.dadosRelatorioPedido,
        enableResponseModal: state.enableResponseModal,
    }
};

const mapDispatchToProps = dispatch => ({
    setLoaderShow: enable => dispatch(setLoaderShow(enable)),
    setDadosRelatorioPedido: dados => dispatch(setDadosRelatorioPedido(dados)),
    setResponseModal: enable => dispatch(setResponseModal(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PedidoVisualizar);